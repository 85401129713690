import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../helpers/api";
import { getQueryParams } from "../../helpers/structureData";
import {
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFailed,
  getNotificationTypesSuccess,
  getNotificationTypesFailed,
  changeNotificationValidtySuccess,
  changeNotificationValidtyFailed,
  changePhoneSuccess,
  changePhoneFailed,
  getSellerAccountSuccess,
  getSellerAccountFailed,
  removeSellerSuccess,
  removeSellerFailed,
  productsAddSuccess,
  productsAddFailed,
  getSubscriptionByUserSuccess,
  getSubscriptionByUserFailed,
  addSubscriptionPlanSuccess,
  getSubscriptionByUserPlanFailed,
  amazonPayBillingSuccess,
  amazonPayBillingFailed,
  getPaymentHistorySuccess,
  getPaymentHistoryFailed,
  getProfileDataSuccess,
  getProfileDataFailed,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailed,
  getAlertSettingsSuccess,
  getAlertSettingsFailed,
  updateAlertSettingsSuccess,
  updateAlertSettingsFailed,
  checkCouponSuccess,
  checkCouponFailed,
  feedbackViewSuccess,
  feedbackViewFailed,
  fetchInitialDatasSuccess,
  fetchInitialDatasFailed,
  changeSubscriptionPlanSuccess,
  changeSubscriptionPlanFailed,
} from "./actions";

function* getSubscriptionPlans({params}) {
  //DON'T REMOVE THIS COMMIT, THIS NEEDED FOR SUBSCRIPTION 
  // let options = getUnProtectedOptions(endpoints.getPlans, "GET", params);
  let options = getUnProtectedOptions(
    `${endpoints.getPlans}?${getQueryParams(params)}`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getSubscriptionPlansSuccess(response.data.data));
    } else {
      yield put(getSubscriptionPlansFailed(response.data.message));
    }
  } catch (error) {
    yield put(getSubscriptionPlansFailed(error || "Error not found!"));
  }
}

function* getSubscriptionPlansForUser() {
  let options = getProtectedOptions(endpoints.userPlans, "POST", {});
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getSubscriptionPlansSuccess(response.data.data));
    } else {
      yield put(getSubscriptionPlansFailed(response.data.message));
    }
  } catch (error) {
    yield put(getSubscriptionPlansFailed(error || "Error not found!"));
  }
}

function* getNotificationTypes({ sellerData }) {
  let options = getProtectedOptions(
    endpoints.getSellerNotification,
    "POST",
    sellerData
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getNotificationTypesSuccess(response.data.data));
    } else {
      yield put(getNotificationTypesFailed(response.data.message));
    }
  } catch (error) {
    yield put(getNotificationTypesFailed(error || "Error not found!"));
  }
}

function* changeNotificationValidty({ params }) {
  let options = getProtectedOptions(
    endpoints.changeNotification,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(changeNotificationValidtySuccess(response.data));
    } else {
      yield put(changeNotificationValidtyFailed(response.data.message));
    }
  } catch (error) {
    yield put(changeNotificationValidtyFailed(error || "Error not found!"));
  }
}

function* changePhone({ params }) {
  let options = getProtectedOptions(endpoints.changePhone, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(changePhoneSuccess(response.data));
    } else {
      yield put(changePhoneFailed(response.data.message));
    }
  } catch (error) {
    yield put(changePhoneFailed(error || "Error not found!"));
  }
}

function* getSellerAccount({ params }) {
  let options = getProtectedOptions(endpoints.sellerAccount, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200) {
      yield put(getSellerAccountSuccess(response.data));
    } else {
      yield put(
        getSellerAccountFailed(
          response?.data?.non_field_errors ||
            response.data.message ||
            "API Failed"
        )
      );
    }
  } catch (error) {
    yield put(getSellerAccountFailed(error || "Error not found!"));
  }
}

function* removeSellerAccount({ params }) {
  let options = getProtectedOptions(endpoints.removeSeller, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(removeSellerSuccess(response.data));
    } else {
      yield put(removeSellerFailed(response.data.message));
    }
  } catch (error) {
    yield put(removeSellerFailed(error || "Error not found!"));
  }
}

function* addProducts({ params }) {
  let options = getProtectedOptions(endpoints.addProducts, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(productsAddSuccess(response.data));
    } else {
      yield put(productsAddFailed(response.data.message));
    }
  } catch (error) {
    yield put(productsAddFailed(error || "Error not found!"));
  }
}

function* amazonBilling({ params }) {
  let options = getProtectedOptions(endpoints.amazonPay, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(amazonPayBillingSuccess(response.data));
    } else {
      yield put(amazonPayBillingFailed(response.data.message));
    }
  } catch (error) {
    yield put(amazonPayBillingFailed(error || "Error not found!"));
  }
}

function* addSubscriptionPlan({ params }) {
  let options = getProtectedOptions(
    endpoints.addSubscriptionPlan,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addSubscriptionPlanSuccess(response.data));
    } else {
      yield put(getSubscriptionByUserPlanFailed(response.data.message));
    }
  } catch (error) {
    yield put(getSubscriptionByUserPlanFailed(error || "Error not found!"));
  }
}

function* getSubscriptionByUserPlan({ params }) {
  let options = getProtectedOptions(
    endpoints.getSubscriptionByUser,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getSubscriptionByUserSuccess(response.data));
    } else {
      yield put(getSubscriptionByUserFailed(response.data.message));
    }
  } catch (error) {
    yield put(getSubscriptionByUserFailed(error || "Error not found!"));
  }
}

function* getPaymentHistory({ params }) {
  let options = getProtectedOptions(endpoints.paymentHistory, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getPaymentHistorySuccess(response.data));
    } else {
      yield put(getPaymentHistoryFailed(response.data.message));
    }
  } catch (error) {
    yield put(getPaymentHistoryFailed(error || "Error not found!"));
  }
}

function* getProfileData({ params }) {
  let options = getProtectedOptions(endpoints.profileData, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getProfileDataSuccess(response.data));
    } else {
      yield put(getProfileDataFailed("Seller Details API failed."));
    }
  } catch (error) {
    yield put(getProfileDataFailed(error || "Error not found!"));
  }
}

function* cancelSubscription({ params }) {
  let options = getProtectedOptions(
    endpoints.cancelSubscription,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(cancelSubscriptionSuccess(response.data));
    } else {
      yield put(cancelSubscriptionFailed(response.data.message));
    }
  } catch (error) {
    yield put(cancelSubscriptionFailed(error || "Error not found!"));
  }
}

function* getAlertSettings({ params }) {
  let options = getProtectedOptions(endpoints.alertSettings, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getAlertSettingsSuccess(response.data));
    } else {
      yield put(getAlertSettingsFailed(null));
    }
  } catch (error) {
    yield put(getAlertSettingsFailed(error || "Error not found!"));
  }
}

function* updateAlertSettings({ params }) {
  let options = getProtectedOptions(endpoints.alertSettings, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(updateAlertSettingsSuccess(response.data));
    } else {
      yield put(updateAlertSettingsFailed(null));
    }
  } catch (error) {
    yield put(updateAlertSettingsFailed(error || "Error not found!"));
  }
}

function* checkCouponValidity({ params }) {
  let options = getProtectedOptions(endpoints.couponCheck, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(checkCouponSuccess(response.data));
    } else {
      yield put(checkCouponFailed(response.data.message));
    }
  } catch (error) {
    yield put(checkCouponFailed(error || "Error not found!"));
  }
}

function* getFeedbackList() {
  let options = getUnProtectedOptions(endpoints.viewFeedbacks, "GET", {});
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(feedbackViewSuccess(response.data));
    } else {
      yield put(feedbackViewFailed(response.data.message));
    }
  } catch (error) {
    yield put(feedbackViewFailed(error || "Error not found!"));
  }
}

function* initialDataFetch({ params }) {
  let options = getProtectedOptions(endpoints.fetchInitialData, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchInitialDatasSuccess(response.data.data));
    } else {
      yield put(fetchInitialDatasFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchInitialDatasFailed(error || "Error not found!"));
  }
}

function* changeSubscriptionPlans({ params }) {
  let options = getProtectedOptions(endpoints.changePlan, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(changeSubscriptionPlanSuccess(response.data.message));
    } else {
      yield put(changeSubscriptionPlanFailed(response.data.message));
    }
  } catch (error) {
    yield put(changeSubscriptionPlanFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetPlans() {
  yield takeLatest(
    constants.GET_SUBSCRIPTION_PLANS_REQUEST,
    getSubscriptionPlans
  );
}
export function* watchGetUserPlans() {
  yield takeLatest(
    constants.GET_USER_SUBSCRIPTION_PLANS_REQUEST,
    getSubscriptionPlansForUser
  );
}
export function* watchGetnotificationTypes() {
  yield takeLatest(
    constants.GET_SELLER_NOTIFICATION_TYPES_REQUEST,
    getNotificationTypes
  );
}
export function* watchChangeNotificationValidty() {
  yield takeLatest(
    constants.CHANGE_NOTIFICATION_VALIDITY_REQUEST,
    changeNotificationValidty
  );
}
export function* watchChangePhone() {
  yield takeLatest(constants.CHANGE_PHONE_REQUEST, changePhone);
}
export function* watchGetSellerAccount() {
  yield takeLatest(constants.GET_SELLER_ACCOUNT_REQUEST, getSellerAccount);
}
export function* watchRemoveSellerAccount() {
  yield takeLatest(constants.REMOVE_SELLER_REQUEST, removeSellerAccount);
}
export function* watchAddProducts() {
  yield takeLatest(constants.PRODUCTS_ADD_REQUEST, addProducts);
}
export function* watchAddSubscriptionPlan() {
  yield takeLatest(
    constants.ADD_SUBSCRIPTION_PLAN_REQUEST,
    addSubscriptionPlan
  );
}
export function* watchAmazonBilling() {
  yield takeLatest(constants.AMAZON_PAY_BILLING_REQUEST, amazonBilling);
}

export function* watchGetSubscriptionByUser() {
  yield takeLatest(
    constants.GET_SUBSCRIPTION_BY_ID_PLAN_REQUEST,
    getSubscriptionByUserPlan
  );
}

export function* watchGetPaymentHistory() {
  yield takeLatest(constants.GET_PAYMENT_HISTORY_REQUEST, getPaymentHistory);
}

export function* watchGetProfileData() {
  yield takeLatest(constants.GET_PROFILE_DATA_REQUEST, getProfileData);
}

export function* watchCancelSubscription() {
  yield takeLatest(constants.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
}

export function* watchGetAlertSettings() {
  yield takeLatest(constants.GET_ALERT_SETTINGS_REQUEST, getAlertSettings);
}

export function* watchUpdateAlertSettings() {
  yield takeLatest(
    constants.UPDATE_ALERT_SETTINGS_REQUEST,
    updateAlertSettings
  );
}

export function* watchCheckCouponValidity() {
  yield takeLatest(constants.CHECK_COUPON_REQUEST, checkCouponValidity);
}

export function* watchGetFeedbackList() {
  yield takeLatest(constants.FEEDBACK_VIEW_REQUEST, getFeedbackList);
}

export function* watchFetchInitialData() {
  yield takeLatest(constants.FETCH_INITIAL_DATA_REQUEST, initialDataFetch);
}

export function* watchChangeSubscriptionPlan() {
  yield takeLatest(
    constants.CHANGE_SUBSCRIPTION_PLAN_REQUEST,
    changeSubscriptionPlans
  );
}

function* settingsSaga() {
  yield all([
    watchGetPlans(),
    watchChangePhone(),
    watchAddProducts(),
    watchGetUserPlans(),
    watchAmazonBilling(),
    watchGetProfileData(),
    watchGetAlertSettings(),
    watchGetSellerAccount(),
    watchGetPaymentHistory(),
    watchCancelSubscription(),
    watchRemoveSellerAccount(),
    watchUpdateAlertSettings(),
    watchCheckCouponValidity(),
    watchAddSubscriptionPlan(),
    watchGetnotificationTypes(),
    watchGetSubscriptionByUser(),
    watchChangeNotificationValidty(),
    watchGetFeedbackList(),
    watchFetchInitialData(),
    watchChangeSubscriptionPlan(),
  ]);
}

export default settingsSaga;
