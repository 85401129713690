import { constants } from "./constants";

const INIT_STATE = {
  loading: true,
  sellerStatus:null,
  subscriptionStatus: null,
  isOnboardingCompleted:null,
  isDataFetchingCompleted:null,
  paymentData:null,
};

const OnBoarding = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case constants.GET_SELLER_STATUS_REQUEST: // REQUEST
    case constants.GET_CHECK_SELLER_STATUS_REQUEST: // REQUEST
      return { ...state, loading: true , statusData:null};
    // case constants.GET_SELLER_STATUS_FAILED: // FAILED
    case constants.GET_CHECK_SELLER_STATUS_FAILED: // FAILED
      return {
        ...state,
        loading: false,
      };
    case constants.AMAZON_PAY_BILLING_FAILED:
      return {
        ...state,
        loading: false,
        paymentStatus: null,
      };
    // case constants.GET_SELLER_STATUS_SUCCESS: //SUCCESS
    case constants.GET_CHECK_SELLER_STATUS_SUCCESS: //SUCCESS
        return {
        ...state,
        loading: false,
        sellerStatus:{
          freePlan:action.payload.is_trail,
          authorized:action.payload.is_authorized,
          subscribed:action.payload.is_subscribed,
          paymentData:action.payload.data[0]?.plan?.plan_name,
        cancellationStatus:action.payload.cancellation_status,
        onBoardingCompleted:action.payload.onboarding_completed_status,
        dataFetchingCompleted:action.payload.data_fetching_completed_status}
      };

    case constants.AMAZON_PAY_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentStatus: true,
      };
    default:
      return { ...state };
  }
};

export default OnBoarding;
