import { constants } from './constants';

export const getSellerSpy = (params) => ({
  type: constants.GET_SELLER_SPY_REQUEST,
  params,
});

export const getSellerSpySuccess = (report) => ({
  type: constants.GET_SELLER_SPY_SUCCESS,
  payload: report,
});

export const getSellerSpyFailed = (error) => ({
  type: constants.GET_SELLER_SPY_FAILED,
  payload: error,
});

export const deleteSellerSpy = (params) => ({
  type: constants.DELETE_SELLER_SPY_REQUEST,
  params,
});

export const deleteSellerSpySuccess = (report) => ({
  type: constants.DELETE_SELLER_SPY_SUCCESS,
  payload: report,
});

export const deleteSellerSpyFailed = (error) => ({
  type: constants.DELETE_SELLER_SPY_FAILED,
  payload: error,
});

export const addSellerId = (params) => ({
  type: constants.ADD_SELLER_ID_REQUEST,
  params,
});

export const addSellerIdSuccess = (report) => ({
  type: constants.ADD_SELLER_ID_SUCCESS,
  payload: report,
});

export const addSellerIdFailed = (error) => ({
  type: constants.ADD_SELLER_ID_FAILED,
  payload: error,
});
export const fetchSellerId = (params) => ({
  type: constants.FETCH_SELLER_ID_REQUEST,
  params,
});

export const fetchSellerIdSuccess = (report) => ({
  type: constants.FETCH_SELLER_ID_SUCCESS,
  payload: report,
});

export const fetchSellerIdFailed = (error) => ({
  type: constants.FETCH_SELLER_ID_FAILED,
  payload: error,
});

export const asinToSelers = (params) => ({
  type: constants.ASIN_TO_SELLER_REQUEST,
  params,
});

export const asinToSelersSuccess = (report) => ({
  type: constants.ASIN_TO_SELLER_SUCCESS,
  payload: report,
});

export const asinToSelersIdFailed = (error) => ({
  type: constants.ASIN_TO_SELLER_FAILED,
  payload: error,
});

export const fetchCompetitorTableData = (params) => ({
  type: constants.FETCH_COMPETITOR_DATA_REQUEST,
  params,
});

export const fetchCompetitorTableDataSuccess = (report) => ({
  type: constants.FETCH_COMPETITOR_DATA_SUCCESS,
  payload: report,
});

export const fetchCompetitorTableDataFailed = (error) => ({
  type: constants.FETCH_COMPETITOR_DATA_FAILED,
  payload: error,
});

export const clearCompetitorList = (data) => ({
  type: constants.CLEAR_COMPETITOR_LIST,
  payload: data,
});

export const fetchAsinCount = (params) => ({
  type: constants.FETCH_ASIN_COUNT_REQUEST,
  params,
});

export const fetchAsinCountSuccess = (report) => ({
  type: constants.FETCH_ASIN_COUNT_SUCCESS,
  payload: report,
});

export const fetchAsinCountFailed = (error) => ({
  type: constants.FETCH_ASIN_COUNT_FAILED,
  payload: error,
});
