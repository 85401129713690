import { constants } from "./constants";

export const resetMessages = () => ({
  type: constants.RESET_ADMIN_FAQ_MESSAGE,
});

export const getFaq = (data) => ({
  type: constants.GET_ADMIN_FAQ_REQUEST,
  data,
});

export const getFaqSuccess = (data) => ({
  type: constants.GET_ADMIN_FAQ_SUCCESS,
  payload: data,
});

export const getFaqFailed = (error) => ({
  type: constants.GET_ADMIN_FAQ_FAILED,
  payload: error,
});

export const addFaq = (data) => ({
  type: constants.ADD_ADMIN_FAQ_REQUEST,
  data,
});

export const addFaqSuccess = (data) => ({
  type: constants.ADD_ADMIN_FAQ_SUCCESS,
  payload: data,
});

export const addFaqFailed = (error) => ({
  type: constants.ADD_ADMIN_FAQ_FAILED,
  payload: error,
});

export const editFaq = (data) => ({
  type: constants.EDIT_ADMIN_FAQ_REQUEST,
  data,
});

export const editFaqSuccess = (data) => ({
  type: constants.EDIT_ADMIN_FAQ_SUCCESS,
  payload: data,
});

export const editFaqFailed = (error) => ({
  type: constants.EDIT_ADMIN_FAQ_FAILED,
  payload: error,
});

export const deleteFaq = (data) => ({
  type: constants.DELETE_ADMIN_FAQ_REQUEST,
  data,
});

export const deleteFaqSuccess = (data) => ({
  type: constants.DELETE_ADMIN_FAQ_SUCCESS,
  payload: data,
});

export const deleteFaqFailed = (error) => ({
  type: constants.DELETE_ADMIN_FAQ_FAILED,
  payload: error,
});
