import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../../helpers/api";
import {
  getFAQFailed,
  getFAQSuccess,
  addContactMessageFailed,
  addContactMessageSuccess,
  submitFeedbackSuccess,
  submitFeedbackFailed,
  fetchBlogDetailFailed,
  fetchBlogDetailSuccess,
  getBlogSuccess,
  getBlogFailed,
  blogSubscriptionSuccess,
  blogSubscriptionFailed,
} from "./actions";

function* addContactMessage({ params }) {
  let options = getUnProtectedOptions(endpoints.contactUs, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addContactMessageSuccess(response.data));
    } else {
      yield put(addContactMessageFailed(response.data.message));
    }
  } catch (error) {
    yield put(addContactMessageFailed(error || "Error not found!"));
  }
}

function* getFAQ() {
  let options = getUnProtectedOptions(endpoints.faq, "GET", {});
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getFAQSuccess(response.data));
    } else {
      yield put(getFAQFailed(response.data.message));
    }
  } catch (error) {
    yield put(getFAQFailed(error || "Error not found!"));
  }
}

function* blogSubscription({ params }) {
  let options = getUnProtectedOptions(
    endpoints.blogSubscription,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(blogSubscriptionSuccess(response.data));
    } else {
      yield put(blogSubscriptionFailed(response.data.message));
    }
  } catch (error) {
    yield put(blogSubscriptionFailed(error || "Error not found!"));
  }
}

function* getUserBlog({ params }) {
  let options = getUnProtectedOptions(endpoints.fetchBlogs, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getBlogSuccess(response.data));
    } else {
      yield put(getBlogFailed(response.data.message));
    }
  } catch (error) {
    yield put(getBlogFailed(error || "Error not found!"));
  }
}

function* fetchBlogDetail({ data }) {
  let options = getUnProtectedOptions(endpoints.fetchBlogsDetail, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchBlogDetailSuccess(response.data));
    } else {
      yield put(fetchBlogDetailFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchBlogDetailFailed(error || "Error not found!"));
  }
}

function* submitFeedback({ params }) {
  let options = getProtectedOptions(endpoints.feedback, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(submitFeedbackSuccess(response.data.message));
    } else {
      yield put(submitFeedbackFailed(response.data.message));
    }
  } catch (error) {
    yield put(submitFeedbackFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchAddContactMessage() {
  yield takeLatest(constants.ADD_CONTACT_MESSAGE_REQUEST, addContactMessage);
}
export function* watchGetFAQ() {
  yield takeLatest(constants.GET_FAQ_REQUEST, getFAQ);
}
export function* watchSubmitFeedback() {
  yield takeLatest(constants.SUBMIT_FEEDBACK_REQUEST, submitFeedback);
}
export function* watchfetchBlogDetail() {
  yield takeLatest(
    constants.FETCH_BLOG_LIST_DETAIL_REQUEST,
    fetchBlogDetail
  );
}
export function* watchBlogSubscription() {
  yield takeLatest(constants.FOOTER_SUBSCRIPTON_REQUEST, blogSubscription);
}
export function* watchGetUserBlog() {
  yield takeLatest(constants.GET_USER_BLOG_REQUEST, getUserBlog);
}
function* publicSaga() {
  yield all([
    watchGetFAQ(),
    watchSubmitFeedback(),
    watchfetchBlogDetail(),
    watchGetUserBlog(),
    watchAddContactMessage(),
    watchBlogSubscription(),
  ]);
}

export default publicSaga;
