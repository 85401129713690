import { constants } from "./constants";


export const getDashboardData = (data) => ({
  type: constants.GET_DASHBOARD_DATA_REQUEST,
  data,
});

export const getDashboardDataSuccess = (data) => ({
  type: constants.GET_DASHBOARD_DATA_SUCCESS,
  payload: data,
});

export const getDashboardDataFailed = (error) => ({
  type: constants.GET_DASHBOARD_DATA_FAILED,
  payload: error,
});