import PublicRoute from "./PublicRoute";
import { components } from "./components";
import PrivateRoute from "./PrivateRoute";
import adminFaq from "../assets/img/adminFaq.svg";
import AdminPublicRoute from "./AdminPublicRoute";
import reviewsIcon from "../assets/img/menu-icon-05.svg";
import settingsIcon from "../assets/img/menu-icon-06.svg";
import productsIcon from "../assets/img/menu-icon-04.svg";
import competitorSpyIcon from "../assets/img/spy-on-competetor.svg";
import categorySpyIcon from "../assets/img/spy-on-category.svg";
import competitorProductsIcon from "../assets/img/competetor.svg";
import videoIcon from "../assets/img/vuesax-linear-video-play.svg";
import analyticsIcon from "../assets/img/menu-icon-02.svg";
import monitoringIcon from "../assets/img/menu-icon-03.svg";
import adminCustomer from "../assets/Admin/img/menu-icon-02.svg";
import adminFeedback from "../assets/img/admin-feedback.svg";
import adminDashboard from "../assets/img/admin-dashboard.svg";
import Calculator from "../assets/img/Calculator.svg";
import categorytrend from "../assets/img/category-trend.svg";
import KeywordIcon from "../assets/img/keywords.svg";

export const allFlattenRoutes = [
  {
    path: "/login",
    name: "login",
    component: components.login,
    roles: ["PUBLIC"],
    route: PublicRoute,
  },
  {
    path: "/",
    name: "login",
    exact: true,
    component: components.features,
    route: PublicRoute,
  },
  {
    path: "/blog",
    name: "blog",
    exact: true,
    component: components.blog,
    route: PublicRoute,
  },
  {
    path: "/unsubscribe_newsletter",
    name: "unsubscribeNewsletter",
    exact: true,
    component: components.unsubscribeNewsletter,
    route: PublicRoute,
  },
  {
    path: "/blog/:slug",
    name: "blog",
    exact: true,
    component: components.blogdetails,
    route: PublicRoute,
  },
  // DON'T REMOVE THIS COMMIT, THIS NEEDED FOR SUBSCRIPTION
  {
    path: "/amazon-seller-software-pricing",
    name: "login",
    exact: true,
    component: components.pricing,
    route: PublicRoute,
  },
  {
    path: "/amazon-seller-software-faq",
    name: "login",
    exact: true,
    component: components.faq,
    route: PublicRoute,
  },
  {
    path: "/contactus",
    name: "login",
    exact: true,
    component: components.contactus,
    route: PublicRoute,
  },
  {
    path: "/home",
    name: "home",
    exact: true,
    component: components.home,
    route: PrivateRoute,
    roles: ["USER"],
  },
  {
    path: "/authorization",
    name: "onBoarding",
    exact: true,
    component: components.onBoarding,
    route: PrivateRoute,
    roles: ["USER", "onBoarding", "subscription"],
  },
  {
    path: "/subscriptions",
    name: "onBoarding",
    exact: true,
    component: components.onBoarding,
    route: PrivateRoute,
    roles: ["USER", "onBoarding", "subscription"],
  },
  {
    path: "/welcomePage",
    name: "welcomePage",
    exact: true,
    component: components.welcomePage,
    route: PrivateRoute,
    roles: ["USER", "onBoarding"],
  },
  {
    path: "/welcome",
    name: "home",
    exact: true,
    component: components.home,
    route: PrivateRoute,
    roles: ["USER"],
  },
  {
    path: "/dashboard",
    name: "home",
    exact: true,
    component: components.dashboard,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/admin/customer",
    name: "home",
    exact: true,
    component: components.customer,
    route: PrivateRoute,
  },
  {
    path: "/admin/subscription",
    name: "home",
    exact: true,
    component: components.adminSubscription,
    route: PrivateRoute,
  },
  {
    path: "/admin/inquiry",
    name: "home",
    exact: true,
    component: components.adminInquiry,
    route: PrivateRoute,
  },
  {
    path: "/admin/dashboard",
    name: "dashboard",
    exact: true,
    component: components.adminDashboard,
    route: PrivateRoute,
  },
  {
    path: "/admin/feedback",
    name: "home",
    exact: true,
    component: components.adminFeedback,
    route: PrivateRoute,
  },
  {
    path: "/admin/deals_management",
    name: "deals",
    exact: true,
    component: components.adminDealsManagement,
    route: PrivateRoute,
  },
  {
    path: "/admin/seller_deals_report",
    name: "dealsreport",
    exact: true,
    component: components.adminSellerDealsReport,
    route: PrivateRoute,
  },
  {
    path: "/admin/user_report",
    name: "userreport",
    exact: true,
    component: components.adminUserReport,
    route: PrivateRoute,
  },
  {
    path: "/admin/deal_suggestions",
    name: "dealsuggestions",
    exact: true,
    component: components.adminDealSuggestions,
    route: PrivateRoute,
  },
  {
    path: "/admin/deals_dashboard",
    name: "dealsDashboard",
    exact: true,
    component: components.adminDealsDashboard,
    route: PrivateRoute,
  },

  {
    path: "/admin/blog_management",
    name: "blogManagement",
    exact: true,
    component: components.adminBlogManagement,
    route: PrivateRoute,
  },

  {
    path: "/admin/alerts",
    name: "home",
    exact: true,
    component: components.adminAlerts,
    route: PrivateRoute,
  },
  {
    path: "/admin/referal-earnings",
    name: "referandearn",
    exact: true,
    component: components.adminReferAndEarn,
    route: PrivateRoute,
  },
  {
    path: "/admin",
    name: "home",
    exact: true,
    component: components.adminLogin,
    route: AdminPublicRoute,
  },
  {
    path: "/resetpassword",
    name: "home",
    exact: true,
    component: components.adminResetPassword,
    route: AdminPublicRoute,
  },
  {
    path: "/products",
    name: "products",
    exact: true,
    component: components.products,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/competitorProducts",
    name: "Competitor Products",
    exact: true,
    component: components.CompetitorProducts,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/category-spy",
    name: "Spy on Category",
    exact: true,
    component: components.CategorySpy,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/competitor-spy",
    name: "Spy on Competitor",
    exact: true,
    component: components.CompetitorSpy,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/keyword-recommendation",
    name: "Keyword",
    exact: true,
    component: components.Keyword,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/video-creation",
    name: "Video Creation",
    exact: true,
    component: components.videoCreation,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/dealsDashboard",
    name: "Seller Dashboard",
    exact: true,
    component: components.sellerDashboard,
    route: PrivateRoute,
  },
  {
    path: "/settings",
    name: "settings",
    exact: true,
    component: components.settings,
    route: PrivateRoute,
  },
  {
    path: "/admin/settings",
    name: "settings",
    exact: true,
    component: components.adminSettings,
    route: PrivateRoute,
  },
  {
    path: "/settings/profile",
    name: "settings",
    exact: true,
    component: components.settings,
    route: PrivateRoute,
  },
  {
    path: "/settings/subscription",
    name: "settings",
    exact: true,
    component: components.settings,
    route: PrivateRoute,
  },
  {
    path: "/settings/alerts",
    name: "settings",
    exact: true,
    component: components.settings,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/analytics",
    name: "analytics",
    exact: true,
    component: components.analytics,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/monitoring",
    name: "monitoring",
    exact: true,
    component: components.monitoring,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/reviews",
    name: "reviews",
    exact: true,
    component: components.reviews,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/fba-calculator",
    name: "fba-calculator",
    exact: true,
    component: components.fbacalculator,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/category-trends",
    name: "Category Trends",
    exact: true,
    component: components.category,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/dealsManagement",
    name: "dealsManagement",
    exact: true,
    component: components.dealsManagement,
    route: PrivateRoute,
    roles: ["PAYER"],
  },
  {
    path: "/admin/faq",
    name: "faq",
    exact: true,
    component: components.adminFaq,
    route: PrivateRoute,
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    exact: true,
    component: components.privacy,
    route: PublicRoute,
  },
  {
    path: "/terms-and-conditions",
    name: "termsandconditions",
    exact: true,
    component: components.termsandconditions,
    route: PublicRoute,
  },
  {
    path: "/feedback",
    name: "feedback",
    exact: true,
    component: components.feedback,
    roles: ["USER"],
    route: PublicRoute,
  },
  {
    path: "*",
    name: "All",
    exact: true,
    component: components.NotFound,
    route: PublicRoute,
  },
];

export const protectedRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: adminDashboard,
    type: "dash",
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: analyticsIcon,
    type: "sub_business",
  },
  {
    path: "/competitorProducts",
    name: "Competitor Products",
    icon: competitorProductsIcon,
  },
  {
    path: "/category-spy",
    name: "Spy on Category",
    icon: categorySpyIcon,
    type: "sub_spying",
  },
  {
    path: "/competitor-spy",
    name: "Spy on Competitor",
    icon: competitorSpyIcon,
    type: "sub_spying",
  },
  {
    path: "/keyword-recommendation",
    name: "Keyword",
    icon: KeywordIcon,
    type: "sub_tools",
  },
  {
    path: "/video-creation",
    name: "Video Creation",
    icon: videoIcon,
    type: "sub_tools",
  },
  {
    path: "/monitoring",
    name: "Monitoring",
    icon: monitoringIcon,
  },
  {
    path: "/products",
    name: "Products",
    icon: productsIcon,
    type: "sub_business",
  },

  {
    path: "/reviews",
    name: "Review Automation",
    icon: reviewsIcon,
    type: "sub_business",
  },
  {
    path: "/fba-calculator",
    name: "FBA Calculator",
    icon: Calculator,
    type: "sub_business",
  },
  {
    path: "/category-trends",
    name: "Category Trends",
    icon: categorytrend,
    type: "sub_business",
  },
  {
    path: "/dealsManagement",
    name: "Deals Management",
    type: "deals",
  },
  {
    path: "/dealsDashboard",
    name: "Deals Dashboard",
    type: "deals",
  },
  {
    path: "/settings",
    subPath: [
      "/settings/profile",
      "/settings/subscription",
      "/settings/alerts",
    ],
    name: "Settings",
    icon: settingsIcon,
    type: "settings",
  },
];

export const protectedAdminRoutes = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: adminDashboard,
  },
  {
    path: "/admin/customer",
    name: "Sellers",
    icon: adminCustomer,
  },
  {
    path: "/admin/subscription",
    name: "Subscription",
    icon: reviewsIcon,
  },

  {
    path: "/admin/alerts",
    name: "Alerts",
    icon: productsIcon,
  },
  {
    path: "/admin/faq",
    name: "FAQ",
    icon: adminFaq,
  },
  {
    path: "/admin/feedback",
    name: "Feedback",
    icon: adminFeedback,
  },
  {
    path: "/admin/inquiry",
    name: "Enquiry",
    icon: analyticsIcon,
  },
  {
    path: "/admin/deals_dashboard",
    name: "Dashboard",
    type: "admin_deals",
  },
  {
    path: "/admin/deals_management",
    name: "Deals Management",
    type: "admin_deals",
  },
  {
    path: "/admin/seller_deals_report",
    name: "Seller Deals Report",
    type: "admin_deals",
  },
  {
    path: "/admin/user_report",
    name: "User Report",
    type: "admin_deals",
  },
  {
    path: "/admin/deal_suggestions",
    name: "Deal Suggestions",
    type: "admin_deals",
  },
  {
    path: "/admin/blog_management",
    name: "Blog Management",
    type: "admin_deals",
  },

  {
    path: "/admin/settings",
    name: "Settings",
    icon: settingsIcon,
  },
  {
    path: "/admin/referal-earnings",
    name: "Refer&Earn",
    type: "admin_deals",
    // icon: reviewsIcon,
  },
  // sub-admin
  {
    path: "/admin/deal_suggestions",
    name: "Deal Suggestions",
    type: "sub_admin_deals",
  },
  {
    path: "/admin/deals_management",
    name: "Deals Management",
    type: "sub_admin_deals",
  },
];