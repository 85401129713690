import { constants } from "./constants";


export const getDealSuggestions = (data) => ({
  type: constants.GET_DEAL_SUGGESTIONS_REQUEST,
  data,
});

export const getDealSuggestionsSuccess = (data) => ({
  type: constants.GET_DEAL_SUGGESTIONS_SUCCESS,
  payload: data,
});

export const getDealSuggestionsFailed = (error) => ({
  type: constants.GET_DEAL_SUGGESTIONS_FAILED,
  payload: error,
});

export const fetchProduct = (data) => ({
  type: constants.FETCH_PRODUCT_REQUEST,
  data,
});

export const fetchProductSuccess = (data) => ({
  type: constants.FETCH_PRODUCT_SUCCESS,
  payload: data,
});

export const fetchProductFailed = (error) => ({
  type: constants.FETCH_PRODUCT_FAILED,
  payload: error,
});
export const getDealDetails = (data) => ({
  type: constants.GET_DEAL_DETAILS_REQUEST,
  data,
});

export const getDealDetailsSuccess = (data) => ({
  type: constants.GET_DEAL_DETAILS_SUCCESS,
  payload: data,
});

export const getDealDetailsFailed = (error) => ({
  type: constants.GET_DEAL_DETAILS_FAILED,
  payload: error,
});

export const rejectSuggestedDeal = (data) => ({
  type: constants.REJECT_DEAL_REQUEST,
  data,
});

export const rejectSuggestedDealSuccess = (data) => ({
  type: constants.REJECT_DEAL_SUCCESS,
  payload: data,
});

export const rejectSuggestedDealFailed = (error) => ({
  type: constants.REJECT_DEAL_FAILED,
  payload: error,
});

export const createSuggestedDeal = (data) => ({
  type: constants.CREATE_SUGGESTED_DEAL_REQUEST,
  data,
});

export const createSuggestedDealSuccess = (data) => ({
  type: constants.CREATE_SUGGESTED_DEAL_SUCCESS,
  payload: data,
});

export const createSuggestedDealFailed = (error) => ({
  type: constants.CREATE_SUGGESTED_DEAL_FAILED,
  payload: error,
});


// for sort
export const sortSuggestedDeal = (data) => ({
  type: constants.SORT_SUGGESTED_DEAL_REQUEST,
  data,
});

export const sortSuggestedDealSuccess = (data) => ({
  type: constants.SORT_SUGGESTED_DEAL_SUCCESS,
  payload: data,
});

export const sortSuggestedDealFailed = (error) => ({
  type: constants.SORT_SUGGESTED_DEAL_FAILED,
  payload: error,
});

// for suggest deal edit
export const editSuggestDeal = (data) => ({
  type: constants.EDIT_SUGGESTED_DEAL_REQUEST,
  data,
});

export const editSuggestDealSuccess = (data) => ({
  type: constants.EDIT_SUGGESTED_DEAL_SUCCESS,
  payload: data,
});

export const editSuggestDealFailed = (error) => ({
  type: constants.EDIT_SUGGESTED_DEAL_FAILED,
  payload: error,
});