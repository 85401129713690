import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";

const structureData = (str) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1).replaceAll("_", " ");
};

const formatDate = (date) => {
  return (date && moment(date).format("MM/DD/YYYY")) || "N/A";
};

const formatDateStructure = (date) => {
  return (date && moment(date).format("YYYY-MM-DD")) || "N/A";
};

const formatUtcDate = (date,type) => {
  if(type === 2){
    return ((date && moment.utc(date).endOf('day').format())|| moment.utc().format());
  }
  return ((date && moment.utc(date).format()) || moment.utc().format());
};

const formatDateAsText = (date) => {
  return ((date && moment(date).format("MMM DD, YYYY")) || "N/A");
};

const decimalFixing = (amount) => parseFloat(amount).toFixed(2);

const sortByCreatedDate = (data) => {
  return data.sort(
    (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
  );
};

const getQueryParams = (data) => {
  let queryString = '';
  const params = Object.keys(data);
  params.map((param) => {
      if (data[param]) queryString += `&${param}=${data[param]}`;
      return queryString;
  });
  return queryString;
};

const getApiQueryParams = (data) => {
  let queryString = '';
  const params = Object.keys(data);
  params.forEach((param) => {
    if (data[param] !== undefined && data[param] !== null) {
      queryString += `&${param}=${data[param]}`;
    }
  });
  return queryString;
};


const sortByUpdatedDate = (data) => {
  return data.sort(
    (a, b) =>
      Date.parse(b.updated_at ? b.updated_at : b.created_at) -
      Date.parse(a.updated_at ? a.updated_at : a.created_at)
  );
};

const toolTip = (data, params) => {
  const BlueTooltip = withStyles({
    tooltip: {
      minWidth: "auto",
      maxWidth: "500px",
      height: "auto",
      fontSize: "13.5px",
      color: "#F8F8F8",
      backgroundColor: "#00aeef",
    },
    arrow: {
      color: "#00aeef",
    },
  })(Tooltip);
  return (
    <BlueTooltip title={data} arrow>
      {params}
    </BlueTooltip>
  );
};

const renderSnackbar = (open, setBarClose) => {
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      open={open}
      autoHideDuration={2000}
      message="Message copied to clipboard."
      onClose={setBarClose}
    />
  );
};

const loadingLabel = () => {
  return (
    <div className="ali-cntr">
      <h2>Loading...</h2>
    </div>
  );
};

export {
  toolTip,
  formatDate,
  loadingLabel,
  structureData,
  decimalFixing,
  formatUtcDate,
  getQueryParams,
  getApiQueryParams,
  renderSnackbar,
  formatDateAsText,
  sortByCreatedDate,
  sortByUpdatedDate,
  formatDateStructure,
};
