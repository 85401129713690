import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
    apiCall,
    getProtectedOptions,
    getUnProtectedOptions
} from "../../helpers/api";
import {
    getProductListSuccess,
    getProductListFailed,
    updateProductSuccess,
    updateProductFailed,
    updateProductAlertSuccess,
    updateProductAlertFailed,
    activateProductSuccess,
    activateProductFailed,
    getProductAlertSuccess,
    getProductAlertFailed,
    getProductInfoSuccess,
    getProductInfoFailed,
    getProductFbaCalcSuccess,
    getProductFbaCalcFailed,

} from "./actions";

function* getProductList({ params }) {
    let options = getProtectedOptions(endpoints.getProducts, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getProductListSuccess(response.data));
        } else {
            yield put(getProductListFailed(response.data.message));
        }
    } catch (error) {
        yield put(getProductListFailed(error || "Error not found!"));
    }
}
function* getProductInfo({ params }) {
    let options = getProtectedOptions(endpoints.getProductInfo, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getProductInfoSuccess(response.data));
        } else {
            yield put(getProductInfoFailed(response.data.message));
        }
    } catch (error) {
        yield put(getProductInfoFailed(error));
    }
}

function* getProductFbaCalc({ params }) {
    let options = getProtectedOptions(endpoints.getProductFbaCalculation, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getProductFbaCalcSuccess(response.data));
        } else {
            yield put(getProductFbaCalcFailed(response.data.message));
        }
    } catch (error) {
        yield put(getProductFbaCalcFailed(error || "Error not found!"));
    }
}
function* updateProduct({ params }) {
    let options = getProtectedOptions(endpoints.updateProduct, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(updateProductSuccess(response.data));
        } else {
            yield put(updateProductFailed(response.data.message));
        }
    } catch (error) {
        yield put(updateProductFailed(error || "Error not found!"));
    }
}

function* getProductAlerts({ params }) {
    let options = getProtectedOptions(endpoints.getProductAlert, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getProductAlertSuccess(response.data));
        } else {
            yield put(getProductAlertFailed(response.data.message));
        }
    } catch (error) {
        yield put(getProductAlertFailed(error || "Error not found!"));
    }
}

function* updateProductAlerts({ params }) {
    let options = getProtectedOptions(endpoints.updateProduct, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(updateProductAlertSuccess(response.data));
        } else {
            yield put(updateProductAlertFailed(response.data.message));
        }
    } catch (error) {
        yield put(updateProductAlertFailed(error || "Error not found!"));
    }
}

function* activateProductList({ params }) {
    let options = getProtectedOptions(endpoints.activateProducts, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(activateProductSuccess(response.data));
        } else {
            yield put(activateProductFailed(response.data.message));
        }
    } catch (error) {
        yield put(activateProductFailed(error || "Error not found!"));
    }
}

//Watcher
export function* watchgetProductList() {
    yield takeLatest(constants.GET_PRODUCT_LIST_REQUEST, getProductList);
};
export function* watchUpdateProduct() {
    yield takeLatest(constants.UPDATE_PRODUCT_REQUEST, updateProduct);
};
export function* watchGetProductAlerts() {
    yield takeLatest(constants.GET_PRODUCT_ALERTS_REQUEST, getProductAlerts);
};
export function* watchUpdateProductAlerts() {
    yield takeLatest(constants.UPDATE_PRODUCT_ALERTS_REQUEST, updateProductAlerts);
};
export function* watchActivateProducts() {
    yield takeLatest(constants.ACTIVATE_PRODUCT_REQUEST, activateProductList);
};
export function* watchgetProductInfo() {
    yield takeLatest(constants.GET_PRODUCT_INFO_REQUEST, getProductInfo);
};
export function* watchgetProductFbaCalc() {
    yield takeLatest(constants.GET_PRODUCT_FBA_CALC_REQUEST, getProductFbaCalc);
};
function* productsSaga() {
    yield all([
        watchgetProductList(),
        watchUpdateProduct(),
        watchGetProductAlerts(),
        watchUpdateProductAlerts(),
        watchActivateProducts(),
        watchgetProductInfo(),
        watchgetProductFbaCalc(),

    ]);
}

export default productsSaga;