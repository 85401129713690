import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  apiCall,
  getUnProtectedOptions,
  getProtectedOptions,
} from "../../../helpers/api";
import { endpoints } from "../../../helpers/endpoints";
import { approveReferralFailed, approveReferralSuccess, fetchReferAndEarnFailed, fetchReferAndEarnSuccess } from "./actions";
import { constants } from "./constants";
import { getQueryParams,getApiQueryParams } from "../../../helpers/structureData";


function* fetchallreferandearn({ params }) {
  let options = getProtectedOptions(`${endpoints.fetchReferAndEarn}?${getApiQueryParams(params)}`,
  "GET");
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200) {
      yield put(fetchReferAndEarnSuccess(response.data));
    } else {
      yield put(fetchReferAndEarnFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchReferAndEarnFailed(error || "Error not Found"));
  }
}

function* approveReferral({ params }) {
  let options = getProtectedOptions(endpoints.approveReferral, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200) {
      yield put(approveReferralSuccess(response.data));
    } else {
      yield put(approveReferralFailed(response.data.message));
    }
  } catch (error) {
    yield put(approveReferralFailed(error || "Error not Found"));
  }
}
export function* watchfetchallreferandearn() {
  yield takeLatest(
    constants.FETCH_REFER_AND_EARN_REQUEST,
    fetchallreferandearn
  );
}
export function* watchApproveReferAndEarn() {
  yield takeLatest(
    constants.APPROVE_REFERRAL_REQUEST,
    approveReferral
  );
}

function* adminReferAndEarnSaga() {
  yield all([watchfetchallreferandearn(), watchApproveReferAndEarn()]);
}

export default adminReferAndEarnSaga;
