export const constants = {
      GET_LISTING_CATEGORYSPY_REQUEST: "GET_LISTING_CATEGORYSPY_REQUEST",
      GET_LISTING_CATEGORYSPY_SUCCESS: "GET_LISTING_CATEGORYSPY_SUCCESS",
      GET_LISTING_CATEGORYSPY_FAILED: "GET_LISTING_CATEGORYSPY_FAILED",

      DELETE_CATEGORY_SPY_REQUEST: "DELETE_CATEGORY_SPY_REQUEST",
      DELETE_CATEGORY_SPY_SUCCESS: "DELETE_CATEGORY_SPY_SUCCESS",
      DELETE_CATEGORY_SPY_FAILED: "DELETE_CATEGORY_SPY_FAILED",

      FETCH_MAIN_CATEGORY_REQUEST: "FETCH_MAIN_CATEGORY_REQUEST",
      FETCH_MAIN_CATEGORY_SUCCESS: "FETCH_MAIN_CATEGORY_SUCCESS",
      FETCH_MAIN_CATEGORY_FAILED: "FETCH_MAIN_CATEGORY_FAILED",

      FETCH_SUB_CATEGORY_REQUEST: "FETCH_SUB_CATEGORY_REQUEST",
      FETCH_SUB_CATEGORY_SUCCESS: "FETCH_SUB_CATEGORY_SUCCESS",
      FETCH_SUB_CATEGORY_FAILED: "FETCH_SUB_CATEGORY_FAILED",

      FETCH_CATEGORY_DATA_REQUEST: "FETCH_CATEGORY_DATA_REQUEST",
      FETCH_CATEGORY_DATA_SUCCESS: "FETCH_CATEGORY_DATA_SUCCESS",
      FETCH_CATEGORY_DATA_FAILED: "FETCH_CATEGORY_DATA_FAILED",

      ADD_SUB_CATEGORY_ID_REQUEST: "ADD_SUB_CATEGORY_ID_REQUEST",
      ADD_SUB_CATEGORY_ID_SUCCESS: "ADD_SUB_CATEGORY_ID_SUCCESS",
      ADD_SUB_CATEGORY_ID_FAILED: "ADD_SUB_CATEGORY_ID_FAILED",

      ADD_CATEGORY_ID_REQUEST: "ADD_CATEGORY_ID_REQUEST",
      ADD_CATEGORY_ID_SUCCESS: "ADD_CATEGORY_ID_SUCCESS",
      ADD_CATEGORY_ID_FAILED: "ADD_CATEGORY_ID_FAILED",

      CLEAR_CATEGORY_LIST:"CLEAR_CATEGORY_LIST"

}