import { constants } from "./constants";

export const fetchReferAndEarn = (params) => ({
  type: constants.FETCH_REFER_AND_EARN_REQUEST,
  params,
});

export const fetchReferAndEarnSuccess = (params) => ({
  type: constants.FETCH_REFER_AND_EARN_SUCCESS,
  payload: params,
});

export const fetchReferAndEarnFailed = (error) => ({
  type: constants.FETCH_REFER_AND_EARN_FAILED,
  payload: error,
});

export const approveReferral = (params) => ({
  type: constants.APPROVE_REFERRAL_REQUEST,
  params,
});

export const approveReferralSuccess = (params) => ({
  type: constants.APPROVE_REFERRAL_SUCCESS,
  payload: params,
});

export const approveReferralFailed = (error) => ({
  type: constants.APPROVE_REFERRAL_FAILED,
  payload: error,
});
