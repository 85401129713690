import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
    getDealsByAdminSuccess,
    getDealsByAdminFailed,
    createDealsByAdminSuccess,
    createDealsByAdminFailed,
    editDealsByAdminFailed,
    editDealsByAdminSuccess,
    sortDealsByAdminFailed,
    sortDealsByAdminSuccess,
    sellerProductsListSuccess,
    sellerProductsListFailed,
    searchDealsByAdminSuccess,
    searchDealsByAdminFailed,
} from "./actions";

function* getDealsByAdmin({ data }) {
  let options = getProtectedOptions(endpoints.getDealsByAdmin, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getDealsByAdminSuccess(response.data));
    } else {
      yield put(getDealsByAdminFailed(response.data.message));
    }
  } catch (error) {
    yield put(getDealsByAdminFailed(error || "Error not found!"));
  }
}
function* searchDealsByAdmin({ data }) {
  let options = getProtectedOptions(endpoints.getDealsByAdmin, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(searchDealsByAdminSuccess(response.data));
    } else {
      yield put(searchDealsByAdminFailed(response.data.message));
    }
  } catch (error) {
    yield put(searchDealsByAdminFailed(error || "Error not found!"));
  }
}
function* createDealsByAdmin({ data }) {
  let options = getProtectedOptions(endpoints.createDealsByAdmin, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(createDealsByAdminSuccess(response.data));
    } else {
      yield put(createDealsByAdminFailed(response.data.message));
    }
  } catch (error) {
    yield put(createDealsByAdminFailed(error || "Error not found!"));
  }
}

function* editDealsByAdmin({ data }) {
  let options = getProtectedOptions(endpoints.editDealsByAdmin, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editDealsByAdminSuccess(response.data));
    } else {
      yield put(editDealsByAdminFailed(response.data.message));
    }
  } catch (error) {
    yield put(editDealsByAdminFailed(error || "Error not found!"));
  }
}

function* sortDealsByAdmin({ data }) {
  let options = getProtectedOptions(endpoints.sortDealsByAdmin, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sortDealsByAdminSuccess(response.data));
    } else {
      yield put(sortDealsByAdminFailed(response.data.message));
    }
  } catch (error) {
    yield put(sortDealsByAdminFailed(error || "Error not found!"));
  }
}

function* sellerProductsList({ data }) {
  let options = getProtectedOptions(endpoints.sellerProductsList, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sellerProductsListSuccess(response.data));
    } else {
      yield put(sellerProductsListFailed(response.data.message));
    }
  } catch (error) {
    yield put(sellerProductsListFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetDealsByAdmin() {
  yield takeLatest(constants.GET_DEALS_BY_ADMIN_REQUEST, getDealsByAdmin);
}
export function* watchSearchDealsByAdmin() {
  yield takeLatest(constants.SEARCH_DEALS_BY_ADMIN_REQUEST, searchDealsByAdmin);
}
export function* watchCreateDealsByAdmin() {
  yield takeLatest(constants.CREATE_DEALS_BY_ADMIN_REQUEST, createDealsByAdmin);
}
export function* watchEditDealsByAdmin() {
  yield takeLatest(constants.EDIT_DEALS_BY_ADMIN_REQUEST, editDealsByAdmin);
}
export function* watchSortDealsByAdmin() {
  yield takeLatest(constants.SORT_DEALS_BY_ADMIN_REQUEST, sortDealsByAdmin);
}
export function* watchsellerProductsList() {
  yield takeLatest(
    constants.SELLER_PRODUCT_LIST_REQUEST,
    sellerProductsList
  );
}
function* adminDealManagementSaga() {
  yield all([
    watchGetDealsByAdmin(),
    watchCreateDealsByAdmin(),
    watchEditDealsByAdmin(),
    watchSortDealsByAdmin(),
    watchsellerProductsList(),
    watchSearchDealsByAdmin()
  ]);
}

export default adminDealManagementSaga;
