export const constants = {
  GET_SELLER_DEALS_REPORT_REQUEST: "GET_SELLER_DEALS_REPORT_REQUEST",
  GET_SELLER_DEALS_REPORT_SUCCESS: "GET_SELLER_DEALS_REPORT_SUCCESS",
  GET_SELLER_DEALS_REPORT_FAILED: "GET_SELLER_DEALS_REPORT_FAILED",

  DELETE_SELLER_DEALS_REQUEST: "DELETE_SELLER_DEALS_REQUEST",
  DELETE_SELLER_DEALS_SUCCESS: "DELETE_SELLER_DEALS_SUCCESS",
  DELETE_SELLER_DEALS_FAILED: "DELETE_SELLER_DEALS_FAILED",

  SEARCH_SELLER_DEALS_REQUEST: "SEARCH_SELLER_DEALS_REQUEST",
  SEARCH_SELLER_DEALS_SUCCESS: "SEARCH_SELLER_DEALS_SUCCESS",
  SEARCH_SELLER_DEALS_FAILED: "SEARCH_SELLER_DEALS_FAILED",
};
