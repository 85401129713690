export const constants = {
  RESET_FEEDBACK_MESSAGE: "RESET_FEEDBACK_MESSAGE",

  LIST_FEEDBACK_REQUEST: "LIST_FEEDBACK_REQUEST",
  LIST_FEEDBACK_SUCCESS: "LIST_FEEDBACK_SUCCESS",
  LIST_FEEDBACK_FAILED: "LIST_FEEDBACK_FAILED",

  FEEDBACK_STATUS_REQUEST: "FEEDBACK_STATUS_REQUEST",
  FEEDBACK_STATUS_SUCCESS: "FEEDBACK_STATUS_SUCCESS",
  FEEDBACK_STATUS_FAILED: "FEEDBACK_STATUS_FAILED",
};
