import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../helpers/api";
import {
  deleteAlertFailed,
  deleteAlertSuccess,
  getMonitoringReportFailed,
  getMonitoringReportSuccess,
  getCompetitorReportFailed,
  getCompetitorReportSuccess,
  alertRemovetSuccess,
  alertRemoveFailed,


} from "./actions";

function* getMonitoringReport({ params }) {
  let options = getProtectedOptions(endpoints.monitoringPage, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getMonitoringReportSuccess(response.data));
    } else {
      yield put(getMonitoringReportFailed(response.data.message));
    }
  } catch (error) {
    yield put(getMonitoringReportFailed(error || "Error not found!"));
  }
}

function* getCompetitorReport({ params }) {
  let options = getProtectedOptions(
    endpoints.CompetitorMonitoringReport,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getCompetitorReportSuccess(response.data));
    } else {
      yield put(getCompetitorReportFailed(response.data.message));
    }
  } catch (error) {
    yield put(getCompetitorReportFailed(error || "Error not found!"));
  }
}

function* deleteAlert({ params }) {
  let options = getProtectedOptions(endpoints.deleteAlert, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteAlertSuccess(response.data));
    } else {
      yield put(deleteAlertFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteAlertFailed(error || "Error not found!"));
  }
}
// remove toggle true alert
function* alertRemove({ params }) {
  let options = getProtectedOptions(endpoints.alertRemove, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(alertRemovetSuccess(response.data));
    } else {
      yield put(alertRemoveFailed(response.data.message));
    }
  } catch (error) {
    yield put(alertRemoveFailed(error || "Error not found!"));
  }
}


//Watcher
export function* watchGetMonitoringReport() {
  yield takeLatest(
    constants.GET_MONITORING_REPORT_REQUEST,
    getMonitoringReport
  );
}
export function* watchGetCompetitorReport() {
    yield takeLatest(
      constants.GET_COMPETITOR_REPORT_REQUEST,
      getCompetitorReport
    );
  }
export function* watchDeleteAlert() {
  yield takeLatest(constants.DELETE_ALERT_REQUEST, deleteAlert);
}
// on toggle remove
export function* watchAlertRemove() {
  yield takeLatest(constants.ALERT_REMOVE_REQUEST, alertRemove);
}


function* monitoringSaga() {
  yield all([watchGetMonitoringReport(), watchDeleteAlert(),watchAlertRemove(), watchGetCompetitorReport()]);
}

export default monitoringSaga;
