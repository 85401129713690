export const constants = {
    RESET_PRODUCTS_MESSAGE: "RESET_PRODUCTS_MESSAGE",

    SHOW_FBA_CALCULATOR: "SHOW_FBA_CALCULATOR",
    HIDE_FBA_CALCULATOR: "HIDE_FBA_CALCULATOR",

    GET_PRODUCT_LIST_REQUEST: "GET_PRODUCT_LIST_REQUEST",
    GET_PRODUCT_LIST_SUCCESS: "GET_PRODUCT_LIST_SUCCESS",
    GET_PRODUCT_LIST_FAILED: "GET_PRODUCT_LIST_FAILED",

    GET_PRODUCT_INFO_REQUEST: "GET_PRODUCT_INFO_REQUEST",
    GET_PRODUCT_INFO_SUCCESS: "GET_PRODUCT_INFO_SUCCESS",
    GET_PRODUCT_INFO_FAILED: "GET_PRODUCT_INFO_FAILED",

    GET_PRODUCT_FBA_CALC_REQUEST: "GET_PRODUCT_FBA_CALC_REQUEST",
    GET_PRODUCT_FBA_CALC_SUCCESS: "GET_PRODUCT_FBA_CALC_SUCCESS",
    GET_PRODUCT_FBA_CALC_FAILED: "GET_PRODUCT_FBA_CALC_FAILED",

    UPDATE_PRODUCT_REQUEST: "UPDATE_PRODUCT_REQUEST",
    UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
    UPDATE_PRODUCT_FAILED: "UPDATE_PRODUCT_FAILED",

    UPDATE_PRODUCT_ALERTS_REQUEST: "UPDATE_PRODUCT_ALERTS_REQUEST",
    UPDATE_PRODUCT_ALERTS_SUCCESS: "UPDATE_PRODUCT_ALERTS_SUCCESS",
    UPDATE_PRODUCT_ALERTS_FAILED: "UPDATE_PRODUCT_ALERTS_FAILED",

    ACTIVATE_PRODUCT_REQUEST: "ACTIVATE_PRODUCT_REQUEST",
    ACTIVATE_PRODUCT_SUCCESS: "ACTIVATE_PRODUCT_SUCCESS",
    ACTIVATE_PRODUCT_FAILED: "ACTIVATE_PRODUCT_FAILED",

    GET_PRODUCT_ALERTS_REQUEST: "GET_PRODUCT_ALERTS_REQUEST",
    GET_PRODUCT_ALERTS_SUCCESS: "GET_PRODUCT_ALERTS_SUCCESS",
    GET_PRODUCT_ALERTS_FAILED: "GET_PRODUCT_ALERTS_FAILED",
}