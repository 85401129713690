let baseUrl = "";
let domain = "";
let frontEndUrl = "";

if (process.env) {
  //select working env
  if (
    process.env.REACT_APP_NODE_BASE_URL &&
    process.env.REACT_APP_NODE_BASE_URL
  ) {
    // baseUrl = "https://stagingapi.sellersimple.com" || process.env.REACT_APP_NODE_BASE_URL;
    baseUrl = process.env.REACT_APP_NODE_BASE_URL;
    domain = process.env.REACT_APP_NODE_BASE_URL;
    frontEndUrl = "";
  }
}

if (!baseUrl) {
  throw new Error("API Url is not provided.");
}

export const endpoints = {
  hostDomain: domain,
  hostUrl: baseUrl,
  frontEndUrl,

  faq: `${baseUrl}/api/faq/`, // USER
  welcomePage: `${baseUrl}/api/dashboard_initial_data/`,
  changeOnboard: `${baseUrl}/api/change_on_boarding_status/`,
  userPlans: `${baseUrl}/api/plans/`,
  feedback: `${baseUrl}/api/feedback/`,
  login: `${baseUrl}/api/seller_login/`,
  addOrders: `${baseUrl}/api/order_add/`,
  getReview: `${baseUrl}/api/get_orders/`,
  contactUs: `${baseUrl}/api/contact_us/`,
  getDashboard: `${baseUrl}/api/dashboard/`,
  getPlans: `${baseUrl}/api/subscriptions/`,
  addSellerUrl: `${baseUrl}/api/add_seller/`,
  profileData: `${baseUrl}/api/seller_details/`,
  removeSeller: `${baseUrl}/api/delete_seller/`,
  sellerLogout: `${baseUrl}/api/seller_logout/`,
  getProducts: `${baseUrl}/api/product_get_api/`,
  couponCheck: `${baseUrl}/api/validate_coupon/`,
  addProducts: `${baseUrl}/api/product_add_api/`,
  register: `${baseUrl}/api/seller_registration/`,
  deleteAlert: `${baseUrl}/api/alert_delete_api/`,
  checkSellerStatus: `${baseUrl}/api/check_seller_status/`,
  alertRemove: `${baseUrl}/api/competitor_alert_remove/`,
  fetchFilterSearch: `${baseUrl}/api/competitor_fetch_filter_search/`,
  fetchasincount: `${baseUrl}/api/fetch_asin_count/`, 

  updateProduct: `${baseUrl}/api/update_product/`,
  viewFeedbacks: `${baseUrl}/api/view_feedbacks/`,
  amazonPay: `${baseUrl}/payments/initial_payment/`,
  paymentHistory: `${baseUrl}/api/payment_history/`,
  revenueReport: `${baseUrl}/api/product_analysis/`,
  changePhone: `${baseUrl}/api/change_seller_phone/`,
  sellerAccount: `${baseUrl}/api/get_seller_region/`,
  getUserNotification: `${baseUrl}/api/notifications/`,
  activateProducts: `${baseUrl}/api/products_enabling/`,
  monitoringPage: `${baseUrl}/api/monitoring_page_api/`,
  alertSettings: `${baseUrl}/api/common_alert_settings/`,
  addSubscriptionPlan: `${baseUrl}/api/add_seller_plan/`,
  getSubscriptionByUser: `${baseUrl}/api/get_seller_plan/`,
  cancelSubscription: `${baseUrl}/api/cancel_seller_plan/`,
  changeNotification: `${baseUrl}/api/change_notification/`,
  fetchInitialData: `${baseUrl}/api/initial_data_fetching/`,
  sellerLogHistory: `${baseUrl}/api/seller_log_history/`,
  changePlan: `${baseUrl}/payments/change_plan/`,
  fetchUserNotification: `${baseUrl}/api/fetch_notifications/`,
  getProductAlert: `${baseUrl}/api/product_alert_settings_api/`,
  getSellerNotification: `${baseUrl}/api/get_seller_notification/`,
  sellerDeals: `${baseUrl}/api/seller_deals/`,
  editSellerDeal: `${baseUrl}/api/edit_deals/`,
  searchSellerDeal: `${baseUrl}/api/seller_deals/`,
  sortSellerDeal: `${baseUrl}/api/sorting_deal_data/`,
  sellerDealsDropdown: `${baseUrl}/api/search_product/`,
  createSellerDeals: `${baseUrl}/api/create_single_seller_deal/`,
  createSellerBulkDeals: `${baseUrl}/api/create_bulk_coupon_seller_deal/`,
  addcompetitor: `${baseUrl}/api/competitor_add/`,
  getcompetitor: `${baseUrl}/api/get_competitor_product/`,
  getCompetitorAlerts: `${baseUrl}/api/fetch_competitor_product_alerts/`,
  updateCompetitorAlerts: `${baseUrl}/api/update_competitor_product_alerts/`,
  deletecompetitor: `${baseUrl}/api/delete_competitor_product/`,
  CompetitorMonitoringReport: `${baseUrl}/api/get_competitor_alert/`,
  fetchBlogs: `${baseUrl}/api/listing_blogs/`,
  fetchBlogsDetail: `${baseUrl}/api/fetch_blogs/`,
  fetchAllAiVideos: `${baseUrl}/api/fetch_ai_all_video/`,
  deleteAiVideos: `${baseUrl}/api/delete_ai_video/`,
  downloadAiVideos: `${baseUrl}/api/download_ai_video/`,
  getVideos: `${baseUrl}/api/generate_ai_video/`,
  getVoices: `${baseUrl}/api/get_voice_over/`,
  editVoices: `${baseUrl}/api/edit_voice_over/`,
  fetchVideo: `${baseUrl}/api/fetch_ai_video/`,
  videoPayment: `${baseUrl}/api/extra_payment/`,

  getBlog: `${baseUrl}/seller_admin/get_blogs/`,
  blogSubscription: `${baseUrl}/api/change_blog_subscription/`,
  getProductInfo: `${baseUrl}/api/get_product_info/`,
  getProductFbaCalculation: `${baseUrl}/api/get_product_fba_details/`,
  getListingCategories: `${baseUrl}/api/listing_categories/`,
  fetchCategoryTrends: `${baseUrl}/api/fetch_category_trends/`,
  categorySpy: `${baseUrl}/api/category_spy/`,
  listingSpyCategories: `${baseUrl}/api/listing_spy_categories/`,
  keywordRecommendation: `${baseUrl}/api/keyword_recommendation/`,
  keywordTitle: `${baseUrl}/api/title_generate/`,
  listingCompetitors: `${baseUrl}/api/listing_competitors/`,
  sellerSpy: `${baseUrl}/api/seller_spy/`,
  // deleteSellerSpy: `${baseUrl}/api/delete_seller_spy/`,
  deleteSellerSpy: `${baseUrl}/api/delete_spy_competitors/`,
  // deleteCategorySpy: `${baseUrl}/api/delete_category_spy/`,
  deleteCategorySpy: `${baseUrl}/api/delete_spy_categories/`,
  addSellerId: `${baseUrl}/api/add_sellerid/`,
  fetchSellerId: `${baseUrl}/api/fetch_seller_id/`,
  fetchMainCategory: `${baseUrl}/api/fetch_main_category/`,
  fetchSubCategory: `${baseUrl}/api/fetch_sub_category/`,
  asinToSelers: `${baseUrl}/api/asin_to_sellers/`,
  addSubCategory: `${baseUrl}/api/add_sub_category/`,
  addCategoryId: `${baseUrl}/api/add_categoryid/`,

  getFaq: `${baseUrl}/seller_admin/faq/`, //ADMIN
  getCoupon: `${baseUrl}/seller_admin/coupons/`,
  adminLogout: `${baseUrl}/seller_admin/logout/`,
  addAdminFaq: `${baseUrl}/seller_admin/add_faq/`,
  loginAdminUser: `${baseUrl}/seller_admin/login/`,
  addCoupon: `${baseUrl}/seller_admin/add_coupon/`,
  getFeedback: `${baseUrl}/seller_admin/feedback/`,
  editAdminFaq: `${baseUrl}/seller_admin/edit_faq/`,
  editCoupon: `${baseUrl}/seller_admin/edit_coupon/`,
  getCustomers: `${baseUrl}/seller_admin/customers/`,
  logoUpload: `${baseUrl}/seller_admin/logo_upload/`,
  getAdminDetails: `${baseUrl}/seller_admin/settings/`,
  imageUpload: `${baseUrl}/seller_admin/image_upload/`,
  deleteAdminFaq: `${baseUrl}/seller_admin/delete_faq/`,
  getDealsByAdmin: `${baseUrl}/seller_admin/admin_deals/`,
  editDealsByAdmin: `${baseUrl}/seller_admin/admin_edit_deals/`,
  getAdminAlerts: `${baseUrl}/seller_admin/global_alerts/`,
  listNotification: `${baseUrl}/seller_admin/notification/`,
  adminPassReset: `${baseUrl}/seller_admin/reset_password/`,
  backgroundTask: `${baseUrl}/seller_admin/background_task/`,
  changeFeedback: `${baseUrl}/seller_admin/change_feedback/`,
  customerEmailSend: `${baseUrl}/seller_admin/customer_email/`,
  sentPassResetLink: `${baseUrl}/seller_admin/forgot_password/`,
  getAdminEnquiry: `${baseUrl}/seller_admin/contact_us_inquiry/`,
  updateAdminSettings: `${baseUrl}/seller_admin/update_settings/`,
  createDealsByAdmin: `${baseUrl}/seller_admin/create_admin_deals/`,
  updateAdminAlerts: `${baseUrl}/seller_admin/status_global_alert/`,
  getAdminSubscription: `${baseUrl}/seller_admin/subscription_plan/`,
  changePassword: `${baseUrl}/seller_admin/change_password/<int:pk>/`,
  getRevenueGraphApi: `${baseUrl}/seller_admin/dashboard_revenue_graph/`,
  changeAdminNotification: `${baseUrl}/seller_admin/change_notification/`,
  // getRevenueGraphApi: `${baseUrl}/seller_admin/dashboard_revenue_graph/`,
  getSubscriptionGraph: `${baseUrl}/seller_admin/dashboard_subscription_graph/`,
  getSellerDealsReport: `${baseUrl}/seller_admin/fetch_seller_deal/`,
  deleteSellerDeals: `${baseUrl}/seller_admin/delete_seller_deal/`,
  searchSortSellerDeals: `${baseUrl}/seller_admin/drop_down_deal_search/`,
  sortDealsByAdmin: `${baseUrl}/seller_admin/sort_filter_admin_deal_data/`,
  sellerProductsList: `${baseUrl}/seller_admin/drop_down_search/`,
  getUserReport: `${baseUrl}/seller_admin/user_report/`,
  getDashboardData: `${baseUrl}/seller_admin/deal_dashboard_for_admin/`,
  getDealSuggestions: `${baseUrl}/seller_admin/get_deal_suggestions/`,
  fetchProduct: `${baseUrl}/seller_admin/fetching_products/`,
  getDealDetails: `${baseUrl}/seller_admin/single_deal_suggestions/`,
  rejectSuggestedDeal: `${baseUrl}/seller_admin/reject_deal_suggestions/`,
  createSuggestedDeal: `${baseUrl}/seller_admin/deal_create_deal_suggestions/`,
  getBlogs: `${baseUrl}/seller_admin/get_blogs/`,
  deleteBlog: `${baseUrl}/seller_admin/delete_blog/`,
  sellerDashboardData: `${baseUrl}/api/seller_deal_dashboard/`,
  getBlogData: `${baseUrl}/seller_admin/fetch_blog/`,
  editBlog: `${baseUrl}/seller_admin/edit_blog/`,
  addBlog: `${baseUrl}/seller_admin/create_blog/`,
  searchSortBlog: `${baseUrl}/seller_admin/sort_search_blog/`,
  sortSuggestion: `${baseUrl}/seller_admin/sort_deal_suggestions/`,
  fetchReferAndEarn: `${baseUrl}/seller_admin/fetch_refer_and_earn/`,
  approveReferral: `${baseUrl}/seller_admin/admin_initiate_giftcard/`,
  editDealSuggestions: `${baseUrl}/seller_admin/admin_edit_deals_suggestions/`,
};
