import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  pageCount: null,
  enquiryList: [],
  errorMessage: null,
  successMessage: null,
};

const AdminEnquiry = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.GET_ADMIN_ENQUIRY_REQUEST: // REQUEST
      return { ...state, loading: true };

    case constants.GET_ADMIN_ENQUIRY_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };

    case constants.GET_ADMIN_ENQUIRY_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        errorMessage: null,
        enquiryList: action.payload.data,
        pageCount: action.payload.total_pages,
      };

    default:
      return { ...state };
  }
};

export default AdminEnquiry;
