import { constants } from "./constants";

const INIT_STATE = {
    loading: false,
    pageCount: null,
    revenueReport: null,
    analyticsErrorMessage: null,
    analyticsSuccessMessage: null
};

const Analytics = (state = INIT_STATE, action) => {
    switch (action.type) {
        case constants.RESET_ANALYTICS_MESSAGE:          //RESET MSGS
            return {
                ...state,
                analyticsErrorMessage: null,
                analyticsSuccessMessage: null
            };

        case constants.GET_REVENUE_REPORT_REQUEST: // REQUEST
            return { ...state, loading: true };

        case constants.GET_REVENUE_REPORT_FAILED:     //FAILED
            return {
                ...state,
                analyticsErrorMessage: action.payload,
                loading: false
            };

        case constants.GET_REVENUE_REPORT_SUCCESS:          //SUCCESS
            return {
                ...state,
                loading: false,
                productsErrorMessage: null,
                revenueReport: action.payload.data,
                pageCount: action.payload.total_pages,
            };
        default:
            return { ...state };
    }
};

export default Analytics;