import { constants } from "./constants";

const INIT_STATE = {
  reportsList: [],
  exportReportsList:[],
  total_count:0,
  total_pages:10,
  loading:false,
  pageCount: null,
  isDeleted: false,
  errorMessage: null,
  successMessage: null,
  
};

const UserReport = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_USER_REPORT_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
        isDeleted: false,
      };


    case constants.GET_USER_REPORT_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,
        isDeleted: false,
        loading: false,
      };

    case constants.GET_USER_REPORT_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        isDeleted: false,
        errorMessage: null,
        reportsList: action.payload.data,
        total_pages: action.payload.total_pages,
      };
    default:
      return { ...state };
  }
};

export default UserReport;
