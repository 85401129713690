import { constants } from "./constants";


export const getSellerDealsReport = (data) => ({
  type: constants.GET_SELLER_DEALS_REPORT_REQUEST,
  data,
});

export const getSellerDealsReportSuccess = (data) => ({
  type: constants.GET_SELLER_DEALS_REPORT_SUCCESS,
  payload: data,
});

export const getSellerDealsReportFailed = (error) => ({
  type: constants.GET_SELLER_DEALS_REPORT_FAILED,
  payload: error,
});

export const deleteSellerDeals = (data) => ({
  type: constants.DELETE_SELLER_DEALS_REQUEST,
  data,
});

export const deleteSellerDealsSuccess = (data) => ({
  type: constants.DELETE_SELLER_DEALS_SUCCESS,
  payload: data,
});

export const deleteSellerDealsFailed = (error) => ({
  type: constants.DELETE_SELLER_DEALS_FAILED,
  payload: error,
});

export const searchSortSellerDeals = (data) => ({
  type: constants.SEARCH_SELLER_DEALS_REQUEST,
  data,
});

export const searchSortSellerDealsSuccess = (data) => ({
  type: constants.SEARCH_SELLER_DEALS_SUCCESS,
  payload: data,
});

export const searchSortSellerDealsFailed = (error) => ({
  type: constants.SEARCH_SELLER_DEALS_FAILED,
  payload: error,
});
