import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../helpers/api";
import { getQueryParams } from "../../helpers/structureData";
import {
  fetchSellerIdSuccess,
  fetchSellerIdFailed,
  getSellerSpyFailed,
  getSellerSpySuccess,
  fetchAsinCountSuccess,
  fetchAsinCountFailed,
  deleteSellerSpySuccess,
  deleteSellerSpyFailed,
  addSellerIdSuccess,
  addSellerIdFailed,
  asinToSelersSuccess,
  asinToSelersIdFailed,
  fetchCompetitorTableDataSuccess,
  fetchCompetitorTableDataFailed,
} from "./actions";

function* getSellerSpy({ params }) {
  // let options = getUnProtectedOptions(endpoints.sellerSpy, "GET", params);
  let options = getUnProtectedOptions(
    `${endpoints.sellerSpy}?${getQueryParams(params)}`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getSellerSpySuccess(response.data));
    } else {
      yield put(getSellerSpyFailed(response.data));
    }
  } catch (error) {
    yield put(getSellerSpyFailed(error || "Error not found!"));
  }
}

function* addSellerId({ params }) {
  // let options = getUnProtectedOptions(endpoints.sellerSpy, "GET", params);
  let options = getUnProtectedOptions(
    `${endpoints.addSellerId}?${getQueryParams(params)}&${
      params.offset === 0 && "offset=0"
    }`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addSellerIdSuccess(response.data));
    } else {
      yield put(addSellerIdFailed(response.data));
    }
  } catch (error) {
    yield put(addSellerIdFailed(error || "Error not found!"));
  }
}

function* deleteSellerSpy({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.deleteSellerSpy}?${getQueryParams(params)}`,
    "GET",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteSellerSpySuccess(response.data));
    } else {
      yield put(deleteSellerSpyFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteSellerSpyFailed(error || "Error not found!"));
  }
}

function* fetchSellerId({ params }) {
  let options = getUnProtectedOptions(endpoints.fetchSellerId, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchSellerIdSuccess(response.data));
    } else {
      yield put(fetchSellerIdFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchSellerIdFailed(error || "Error not found!"));
  }
}

function* asinToSelers({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.asinToSelers}?${getQueryParams(params)}`,
    "GET",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(asinToSelersSuccess(response.data));
    } else {
      yield put(asinToSelersIdFailed(response.data.message));
    }
  } catch (error) {
    yield put(asinToSelersIdFailed(error || "Error not found!"));
  }
}

function* fetchCompetitorData({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.listingCompetitors}?${getQueryParams(params)}`,
    "GET",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchCompetitorTableDataSuccess(response.data));
    } else {
      yield put(fetchCompetitorTableDataFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchCompetitorTableDataFailed(error || "Error not found!"));
  }
}
function* fetchasincount({ params }) {
  let options = getUnProtectedOptions(endpoints.fetchasincount, 'POST', params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchAsinCountSuccess(response.data));
    } else {
      yield put(fetchAsinCountFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchAsinCountFailed(error || 'Error not found!'));
  }
}

//Watcher
export function* watchGetSellerSpy() {
  yield takeLatest(constants.GET_SELLER_SPY_REQUEST, getSellerSpy);
}
export function* watchSellerId() {
  yield takeLatest(constants.ADD_SELLER_ID_REQUEST, addSellerId);
}
export function* watchDeleteSellerSpy() {
  yield takeLatest(constants.DELETE_SELLER_SPY_REQUEST, deleteSellerSpy);
}
export function* watchFetchSellerId() {
  yield takeLatest(constants.FETCH_SELLER_ID_REQUEST, fetchSellerId);
}
export function* watchAsinToSelers() {
  yield takeLatest(constants.ASIN_TO_SELLER_REQUEST, asinToSelers);
}
export function* watchFetchCompetitorData() {
  yield takeLatest(constants.FETCH_COMPETITOR_DATA_REQUEST,fetchCompetitorData);
}
export function* watchFetchasincount() {
  yield takeLatest(constants.FETCH_ASIN_COUNT_REQUEST, fetchasincount);
}

function* CompetitorSpySaga() {
  yield all([
    watchGetSellerSpy(),
    watchSellerId(),
    watchDeleteSellerSpy(),
    watchFetchSellerId(),
    watchAsinToSelers(),
    watchFetchCompetitorData(),
    watchFetchasincount(),
  ]);
}

export default CompetitorSpySaga;
