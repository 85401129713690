import { constants } from "./constants";

export const getVideo = (params) => ({
  type: constants.GET_VIDEO_REQUEST,
  params,
});

export const getVideoSuccess = (video) => ({
  type: constants.GET_VIDEO_SUCCESS,
  payload: video,
});

export const getVideoFailed = (error) => ({
  type: constants.GET_VIDEO_FAILED,
  payload: error,
});

export const getVoice = (params) => ({
  type: constants.GET_VOICE_REQUEST,
  params,
});

export const getVoiceSuccess = (video) => ({
  type: constants.GET_VOICE_SUCCESS,
  payload: video,
});

export const getVoiceFailed = (error) => ({
  type: constants.GET_VOICE_FAILED,
  payload: error,
});

export const editVoice = (params) => ({
  type: constants.EDIT_VOICE_REQUEST,
  params,
});

export const editVoiceSuccess = (video) => ({
  type: constants.EDIT_VOICE_SUCCESS,
  payload: video,
});

export const editVoiceFailed = (error) => ({
  type: constants.EDIT_VOICE_FAILED,
  payload: error,
});

export const fetchVideo = (params) => ({
  type: constants.FETCH_VIDEO_REQUEST,
  params,
});

export const fetchVideoSuccess = (video) => ({
  type: constants.FETCH_VIDEO_SUCCESS,
  payload: video,
});

export const fetchVideoFailed = (error) => ({
  type: constants.FETCH_VIDEO_FAILED,
  payload: error,
});

export const fetchAllVideo = (params) => ({
  type: constants.FETCH_ALL_VIDEO_REQUEST,
  params,
});

export const fetchAllVideoSuccess = (video) => ({
  type: constants.FETCH_ALL_VIDEO_SUCCESS,
  payload: video,
});

export const fetchAllVideoFailed = (error) => ({
  type: constants.FETCH_ALL_VIDEO_FAILED,
  payload: error,
});

export const deleteVideo = (params) => ({
  type: constants.DELETE_VIDEO_REQUEST,
  params,
});

export const deleteVideoSuccess = (video) => ({
  type: constants.DELETE_VIDEO_SUCCESS,
  payload: video,
});

export const deleteVideoFailed = (error) => ({
  type: constants.DELETE_VIDEO_FAILED,
  payload: error,
});

export const downloadVideo = (params) => ({
  type: constants.DOWNLOAD_VIDEO_REQUEST,
  params,
});

export const downloadVideoSuccess = (video) => ({
  type: constants.DOWNLOAD_VIDEO_SUCCESS,
  payload: video,
});

export const downloadVideoFailed = (error) => ({
  type: constants.DOWNLOAD_VIDEO_FAILED,
  payload: error,
});

export const videoPayment = (params) => ({
  type: constants.VIDEO_PAYMENT_REQUEST,
  params,
});

export const videoPaymentSuccess = (video) => ({
  type: constants.VIDEO_PAYMENT_SUCCESS,
  payload: video,
});

export const videoPaymentFailed = (error) => ({
  type: constants.VIDEO_PAYMENT_FAILED,
  payload: error,
});


export const setPaymentStatusfalse = (data) => ({
  type: constants.PAYMENT_STATUS_UPDATE,
  payload:data,
})