import React, { useEffect } from "react";
import ReactGA from "react-ga";
import Loadable from "react-loadable";
import { Route, useHistory, useLocation } from "react-router-dom";

import NotFound from "../components/common/NotFound";
import LoaderSpinner from "../components/common/Loader";
import { getLoggedInUser } from "../helpers/authUtils";
import ErrorBoundary from "../components/common/ErrorBoundary";
import { Helmet } from "react-helmet";
const { getMetaData } = require("../helpers/metaDatas");

ReactGA.initialize("UA-218477726-1");

const AuthLayout = Loadable({
  loader: () => import("../layout/AuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading: LoaderSpinner,
});

const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.set({ userId: null });
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(window.location.pathname);
  }, [location.pathname]);
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  useEffect(() => {
    if (rest.roles) {
      const loggedUser = getLoggedInUser();
      if (loggedUser?.user) {
        const normalUser = loggedUser?.user?.userType === 1;
        if (!normalUser) {
          rest.roles.includes("USER") && history.push("/");
        }
        rest.roles.includes("PUBLIC") && history.push("/");
      }
    }
  }, []);

  usePageViews();
  let metaData = getMetaData(window.location.pathname)
  return (
    <>
      {rest?.path == "*" ? (
        <NotFound />
      ) : (
        <ErrorBoundary>
          <Helmet>
            <title>
              {metaData
                ? metaData?.metaTitle
                : "Amazon Seller Software | Product Tracking Tools for Amazon Sellers"}
            </title>
          </Helmet>

          <AuthLayout {...rest}>
            <Route {...rest} render={(props) => <Component {...props} />} />
          </AuthLayout>
        </ErrorBoundary>
      )}
    </>
  );
};

export default PublicRoute;
