// @flow
import { combineReducers } from "redux";

import Auth from "./auth/reducer";
import Public from "./public/UserModule/reducer";
import Review from "../containers/Reviews/reducer";
import Welcome from "../containers/Welcome/reducer";
import Products from "../containers/Products/reducer";
import Settings from "../containers/Settings/reducer";
import Analytics from "../containers/Analytics/reducer";
import Dashboard from "../containers/Dashboard/reducer";
import Monitoring from "../containers/Monitoring/reducer";
import OnBoarding from "../containers/OnBoarding/reducer";
import AdminFaq from "../containers/AdminModule/FAQ/reducer";
import FbaCalculator from "../containers/FbaCalculator/reducer";
import Category from "../containers/Category/reducer";
import CategorySpy from "../containers/CategorySpy/reducer";
import CompetitorSpy from "../containers/CompetitorSpy/reducer";
import KeywordReducer from "../containers/Keyword/reducer";
import Video from "../containers/videoCreation/reducer";
import OtherProducts from "../containers/OtherProducts/reducer";
import AdminAlerts from "../containers/AdminModule/Alerts/reducer";
import Customer from "../containers/AdminModule/Customers/reducer";
import DealsManagement from "../containers/DealsManagement/reducer";
import SellerDealsDashboard from "../containers/sellerDashboard/reducer";

import AdminEnquiry from "../containers/AdminModule/AdminEnquiry/reducer";
import AdminDashboard from "../containers/AdminModule/AdminDashboard/reducer";
import AdminSettings from "../containers/AdminModule/AdminSettings/reducer";
import AdminSubscription from "../containers/AdminModule/AdminSubscription/reducer";
import adminFeedback from "../containers/AdminModule/AdminFeedback/reducer";
import AdminDealsManagement from "../containers/AdminModule/AdminDealsManagement/reducer";
import AdminSellerDeals from "../containers/AdminModule/AdminSellerDealsReport/reducer";
import AdminUserReport from "../containers/AdminModule/AdminUserReport/reducer";
import AdminDealsDashboard from "../containers/AdminModule/AdminDealDashboard/reducer";
import AdminDealSuggestions from "../containers/AdminModule/AdminDealSuggestions/reducer";
import AdminAmzBlogs from "../containers/AdminModule/BlogManagement/reducer";
import ReferandEarn from "../containers/AdminModule/ReferandEarn/reducer";

export default combineReducers({
  Auth, //User
  Review,
  FbaCalculator,
  Category,
  CategorySpy,
  CompetitorSpy,
  KeywordReducer,
  Video,
  Public,
  Welcome,
  Products,
  Settings,
  Analytics,
  Dashboard,
  Monitoring,
  OnBoarding,
  OtherProducts,
  DealsManagement,
  SellerDealsDashboard,

  
  Customer, //Admin
  AdminFaq,
  AdminAlerts,
  AdminEnquiry,
  AdminSettings,
  AdminAmzBlogs,
  AdminDashboard,
  adminFeedback,
  AdminUserReport,
  AdminSellerDeals,
  AdminSubscription,
  AdminDealsDashboard,
  AdminDealsManagement,
  AdminDealSuggestions,
  ReferandEarn
});
