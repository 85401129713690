import axios from "axios";
import { getLoggedInUser } from "./authUtils";
import { toast } from "react-toastify";

axios.defaults.timeout = 150000;

// const loggedUser = getLoggedInUser();

const apiCall = (options) => {
  const loggedUser = getLoggedInUser();
  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((res) => {
      if (
        res.response &&
        res.response.status === 403 &&
        res.response.config.url !==
          "https://api.sellersimple.com/api/fetch_notifications/"
      ) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href =
          loggedUser?.user?.userType === 2 ? "/admin" : "/";
        return;
      }
      if (
        res.response &&
        res.response.status === (400 || 403 || 404 || 500 || 503)
      ) {
        toast.error("Something went wrong!! Please try again later..");
      }
      if (res.response && res.response.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href =
          loggedUser?.user?.userType === 2 ? "/admin" : "/";
        return;
      }
      return res.response;
    });
};

const getUnProtectedOptions = (url, method, data = {}, type = "") => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
    method,
    url: url,
    data: type === "file" ? data : JSON.stringify(data),
  };
};

const getProtectedOptions = (url, method, data = {}, type = "") => {
  const loggedUser = getLoggedInUser();
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${loggedUser.user.accessToken || ""}`,
    },
    method,
    url: url,
    data: type === "file" ? data : JSON.stringify(data),
  };
};

export { apiCall, getProtectedOptions, getUnProtectedOptions };
