import { constants } from "./constants";

export const sellerDeals = (params) => ({
  type: constants.GET_SELLER_DEALS_REQUEST,
  params,
});
export const sellerDealsSuccess = (products) => ({
  type: constants.GET_SELLER_DEALS_SUCCESS,
  payload: products,
});
export const sellerDealsFailed = (error) => ({
  type: constants.GET_SELLER_DEALS_FAILED,
  payload: error,
});
export const getProductList = (params) => ({
  type: constants.GET_DEALS_PRODUCT_LIST_REQUEST,
  params,
});
export const getProductListSuccess = (products) => ({
  type: constants.GET_DEALS_PRODUCT_LIST_SUCCESS,
  payload: products,
});
export const getProductListFailed = (error) => ({
  type: constants.GET_DEALS_PRODUCT_LIST_FAILED,
  payload: error,
});
export const createSellerDeals = (params) => ({
  type: constants.CREATE_SELLER_DEALS_REQUEST,
  params,
});

export const createSellerDealsSuccess = (products) => ({
  type: constants.CREATE_SELLER_DEALS_SUCCESS,
  payload: products,
});
export const createSellerDealsFailed = (error) => ({
  type: constants.CREATE_SELLER_DEALS_FAILED,
  payload: error,
});
export const createSellerBulkDeals = (params) => ({
  type: constants.CREATE_SELLER_BULK_DEALS_REQUEST,
  params,
});

export const createSellerBulkDealsSuccess = (products) => ({
  type: constants.CREATE_SELLER_BULK_DEALS_SUCCESS,
  payload: products,
});
export const createSellerBulkDealsFailed = (error) => ({
  type: constants.CREATE_SELLER_BULK_DEALS_FAILED,
  payload: error,
});
export const editSellerDeal = (params) => ({
  type: constants.EDIT_SELLER_DEAL_REQUEST,
  params,
});
export const editSellerDealSuccess = (products) => ({
  type: constants.EDIT_SELLER_DEAL_SUCCESS,
  payload: products,
});
export const editSellerDealFailed = (error) => ({
  type: constants.EDIT_SELLER_DEAL_FAILED,
  payload: error,
});
export const searchSellerDeal = (params) => ({
  type: constants.SEARCH_SELLER_DEAL_REQUEST,
  params,
});
export const searchSellerDealSuccess = (products) => ({
  type: constants.SEARCH_SELLER_DEAL_SUCCESS,
  payload: products,
});
export const searchSellerDealFailed = (error) => ({
  type: constants.SEARCH_SELLER_DEAL_FAILED,
  payload: error,
});
export const sortSellerDeal = (params) => ({
  type: constants.SORT_SELLER_DEAL_REQUEST,
  params,
});
export const sortSellerDealSuccess = (products) => ({
  type: constants.SORT_SELLER_DEAL_SUCCESS,
  payload: products,
});
export const sortSellerDealFailed = (error) => ({
  type: constants.SORT_SELLER_DEAL_FAILED,
  payload: error,
});
