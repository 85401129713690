import { constants } from "./constants";

export const resetMessages = () => ({
  type: constants.RESET_ADMIN_COUPON_MESSAGE,
});

export const getCoupon = (data) => ({
  type: constants.GET_ADMIN_COUPON_REQUEST,
  data,
});

export const getCouponSuccess = (data) => ({
  type: constants.GET_ADMIN_COUPON_SUCCESS,
  payload: data,
});

export const getCouponFailed = (error) => ({
  type: constants.GET_ADMIN_COUPON_FAILED,
  payload: error,
});

export const addCoupon = (data) => ({
  type: constants.ADD_ADMIN_COUPON_REQUEST,
  data,
});

export const addCouponSuccess = (data) => ({
  type: constants.ADD_ADMIN_COUPON_SUCCESS,
  payload: data,
});

export const addCouponFailed = (error) => ({
  type: constants.ADD_ADMIN_COUPON_FAILED,
  payload: error,
});

export const editCoupon = (data) => ({
  type: constants.EDIT_ADMIN_COUPON_REQUEST,
  data,
});

export const editCouponSuccess = (data) => ({
  type: constants.EDIT_ADMIN_COUPON_SUCCESS,
  payload: data,
});

export const editCouponFailed = (error) => ({
  type: constants.EDIT_ADMIN_COUPON_FAILED,
  payload: error,
});

export const getAdminSubscriptions = (data) => ({
  type: constants.GET_ADMIN_SUBSCRIPTIONS_REQUEST,
  data,
});

export const getAdminSubscriptionsSuccess = (data) => ({
  type: constants.GET_ADMIN_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});

export const getAdminSubscriptionsFailed = (error) => ({
  type: constants.GET_ADMIN_SUBSCRIPTIONS_FAILED,
  payload: error,
});
