export const constants = {
    RESET_ADMIN_ALERTS_MESSAGE: "RESET_ADMIN_ALERTS_MESSAGE",

    GET_ADMIN_ALERTS_REQUEST: "GET_ADMIN_ALERTS_REQUEST",
    GET_ADMIN_ALERTS_SUCCESS: "GET_ADMIN_ALERTS_SUCCESS",
    GET_ADMIN_ALERTS_FAILED: "GET_ADMIN_ALERTS_FAILED",

    UPDATE_ADMIN_ALERTS_REQUEST: "UPDATE_ADMIN_ALERTS_REQUEST",
    UPDATE_ADMIN_ALERTS_SUCCESS: "UPDATE_ADMIN_ALERTS_SUCCESS",
    UPDATE_ADMIN_ALERTS_FAILED: "UPDATE_ADMIN_ALERTS_FAILED",

    // EDIT_ADMIN_ALERTS_REQUEST: "EDIT_ADMIN_ALERTS_REQUEST",
    // EDIT_ADMIN_ALERTS_SUCCESS: "EDIT_ADMIN_ALERTS_SUCCESS",
    // EDIT_ADMIN_ALERTS_FAILED: "EDIT_ADMIN_ALERTS_FAILED",

    // // DELETE_ADMIN_ALERTS_REQUEST: "DELETE_ADMIN_ALERTS_REQUEST",
    // // DELETE_ADMIN_ALERTS_SUCCESS: "DELETE_ADMIN_ALERTS_SUCCESS",
    // // DELETE_ADMIN_ALERTS_FAILED: "DELETE_ADMIN_ALERTS_FAILED",
}