import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  fbaError: false,
  loadingInfo: false,
  fbaModal: false,
  pageCount: null,
  productList: null,
  productAlerts: null,
  changedProduct: null,
  productAsin: null,
  productPrice: null,
  productImg: null,
  productInfo: null,
  productFbaCalc: null,
  productsErrorMessage: null,
  productsSuccessMessage: null,
};

const Products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_PRODUCTS_MESSAGE: //RESET MSGS
      return {
        ...state,
        productsErrorMessage: null,
        productsSuccessMessage: null,
      };

    // REQUEST
    case constants.UPDATE_PRODUCT_REQUEST:
    case constants.GET_PRODUCT_LIST_REQUEST:
    case constants.UPDATE_PRODUCT_ALERTS_REQUEST:
    case constants.ACTIVATE_PRODUCT_REQUEST:
    case constants.GET_PRODUCT_ALERTS_REQUEST:
      return { ...state, loading: true };

    case constants.GET_PRODUCT_FBA_CALC_REQUEST:
      return { ...state, loading: false, productFbaCalc: null };

    case constants.GET_PRODUCT_INFO_REQUEST:
      return {
        ...state,
        loadingInfo: true,
        productInfo: null,
        fbaError: false,
      };

    //FAILED
    case constants.UPDATE_PRODUCT_FAILED:
    case constants.GET_PRODUCT_LIST_FAILED:
    case constants.UPDATE_PRODUCT_ALERTS_FAILED:
    case constants.ACTIVATE_PRODUCT_FAILED:
    case constants.GET_PRODUCT_ALERTS_FAILED:
      return {
        ...state,
        productsErrorMessage: action.payload,
        loading: false,
      };
    case constants.GET_PRODUCT_FBA_CALC_FAILED:
      return {
        ...state,
        productsErrorMessage: action.payload,
        loading: false,
        productFbaCalc: true,
      };
    case constants.GET_PRODUCT_INFO_FAILED:
      return {
        ...state,
        fbaError: action.payload,
        productInfo: null,
        loadingInfo: false,
      };

    case constants.GET_PRODUCT_LIST_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        changedProduct: null,
        productsErrorMessage: null,
        productList: action.payload.data,
        pageCount: action.payload.total_pages,
      };
    case constants.GET_PRODUCT_INFO_SUCCESS:
      return {
        ...state,
        loadingInfo: false,
        changedProduct: null,
        productsErrorMessage: null,
        productInfo: action.payload.data,
        productPrice: action.payload.data.product_price || 0,
      };

    case constants.GET_PRODUCT_FBA_CALC_SUCCESS:
      return {
        ...state,
        loading: false,
        changedProduct: null,
        productsErrorMessage: null,
        productFbaCalc: action.payload.asin,
      };

    case constants.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productsErrorMessage: null,
        changedProduct: action.payload.data,
        productsSuccessMessage: action.payload.message,
      };
    case constants.UPDATE_PRODUCT_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productAlerts: null,
        productsErrorMessage: null,
        productsSuccessMessage: action.payload.message,
      };
    case constants.ACTIVATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productsErrorMessage: null,
        productsSuccessMessage: action.payload.message,
      };
    case constants.GET_PRODUCT_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productsErrorMessage: null,
        productAlerts: action.payload.data.user_product_alert,
      };

    case constants.SHOW_FBA_CALCULATOR:
      return {
        ...state,
        loading: false,
        fbaModal: true,
        productAsin: action?.productAsin || null,
        productImg: action?.productImg || null,
      };
    case constants.HIDE_FBA_CALCULATOR:
      return {
        ...state,
        loading: false,
        fbaModal: false,
        fbaError: false,
        productImg: null,
        productInfo: null,
        productAsin: null,
        productPrice: null,
        productFbaCalc: null,
      };
    default:
      return { ...state };
  }
};

export default Products;
