import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../helpers/api";
import { getDashboardDataFailed, getDashboardDataSuccess } from "./action";

function* getDashboardDataList({ data }) {
  let options = getProtectedOptions(
    endpoints.sellerDashboardData,
    "POST",
    data
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getDashboardDataSuccess(response.data));
    } else {
      yield put(getDashboardDataFailed(response.data.message));
    }
  } catch (error) {
    yield put(getDashboardDataFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchgetSellerDealsReport() {
  yield takeLatest(
    constants.GET_SELLER_DASHBOARD_DATA_REQUEST,
    getDashboardDataList
  );
}

function* SellerDealDashboardsaga() {
  yield all([watchgetSellerDealsReport()]);
}

export default SellerDealDashboardsaga;
