import { constants } from "./constants";

export const loginUser = (loginData) => ({
  type: constants.LOGIN_USER_REQUEST,
  loginData,
});

export const loginUserSuccess = (user) => ({
  type: constants.LOGIN_USER_SUCCESS,
  payload: user,
});

export const loginUserFailed = (error) => ({
  type: constants.LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (registerData) => ({
  type: constants.REGISTRATION_REQUEST,
  registerData,
});

export const registerUserSuccess = (user) => ({
  type: constants.REGISTRATION_SUCCESS,
  payload: user,
});

export const registerUserFailed = (error) => ({
  type: constants.REGISTRATION_FAILED,
  payload: error,
});

export const resetAuthMessages = () => ({
  type: constants.RESET_AUTH_MESSAGE,
});

export const getAuthUrl = (region) => ({
  type: constants.GET_AUTH_URL_REQUEST,
  region,
});

export const getAuthUrlSuccess = (data) => ({
  type: constants.GET_AUTH_URL_SUCCESS,
  payload: data,
});

export const getAuthUrlFailed = (error) => ({
  type: constants.GET_AUTH_URL_FAILED,
  payload: error,
});

export const addSellerAccount = (sellerAccountData) => ({
  type: constants.ADD_SELLER_ACCOUNT_REQUEST,
  sellerAccountData,
});

export const addSellerAccountSuccess = (data) => ({
  type: constants.ADD_SELLER_ACCOUNT_SUCCESS,
  payload: data,
});

export const addSellerAccountFailed = (error) => ({
  type: constants.ADD_SELLER_ACCOUNT_FAILED,
  payload: error,
});

export const loginAdminUser = (loginData) => ({
  type: constants.LOGIN_ADMIN_USER_REQUEST,
  loginData,
});

export const loginAdminUserSuccess = (user) => ({
  type: constants.LOGIN_ADMIN_USER_SUCCESS,
  payload: user,
});

export const loginAdminUserFailed = (error) => ({
  type: constants.LOGIN_ADMIN_USER_FAILED,
  payload: error,
});

export const getUserNotifications = (params) => ({
  type: constants.GET_USER_NOTIFICATIONS_REQUEST,
  params,
});

export const getUserNotificationsSuccess = (payload) => ({
  type: constants.GET_USER_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getUserNotificationsFailed = (error) => ({
  type: constants.GET_USER_NOTIFICATIONS_FAILED,
  payload: error,
});


export const fetchUserNotifications = (params) => ({
  type: constants.FETCH_USER_NOTIFICATIONS_REQUEST,
  params,
});

export const fetchUserNotificationsSuccess = (payload) => ({
  type: constants.FETCH_USER_NOTIFICATIONS_SUCCESS,
  payload,
});

export const fetchUserNotificationsFailed = (error) => ({
  type: constants.FETCH_USER_NOTIFICATIONS_FAILED,
  payload: error,
});

export const getAdminNotifications = (params) => ({
  type: constants.GET_ADMIN_NOTIFICATIONS_REQUEST,
  params,
});

export const getAdminNotificationsSuccess = (payload) => ({
  type: constants.GET_ADMIN_NOTIFICATIONS_SUCCESS,
  payload,
});

export const getAdminNotificationsFailed = (error) => ({
  type: constants.GET_ADMIN_NOTIFICATIONS_FAILED,
  payload: error,
});

export const changeAdminNotifications = (params) => ({
  type: constants.CHANGE_ADMIN_NOTIFICATIONS_REQUEST,
  params,
});

export const changeAdminNotificationsSuccess = (payload) => ({
  type: constants.CHANGE_ADMIN_NOTIFICATIONS_SUCCESS,
  payload,
});

export const changeAdminNotificationsFailed = (error) => ({
  type: constants.CHANGE_ADMIN_NOTIFICATIONS_FAILED,
  payload: error,
});

export const loginOutSeller = () => ({
  type: constants.LOGOUT_SELLER_USER_REQUEST,
});

export const loginOutSellerSuccess = (user) => ({
  type: constants.LOGOUT_SELLER_USER_SUCCESS,
  payload: user,
});

export const loginOutSellerFailed = (error) => ({
  type: constants.LOGOUT_SELLER_USER_FAILED,
  payload: error,
});

export const loginOutAdminUser = () => ({
  type: constants.LOGOUT_ADMIN_USER_REQUEST,
});

export const loginOutAdminSuccess = (user) => ({
  type: constants.LOGOUT_ADMIN_USER_SUCCESS,
  payload: user,
});

export const loginOutAdminFailed = (error) => ({
  type: constants.LOGOUT_ADMIN_USER_FAILED,
  payload: error,
});

export const sentPassResetLink = (params) => ({
  type: constants.SENT_PASS_RESET_LINK_REQUEST,
  params,
});

export const sentPassResetLinkSuccess = (user) => ({
  type: constants.SENT_PASS_RESET_LINK_SUCCESS,
  payload: user,
});

export const sentPassResetLinkFailed = (error) => ({
  type: constants.SENT_PASS_RESET_LINK_FAILED,
  payload: error,
});

export const adminPassResetLink = (params) => ({
  type: constants.ADMIN_PASS_RESET_REQUEST,
  params,
});

export const adminPassResetLinkSuccess = (user) => ({
  type: constants.ADMIN_PASS_RESET_SUCCESS,
  payload: user,
});

export const adminPassResetLinkFailed = (error) => ({
  type: constants.ADMIN_PASS_RESET_FAILED,
  payload: error,
});

export const sellerLogHistoryRequest = (params) => ({
  type: constants.SELLER_LOG_HISTORY_REQUEST,
  params,
});

export const sellerLogHistorySuccess = (payload) => ({
  type: constants.SELLER_LOG_HISTORY_SUCCESS,
  payload,
});

export const sellerLogHistoryFailed = (error) => ({
  type: constants.SELLER_LOG_HISTORY_FAILED,
  payload: error,
});
