import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  pageCount: 0,
  productList: null,
  total_count: null,
  total_pages: null,
  productAlerts: null,
  changedProduct: null,
  productsErrorMessage: null,
  productsSuccessMessage: null,
};

const OtherProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_COMPETITOR_REQUEST:
    case constants.ADD_COMPETITOR_REQUEST:
    case constants.DELETE_COMPETITOR_REQUEST:
    case constants.UPDATE_COMPETITOR_ALERTS_REQUEST:
      return { ...state, loading: true, productsSuccessMessage: false };
    case constants.FETCH_FILTER_SEARCH_REQUEST:
    case constants.GET_COMPETITOR_ALERTS_REQUEST:
      return { ...state, loading: false, productsSuccessMessage: false };
    //FAILED
    case constants.GET_COMPETITOR_FAILED:
    case constants.GET_COMPETITOR_ALERTS_FAILED:
    case constants.DELETE_COMPETITOR_FAILED:
    case constants.ADD_COMPETITOR_FAILED:
    case constants.UPDATE_COMPETITOR_ALERTS_FAILED:
    case constants.FETCH_FILTER_SEARCH_FAILED:
      return {
        ...state,
        productsErrorMessage: action.payload,
        loading: false,
      };

    case constants.GET_COMPETITOR_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        total_count: action.payload.total_count,
        total_pages: action.payload.total_pages,
        changedProduct: null,
        productsErrorMessage: null,
        productList: action.payload.data,
        pageCount: action.payload.total_pages,
      };
    case constants.FETCH_FILTER_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        // total_count: action.payload.total_count,
        total_pages: action.payload.total_pages,
        changedProduct: null,
        productsErrorMessage: null,
        productList: action.payload.data,
        // pageCount: action.payload.total_pages,
      };
    case constants.GET_COMPETITOR_ALERTS_SUCCESS: //SUCCESS
    case constants.UPDATE_COMPETITOR_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productsErrorMessage: null,
        productAlerts: action.payload.data,
      };
    case constants.DELETE_COMPETITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        productsErrorMessage: null,
        changedProduct: action.payload.data,
        productsSuccessMessage: action.payload.message,
      };
    case constants.ADD_COMPETITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        productAlerts: null,
        productsErrorMessage: null,
        productsSuccessMessage: action.payload.message,
      };

    default:
      return { ...state };
  }
};

export default OtherProducts;
