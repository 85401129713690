const metaArray = [
  {
    path: "/amazon-seller-software-faq",
    schema: ` <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "What is Seller Simple? ",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Seller Simple is the ultimate all-in-one Amazon seller tool that protects and grow your Amazon business."
            }
          }, {
            "@type": "Question",
            "name": "How do Seller Simple works?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Seller Simple monitors your Amazon products and sends you alers for listings changes and critical events such as hijackers, buy-box loss and listing issues. Seller Simple can also track profits for all your Amazon listing products and help you grow your business. You can use Seller Simple to also montinor your competitior products so that are always know when your competitors change price or their BSR ranking improves."
            }
          }, {
            "@type": "Question",
            "name": "Is Seller Simple for free?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Seller Simple is available for free for up to 2 ASINS. For customers with more than 2 ASINS, you can subscribe to a monthly plan for a nominal fee. "
            }
          }, {
            "@type": "Question",
            "name": "How accurate is Seller Simple?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, Seller Simple is very accurate and reliable. With Seller Simple, you do not need to monitor your Amazon listings everyday. Seller Simple does the job of monitoring your Amazon listings 24/7 and sends you an alert when it detects a change. "
            }
          }, {
            "@type": "Question",
            "name": "Why should I sign up for Seller Simple?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text":"If you have been selling on Amazon, you know how tough it is to grow your business. Once you have a successful listing, it is again very challenging to keep that top ranking for your Amazon listing. Your competitors are always trying to get ahead of you. So, you need to be always on top of your Amazon listing. But you can spend 24/7 monitoring your listings. You can use Seller Simple to do the heavy lifting. Seller Simple is available for free for up to 2 Amazon listings.
    
    
    Here's the reason you should never start selling on Amazon without Seller Simple:
    Seller Simple protects and grows your amazon business. It provides you an easy-to-use dashboard where you can see your best-selling products, revenue, profits, important alerts & reviews, research keywords, and spy on competitors."}
              }, {
            "@type": "Question",
            "name": "What are the benefits of Seller Simple?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Customize product alerts You can monitor changes in your Amazon products titles, images, categories, and new sellers and set alert thresholds for pricing, Best Seller Rank, and product ratings. 
    
    Track product review changes Track the success of your product with Amazon product review alerts that help you stay on top of your Amazon metrics. When your overall star rating falls below your custom threshold (to the decimal), be notified and take appropriate actions to get back on track. You'll also know when new, deleted, unavailable reviews, or changes in Buy Box ownership are detected. 
    
    Monitor your competitors listing changes if you are looking for your first product to sell or scaling your business with new products, you will need to follow competitive Amazon products. Get custom alerts to easily track your competitors’ listings and price changes. "
            }
                         }, {
            "@type": "Question",
            "name": "What is Amazon ASIN code?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "ASIN exemplifies Amazon Standard Identification Number. ASINs are unique identifiers for an Amazon listing. Customers can identify each product by its ASIN number. Note: There can be no two items with the same ASIN. "
            }
          }, {
            "@type": "Question",
            "name": "How can I use Seller Simple to keep an eye on my competitors?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Here is how Seller Simple can help you keep track of your competitors:
    Find your competitor’s ASIN
    In Amazon.com, search for the key word most closely associated with your product listing. For example, if you sell “cell phone case”, search for “cell phone case” in Amazon.com. 
    
    Look for the top 10 listings for the search term in the previous step and note down their ASINs. These are your competitors.
    
    Use Seller Simple to monitor your competitor’s ASIN
    Sign-in to your Seller Simple account. If you have not signed up for Seller Simple, do can do it for free.
    
    Go to “Competitors” page from the Left Navigation pane.
    
    Add your Competitors ASINs to start monitoring your competitors. You can monitor their BSR ranking, any price changes or any changes to your competitor’s product listing. "
            }
            }, {
            "@type": "Question",
            "name": "How do I monitor my profits for my Amazon listings?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Many Amazon sellers struggle with this basic question: how much money am I making on Amazon. Seller Simple can help you with this. Seller Simple can aggregate the sales of all your Amazon products and provide you gross profits for any given time period. 
    
    For more accurate profits, you can provide the cost of goods sold (cogs), shipping charges, Amazon listing charges and other fees for all of your Amazon product listing. This is one time thing that you need to do for each of your product listing.  "
            }
              }, {
            "@type": "Question",
            "name": "What should I do if I'm losing business to my competitors?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The simple answer is to become more visible than your competitors - Easier said than done, though.
    Let’s see how you funnel more sales into your pipeline. 
    1. Don’t freak out: No business is perfect, so you should never freak out.
    2. Improve your product listing: For example - use enough relevant keywords, and high-quality pictures & pinpoint the product description and bullet points.
    3. Advertise and promote outside of Amazon: For example - Set up an Instagram account devoted to your product, use Google or Facebook ads, and write a blog about it.
    4. Wholesale your products: If nothing seems to work to put your product on Amazon's shelves, then it may be time to sell wholesale to another seller. "
            }
                }, {
            "@type": "Question",
            "name": "What are the factors affecting your product relevancy?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Relevancy factors
    Title - A good product title increases conversion rates.
    Features/bullet points - Displaying product features in detailed bullet points (like below) with keywords is a great way to increase conversions.
    Product Description - Extend the features with product descriptions and insert keywords.
    Brand and manufacturer part number - The title always includes the brand when appropriate. This allows buyers to search for brand names to find products.
    Specifications - This section shows that Amazon is committed to your listing.
    Category and sub-category - When setting your product listing, choose the most relevant category for your product.
    Search terms - Amazon provides five character-limited fields for specifying search terms to associate with the product. "
            }
                  }, {
            "@type": "Question",
            "name": "How are ranking determined on Amazon?
    ",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Amazon Bestseller Rankings are calculated based on product sales and are updated hourly. The ranking also considers the product's current sales and the history of sales. A product ranked #1 means that the store has sold more recently than any other product in that category. "
            }
           }, {
            "@type": "Question",
            "name": "Which products are best to sell on Amazon?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The most product categories: Home & Kitchen, Clothing, Shoes & Jewelry, Electronics, Beauty & Personal Care. "
            }
           }, {
            "@type": "Question",
            "name": "What can I not sell on Amazon?
    ",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "List of Products You Cannot Sell on Amazon: Alcohol, Animals & Animal Products, Automotive and Powersports, Composite Wood Products, Drugs & drug paraphernalia, Explosives, Weapons, Export Controls and Related Items. "
            }
           }, {
            "@type": "Question",
            "name": "What products will be in demand in 2022?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "List of in-demand in 2022; Shapewear, Travel accessories, Healthy and beauty products, Smartwatches, Health Care, Skin Care, Hobbies and Craft, Lamps and Shades, Mobile Accessories, Pet products, Fine jewelry, Sleepwear "
            }
             }, {
            "@type": "Question",
            "name": "How to get buy box?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A buy box is a box on a product detail page where a customer adds products to their cart and initiates the checkout process.
    An important feature of the Amazon platform is the ability for the same product to be sold by multiple sellers. If multiple sellers are listing the same item, they may be able to compete for their shopping cart box for that item.
    A seller's eligibility to earn a Shopping Cart Box is tied to specific seller performance criteria that identify sellers who have consistently provided their customers with a superior shopping experience on the Amazon.in site. "
            }
               }, {
            "@type": "Question",
            "name": "How to follow amazon's ASIN guidelines",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "There are several ways to find your product's Amazon ASIN.
    The first step is to search for your chosen product on Amazon and confirm it in your browser's address bar. Enter the product name and view image below the URL to display the Amazon ASIN.
    Or you need to search for the product on Amazon and later navigate to the product detail page.
    Finally, you should use a third-party software tool designed for ASIN lookups. Tools other than bulk upload can help you find the products you need and match them to ASINs. Using a tool can save you time as it can be hectic to find your product among a multitude of products. "
            }
               
                 
              
            }]
        }
        </script>
        <script type="application/ld+json"> {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://www.sellersimple.com/"
            },{
              "@type": "ListItem",
              "position": 2,
              "name": "Pricing",
              "item": "https://www.sellersimple.com/amazon-seller-software-pricing"
            },{
              "@type": "ListItem",
              "position": 3,
              "name": "FAQ",
              "item": "https://www.sellersimple.com/amazon-seller-software-faq"
           },{
              "@type": "ListItem",
              "position": 4,
              "name": "Contact US",
              "item": "https://www.sellersimple.com/contactus"
           },{
              "@type": "ListItem",
              "position": 5,
              "name": "Blog",
              "item": "https://www.sellersimple.com/blog"
            }]
          }</script>
      `,
    image: "/sellerThumbnail.jpg",
    metaTitle: "Sell on Amazon | Frequently Asked Questions | Seller Simple",
    metaDescription:
      "Seller Simple | Frequently Asked Questions | Get everything you need to know to start selling on Amazon",
  },
  {
    path: "/amazon-seller-software-pricing",
    schema: `<script type="application/ld+json">{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.sellersimple.com/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Pricing",
          "item": "https://www.sellersimple.com/amazon-seller-software-pricing"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "FAQ",
          "item": "https://www.sellersimple.com/amazon-seller-software-faq"
       },{
          "@type": "ListItem",
          "position": 4,
          "name": "Contact US",
          "item": "https://www.sellersimple.com/contactus"
       },{
          "@type": "ListItem",
          "position": 5,
          "name": "Blog",
          "item": "https://www.sellersimple.com/blog"
        }]
      }</script>`,
    image: "/sellerThumbnail.jpg",
    metaTitle: "Best Amazon Seller Tools | Seller Simple Plans and Pricing",
    metaDescription:
      "Selling on Amazon has been easier with Seller Simple, the pricing is designed to suit different budgets. Hurry up to protect and grow your Amazon business",
  },
  {
    path: "/blog",
    schema: `<script type="application/ld+json">{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.sellersimple.com/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Pricing",
          "item": "https://www.sellersimple.com/amazon-seller-software-pricing"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "FAQ",
          "item": "https://www.sellersimple.com/amazon-seller-software-faq"
       },{
          "@type": "ListItem",
          "position": 4,
          "name": "Contact US",
          "item": "https://www.sellersimple.com/contactus"
       },{
          "@type": "ListItem",
          "position": 5,
          "name": "Blog",
          "item": "https://www.sellersimple.com/blog"
        }]
      }</script>`,
    image: "/sellerThumbnail.jpg",
    metaTitle:
      "Seller Simple Blog | Get latest Amazon seller news and updates here",
    metaDescription:
      "Tips and News for Amazon Sellers | From newbies to seasoned pros, Amazon sellers get the latest news and tips here.",
  },
  {
    path: "/contactus",
    schema: `<script type="application/ld+json">{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.sellersimple.com/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Pricing",
          "item": "https://www.sellersimple.com/amazon-seller-software-pricing"
        },{
          "@type": "ListItem",
          "position": 3,
          "name": "FAQ",
          "item": "https://www.sellersimple.com/amazon-seller-software-faq"
       },{
          "@type": "ListItem",
          "position": 4,
          "name": "Contact US",
          "item": "https://www.sellersimple.com/contactus"
       },{
          "@type": "ListItem",
          "position": 5,
          "name": "Blog",
          "item": "https://www.sellersimple.com/blog"
        }]
      }</script>`,
    image: "/sellerThumbnail.jpg",
    metaTitle: "Have a question for the Seller Simple team | Connect Us",
    metaDescription:
      "With Seller Simple, you have all the resources needed to grow and protect a successful Amazon business.",
  },
];
module.exports.getMetaData = (path) =>
  metaArray.find((item) => item.path === path);
