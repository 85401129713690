import { constants } from "./constants";


export const resetReviewMessages = () => ({
    type: constants.RESET_REVIEW_MESSAGE
});

export const getReviewList = (params) => ({
    type: constants.GET_REVIEW_LIST_REQUEST,
    params
});

export const getReviewListSuccess = (products) => ({
    type: constants.GET_REVIEW_LIST_SUCCESS,
    payload: products
});

export const getReviewListFailed = error => ({
    type: constants.GET_REVIEW_LIST_FAILED,
    payload: error
});