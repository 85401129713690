import { constants } from "./constants";

export const categorySpying = (params) => ({
  type: constants.GET_LISTING_CATEGORYSPY_REQUEST,
  params,
});

export const getCategorySpySuccess = (report) => ({
  type: constants.GET_LISTING_CATEGORYSPY_SUCCESS,
  payload: report,
});

export const getCategorySpyFailed = (error) => ({
  type: constants.GET_LISTING_CATEGORYSPY_FAILED,
  payload: error,
});

export const deleteCategorySpy = (params) => ({
  type: constants.DELETE_CATEGORY_SPY_REQUEST,
  params,
});

export const deleteCategorySpySuccess = (report) => ({
  type: constants.DELETE_CATEGORY_SPY_SUCCESS,
  payload: report,
});

export const deleteCategorySpyFailed = (error) => ({
  type: constants.DELETE_CATEGORY_SPY_FAILED,
  payload: error,
});

export const fetchMainCategory = (params) => ({
  type: constants.FETCH_MAIN_CATEGORY_REQUEST,
  params,
});

export const fetchMainCategorySuccess = (report) => ({
  type: constants.FETCH_MAIN_CATEGORY_SUCCESS,
  payload: report,
});

export const fetchMainCategoryFailed = (error) => ({
  type: constants.FETCH_MAIN_CATEGORY_FAILED,
  payload: error,
});

export const fetchSubCategory = (params) => ({
  type: constants.FETCH_SUB_CATEGORY_REQUEST,
  params,
});

export const fetchSubCategorySuccess = (report) => ({
  type: constants.FETCH_SUB_CATEGORY_SUCCESS,
  payload: report,
});

export const fetchSubCategoryFailed = (error) => ({
  type: constants.FETCH_SUB_CATEGORY_FAILED,
  payload: error,
});

export const fetchCategoryTableData = (params) => ({
  type: constants.FETCH_CATEGORY_DATA_REQUEST,
  params,
});

export const fetchCategoryTableDataSuccess = (report) => ({
  type: constants.FETCH_CATEGORY_DATA_SUCCESS,
  payload: report,
});

export const fetchCategoryTableDataFailed = (error) => ({
  type: constants.FETCH_CATEGORY_DATA_FAILED,
  payload: error,
});

export const addSubCategory = (params) => ({
  type: constants.ADD_SUB_CATEGORY_ID_REQUEST,
  params,
});

export const addSubCategorySuccess = (report) => ({
  type: constants.ADD_SUB_CATEGORY_ID_SUCCESS,
  payload: report,
});

export const addSubCategoryFailed = (error) => ({
  type: constants.ADD_SUB_CATEGORY_ID_FAILED,
  payload: error,
});
export const addCategoryId = (params) => ({
  type: constants.ADD_CATEGORY_ID_REQUEST,
  params,
});

export const addCategoryIdSuccess = (report) => ({
  type: constants.ADD_CATEGORY_ID_SUCCESS,
  payload: report,
});

export const addCategoryIdFailed = (error) => ({
  type: constants.ADD_CATEGORY_ID_FAILED,
  payload: error,
});

export const clearCategoryList = (data) => ({
  type: constants.CLEAR_CATEGORY_LIST,
  payload: data,
});