import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import {
  listFeedbackSuccess,
  listFeedbackFailed,
  feedbackStatusSuccess,
  feedbackStatusFailed,
} from "./action";
import { apiCall, getProtectedOptions } from "../../../helpers/api";

function* getFeedbackLists({ data }) {
  let options = getProtectedOptions(endpoints.getFeedback, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(listFeedbackSuccess(response.data.data));
    } else {
      yield put(listFeedbackFailed(response.data.message));
    }
  } catch (error) {
    yield put(listFeedbackFailed(error || "Error not found!"));
  }
}

function* changeFeedbackStatus({ data }) {
  let options = getProtectedOptions(endpoints.changeFeedback, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(feedbackStatusSuccess(response.data.message));
    } else {
      yield put(feedbackStatusFailed(response.data.message));
    }
  } catch (error) {
    yield put(feedbackStatusFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetFeedbackLists() {
  yield takeLatest(constants.LIST_FEEDBACK_REQUEST, getFeedbackLists);
}

export function* watchChangeFeedbackStatus() {
  yield takeLatest(constants.FEEDBACK_STATUS_REQUEST, changeFeedbackStatus);
}

function* feedbackSaga() {
  yield all([watchGetFeedbackLists(), watchChangeFeedbackStatus()]);
}

export default feedbackSaga;
