import React from "react";

const LoaderSpinner = () => {
    return (
        <div
            style={{
                position: "fixed",
                zIndex: 2000,
                backgroundColor: "rgba(255,255,255,.7)",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                left: 0,
                top: 0
            }}
        >
            <div className="spinner-border spn-styl" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default LoaderSpinner;
