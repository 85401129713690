import { constants } from "./constants";

const INIT_STATE = {
  userData: {},
  authUrl: "",
  isRead: false,
  loading:false,
  newUser: false,
  adminUserData: {},
  sellerAccount: {},
  notificationList: [],
  authErrorMessage: [],
  authSuccessMessage: [],
  authorizationComplete:null,
  monitoring_new_alert: "",
  adminNotificationList: [],
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_AUTH_MESSAGE: //RESET MSGS
      return {
        ...state,
        authSuccessMessage: null,
        authErrorMessage: null,
      };

    case constants.LOGIN_USER_REQUEST: // REQUEST
    case constants.GET_AUTH_URL_REQUEST:
    case constants.REGISTRATION_REQUEST:
    case constants.LOGIN_ADMIN_USER_REQUEST:
    case constants.LOGOUT_ADMIN_USER_REQUEST:
    case constants.ADD_SELLER_ACCOUNT_REQUEST:
    case constants.LOGOUT_SELLER_USER_REQUEST:
    case constants.SENT_PASS_RESET_LINK_REQUEST:
    case constants.ADMIN_PASS_RESET_REQUEST:
      return { ...state, loading: true, newUser:false };

    case constants.SELLER_LOG_HISTORY_REQUEST:
      return { ...state };

    case constants.GET_USER_NOTIFICATIONS_REQUEST:
    case constants.FETCH_USER_NOTIFICATIONS_REQUEST:
    case constants.GET_ADMIN_NOTIFICATIONS_REQUEST:
    case constants.CLEAR_ADMIN_NOTIFICATIONS_REQUEST:
    case constants.CHANGE_ADMIN_NOTIFICATIONS_REQUEST:
    case constants.CHANGE_USER_NOTIFICATIONS_REQUEST:
      return { ...state, isRead: false };

    case constants.GET_AUTH_URL_FAILED: //FAILED
    case constants.REGISTRATION_FAILED:
    case constants.LOGIN_ADMIN_USER_FAILED:
    case constants.LOGOUT_ADMIN_USER_FAILED:
    case constants.ADMIN_PASS_RESET_FAILED:
    case constants.ADD_SELLER_ACCOUNT_FAILED:
    case constants.LOGOUT_SELLER_USER_FAILED:
    case constants.SENT_PASS_RESET_LINK_FAILED:
    case constants.GET_USER_NOTIFICATIONS_FAILED:
    case constants.FETCH_USER_NOTIFICATIONS_FAILED:
    case constants.GET_ADMIN_NOTIFICATIONS_FAILED:
    case constants.CLEAR_ADMIN_NOTIFICATIONS_FAILED:
    case constants.CHANGE_ADMIN_NOTIFICATIONS_FAILED:
    case constants.CHANGE_USER_NOTIFICATIONS_FAILED:
      return {
        ...state,
        authErrorMessage: action.payload,
        loading: false,
      };

    case constants.LOGIN_USER_FAILED:
      return {
        ...state,
        newUser: action?.payload?.is_user_exist ? false : true,
      };

    case constants.LOGIN_USER_SUCCESS: //SUCCESS
      return {
        ...state,
        newUser: false,
        loading: false,
        authErrorMessage: null,
        userData: action.payload,
      };
    case constants.SENT_PASS_RESET_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        authSuccessMessage: action.payload,
      };
    case constants.ADMIN_PASS_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        authSuccessMessage: action.payload,
      };
    case constants.REGISTRATION_SUCCESS:
      return {
        ...state,
        newUser: false,
        loading: false,
        authErrorMessage: null,
        userData: action.payload,
      };
    case constants.LOGOUT_ADMIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
      };
    case constants.LOGOUT_SELLER_USER_SUCCESS:
      return {
        ...INIT_STATE,
      };
    case constants.LOGIN_ADMIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        adminUserData: action.payload,
      };
    case constants.GET_AUTH_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        authUrl: action.payload.auth_url,
        authSuccessMessage: action.payload.message,
      };
    case constants.ADD_SELLER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        sellerAccount: action.payload,
        authSuccessMessage: action.payload.message,
      };
    case constants.GET_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        notificationList: action.payload.data,
        monitoring_new_alert: action.payload.new_alert_count,
        isRead: false,
      };

    case constants.FETCH_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        notificationList: action.payload.data,
        monitoring_new_alert: action.payload.new_alert_count,
        isRead: false,
      };

    case constants.GET_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        adminNotificationList: action.payload.data,
        isRead: false,
      };

    case constants.CHANGE_ADMIN_NOTIFICATIONS_SUCCESS:
      //case constants.CLEAR_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        authErrorMessage: null,
        isRead: true,
        authSuccessMessage: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Auth;
