export const constants = {
  RESET_CUSTOMER_MESSAGE: "RESET_CUSTOMER_MESSAGE",

  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILED: "GET_CUSTOMERS_FAILED",

  SEND_SELLER_MAIL: "SEND_SELLER_MAIL",
  SEND_SELLER_MAIL_SUCCESS: "SEND_SELLER_MAIL_SUCCESS",
  SEND_SELLER_MAIL_FAILED: "SEND_SELLER_MAIL_FAILED",

  GENERATE_TASK: "GENERATE_TASK",
  GENERATE_TASK_SUCCESS: "GENERATE_TASK_SUCCESS",
  GENERATE_TASK_FAILED: "GENERATE_TASK_FAILED",
};
