import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
  setSession,
  setUserStatus,
  clearSessionData,
  setAdminSession,
  getLoggedInUser,
} from "../../helpers/authUtils";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../helpers/api";
import {
  loginUserSuccess,
  loginUserFailed,
  getAuthUrlSuccess,
  getAuthUrlFailed,
  addSellerAccountSuccess,
  addSellerAccountFailed,
  loginAdminUserSuccess,
  loginAdminUserFailed,
  getUserNotificationsSuccess,
  getUserNotificationsFailed,
  fetchUserNotificationsSuccess,
  fetchUserNotificationsFailed,
  loginOutAdminSuccess,
  loginOutAdminFailed,
  registerUserSuccess,
  registerUserFailed,
  loginOutSellerSuccess,
  loginOutSellerFailed,
  getAdminNotificationsSuccess,
  getAdminNotificationsFailed,
  changeAdminNotificationsSuccess,
  changeAdminNotificationsFailed,
  sentPassResetLinkSuccess,
  sentPassResetLinkFailed,
  adminPassResetLinkSuccess,
  adminPassResetLinkFailed,
  sellerLogHistorySuccess,
  sellerLogHistoryFailed,
} from "./actions";

function* login({ loginData }) {
  let options = getUnProtectedOptions(endpoints.login, "POST", loginData);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      setSession(response);
      setUserStatus(response.data.data);
      yield put(loginUserSuccess(response.data.data));
    } else {
      yield put(loginUserFailed(response.data));
    }
  } catch (error) {
    yield put(loginUserFailed(error || "Error not found!"));
  }
}

function* registerUser({ registerData }) {
  let options = getUnProtectedOptions(endpoints.register, "POST", registerData);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      setSession(response);
      setUserStatus(response);
      yield put(registerUserSuccess(response.data.data));
    } else {
      yield put(
        registerUserFailed(response?.data?.message || "Error with credentials")
      );
    }
  } catch (error) {
    yield put(registerUserFailed(error || "Error not found!"));
  }
}

function* logoutSellerUser() {
  const loggedUser = getLoggedInUser();
  let options = getUnProtectedOptions(endpoints.sellerLogout, "POST", {
    refresh_token: loggedUser?.user.refreshToken,
  });
  try {
    const response = yield call(apiCall, options);
    clearSessionData();
    if (response.status === 200 && response.data.status) {
      yield put(loginOutSellerSuccess(response.data.data));
    } else {
      yield put(
        loginOutSellerFailed(response.data.message || response.data.detail)
      );
    }
  } catch (error) {
    yield put(loginOutSellerFailed(error || "Error not found!"));
  }
}

function* logoutAdminUser() {
  const loggedUser = getLoggedInUser();
  let options = getProtectedOptions(endpoints.adminLogout, "POST", {
    refresh_token: loggedUser?.user?.refreshToken,
  });
  try {
    const response = yield call(apiCall, options);
    clearSessionData();
    if (response.status === 200 && response.data.status) {
      yield put(loginOutAdminSuccess(response.data.data));
    } else {
      yield put(
        loginOutAdminFailed(response.data.message || response.data.detail)
      );
    }
  } catch (error) {
    yield put(loginOutAdminFailed(error || "Error not found!"));
  }
}

function* getAuthUrlData({ region }) {
  let options = getUnProtectedOptions(endpoints.getAuthUrl, "POST", region);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getAuthUrlSuccess(response.data));
    } else {
      yield put(getAuthUrlFailed(response.data.message));
    }
  } catch (error) {
    yield put(getAuthUrlFailed(error || "Error not found!"));
  }
}

function* addSellerAccount({ sellerAccountData }) {
  let options = getProtectedOptions(
    endpoints.addSellerUrl,
    "POST",
    sellerAccountData
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addSellerAccountSuccess(response.data));
    } else {
      yield put(addSellerAccountFailed(response.data.message));
    }
  } catch (error) {
    yield put(addSellerAccountFailed(error || "Error not found!"));
  }
}

function* loginAdminUser({ loginData }) {
  let options = getUnProtectedOptions(
    endpoints.loginAdminUser,
    "POST",
    loginData
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 500) {
      window.location.href = "/admin/?servererror";
    }
    if (response.status === 200 && response.data.status) {
      setAdminSession(response);
      yield put(loginAdminUserSuccess(response.data));
    } else {
      yield put(
        loginAdminUserFailed(
          response.data.message || response.data.non_field_errors[0]
        )
      );
    }
  } catch (error) {
    yield put(loginAdminUserFailed(error || "Error not found!"));
  }
}

function* getUserNotification({ params }) {
  let options = getProtectedOptions(
    endpoints.getUserNotification,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getUserNotificationsSuccess(response.data));
    } else {
      yield put(getUserNotificationsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getUserNotificationsFailed(error || "Error not found!"));
  }
}

function* fetchUserNotification({ params }) {
  let options = getProtectedOptions(
    endpoints.fetchUserNotification,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchUserNotificationsSuccess(response.data));
    } else {
      yield put(fetchUserNotificationsFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchUserNotificationsFailed(error || "Error not found!"));
  }
}

function* getAdminNotification({ params }) {
  let options = getProtectedOptions(endpoints.listNotification, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getAdminNotificationsSuccess(response.data));
    } else {
      yield put(getAdminNotificationsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getAdminNotificationsFailed(error || "Error not found!"));
  }
}

function* changeAdminNotification({ params }) {
  let options = getProtectedOptions(
    endpoints.changeAdminNotification,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(changeAdminNotificationsSuccess(response.data.message));
    } else {
      yield put(changeAdminNotificationsFailed(response.data.message));
    }
  } catch (error) {
    yield put(changeAdminNotificationsFailed(error || "Error not found!"));
  }
}

function* sentResetPassLink({ params }) {
  let options = getUnProtectedOptions(
    endpoints.sentPassResetLink,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sentPassResetLinkSuccess(response.data.message));
    } else {
      yield put(
        sentPassResetLinkFailed(
          response.data.message || response.data.email[0] || "Error Found"
        )
      );
    }
  } catch (error) {
    yield put(sentPassResetLinkFailed(error || "Error not found!"));
  }
}

function* adminResetPassLink({ params }) {
  let options = getUnProtectedOptions(endpoints.adminPassReset, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(adminPassResetLinkSuccess(response.data.message));
    } else {
      yield put(
        adminPassResetLinkFailed(
          response.data.message || response.data.email[0] || "Error Found"
        )
      );
    }
  } catch (error) {
    yield put(adminPassResetLinkFailed(error || "Error not found!"));
  }
}

function* sellerLogHistory({ params }) {
  let options = getProtectedOptions(endpoints.sellerLogHistory, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sellerLogHistorySuccess(response.data.message));
    } else {
      yield put(
        sellerLogHistoryFailed(
          response.data.message || response.data.email[0] || "Error Found"
        )
      );
    }
  } catch (error) {
    yield put(adminPassResetLinkFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchLoginUser() {
  yield takeLatest(constants.LOGIN_USER_REQUEST, login);
}

export function* watchRegisterUser() {
  yield takeLatest(constants.REGISTRATION_REQUEST, registerUser);
}

export function* watchLogoutSellerUser() {
  yield takeLatest(constants.LOGOUT_SELLER_USER_REQUEST, logoutSellerUser);
}

export function* watchLogoutAdminUser() {
  yield takeLatest(constants.LOGOUT_ADMIN_USER_REQUEST, logoutAdminUser);
}

export function* watchGetAuthUrl() {
  yield takeLatest(constants.GET_AUTH_URL_REQUEST, getAuthUrlData);
}

export function* watchAddSellerAccount() {
  yield takeLatest(constants.ADD_SELLER_ACCOUNT_REQUEST, addSellerAccount);
}

export function* watchLoginAdminUser() {
  yield takeLatest(constants.LOGIN_ADMIN_USER_REQUEST, loginAdminUser);
}

export function* watchGetUserNotification() {
  yield takeLatest(
    constants.GET_USER_NOTIFICATIONS_REQUEST,
    getUserNotification
  );
}

export function* watchFetchUserNotification() {
  yield takeLatest(
    constants.FETCH_USER_NOTIFICATIONS_REQUEST,
    fetchUserNotification
  );
}

export function* watchGetAdminNotification() {
  yield takeLatest(
    constants.GET_ADMIN_NOTIFICATIONS_REQUEST,
    getAdminNotification
  );
}

export function* watchChangeAdminNotification() {
  yield takeLatest(
    constants.CHANGE_ADMIN_NOTIFICATIONS_REQUEST,
    changeAdminNotification
  );
}

export function* watchSentResetPassLink() {
  yield takeLatest(constants.SENT_PASS_RESET_LINK_REQUEST, sentResetPassLink);
}

export function* watchAdminResetPass() {
  yield takeLatest(constants.ADMIN_PASS_RESET_REQUEST, adminResetPassLink);
}

export function* watchSellerLogHistory() {
  yield takeLatest(constants.SELLER_LOG_HISTORY_REQUEST, sellerLogHistory);
}

function* authSaga() {
  yield all([
    watchLoginUser(),
    watchGetAuthUrl(),
    watchRegisterUser(),
    watchAdminResetPass(),
    watchLoginAdminUser(),
    watchLogoutAdminUser(),
    watchLogoutSellerUser(),
    watchAddSellerAccount(),
    watchSellerLogHistory(),
    watchSentResetPassLink(),
    watchGetUserNotification(),
    watchFetchUserNotification(),
    watchGetAdminNotification(),
    watchChangeAdminNotification(),
  ]);
}

export default authSaga;
