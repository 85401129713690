import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import "./assets/css/bootstrap.css";
import "./assets/css/common.css";
import "./assets/css/custom.css";
import { configureStore } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { stopReportingRuntimeErrors } from "react-error-overlay";

const store = configureStore();
// if (
//   window.location.origin === "https://staging.sellersimple.com" ||
//   window.location.origin === "https://www.sellersimple.com" ||
//   window.location.origin === "https://sellersimple.com"
// ) {
//   stopReportingRuntimeErrors(); // disables error overlays
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
