import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { getLoggedInUser } from "../../../helpers/authUtils";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  getAdminDetailsFailed,
  getAdminDetailsSuccess,
  logoUploadFailed,
  logoUploadSuccess,
  uploadImageFailed,
  uploadImageSuccess,
  editProfileSuccess,
  editProfileFailed,
  resetPasswordSuccess,
  resetPasswordFailed,
} from "./action";

function* getAdminDetailsList({ data }) {
  let options = getProtectedOptions(endpoints.getAdminDetails, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getAdminDetailsSuccess(response.data));
    } else {
      yield put(getAdminDetailsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getAdminDetailsFailed(error || "Error not found!"));
  }
}

function* uploadAdminImage({ data }) {
  let options = getProtectedOptions(
    endpoints.imageUpload,
    "POST",
    data,
    "file"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(uploadImageSuccess(response.data.message));
    } else {
      yield put(
        uploadImageFailed(response.data.message || response.data.detail)
      );
    }
  } catch (error) {
    yield put(uploadImageFailed(error || "Image upload failed!"));
  }
}

function* uploadAdminLogo({ data }) {
  let options = getProtectedOptions(endpoints.logoUpload, "POST", data, "file");
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(logoUploadSuccess(response.data.message));
    } else {
      yield put(
        logoUploadFailed(response.data.message || response.data.detail)
      );
    }
  } catch (error) {
    yield put(logoUploadFailed(error || "Logo upload failed!"));
  }
}

function* resetAdminPassword({ data }) {
  const loggedUser = getLoggedInUser();
  let options = getProtectedOptions(
    `${endpoints.hostUrl}/seller_admin/change_password/${loggedUser?.user?.id}/`,
    "POST",
    data
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(resetPasswordSuccess(response.data.message));
    } else {
      yield put(
        resetPasswordFailed(response.data.message || response.data.detail)
      );
    }
  } catch (error) {
    yield put(resetPasswordFailed(error || "Password Reset failed!"));
  }
}

function* editAdminProfile({ data }) {
  let options = getProtectedOptions(
    endpoints.updateAdminSettings,
    "POST",
    data
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editProfileSuccess(response.data.message));
    } else {
      yield put(editProfileFailed(response.data.message));
    }
  } catch (error) {
    yield put(editProfileFailed(error || "Profile Update failed!"));
  }
}

//Watcher
export function* watchGetAdminDetails() {
  yield takeLatest(constants.GET_ADMIN_DETAILS_REQUEST, getAdminDetailsList);
}

export function* watchUploadAdminImage() {
  yield takeLatest(constants.UPLOAD_IMAGE, uploadAdminImage);
}

export function* watchUploadAdminLogo() {
  yield takeLatest(constants.UPLOAD_LOGO, uploadAdminLogo);
}

export function* watchResetAdminPassword() {
  yield takeLatest(constants.RESET_PASSWORD, resetAdminPassword);
}

export function* watchEditAdminProfile() {
  yield takeLatest(constants.EDIT_PROFILE, editAdminProfile);
}

function* adminSettingsSaga() {
  yield all([
    watchGetAdminDetails(),
    watchUploadAdminImage(),
    watchUploadAdminLogo(),
    watchResetAdminPassword(),
    watchEditAdminProfile(),
  ]);
}

export default adminSettingsSaga;
