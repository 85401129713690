import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  getUserReportFailed,
  getUserReportSuccess,
} from "./action";

function* getUserReportList({ data }) {
  let options = getProtectedOptions(endpoints.getUserReport, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getUserReportSuccess(response.data));
    } else {
      yield put(getUserReportFailed(response.data.message));
    }
  } catch (error) {
    yield put(getUserReportFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetUserReport() {
  yield takeLatest(constants.GET_USER_REPORT_REQUEST, getUserReportList);
}


function* UserReportsaga() {
  yield all([
    watchGetUserReport(),
  ]);
}

export default UserReportsaga;
