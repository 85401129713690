export const constants = {
  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILED: "LOGIN_USER_FAILED",

  REGISTRATION_REQUEST: "REGISTRATION_REQUEST",
  REGISTRATION_SUCCESS: "REGISTRATION_SUCCESS",
  REGISTRATION_FAILED: "REGISTRATION_FAILED",

  RESET_AUTH_MESSAGE: "RESET_AUTH_MESSAGE",

  GET_AUTH_URL_REQUEST: "GET_AUTH_URL_REQUEST",
  GET_AUTH_URL_SUCCESS: "GET_AUTH_URL_SUCCESS",
  GET_AUTH_URL_FAILED: "GET_AUTH_URL_FAILED",

  ADD_SELLER_ACCOUNT_REQUEST: "ADD_SELLER_ACCOUNT_REQUEST",
  ADD_SELLER_ACCOUNT_SUCCESS: "ADD_SELLER_ACCOUNT_SUCCESS",
  ADD_SELLER_ACCOUNT_FAILED: "ADD_SELLER_ACCOUNT_FAILED",

  LOGIN_ADMIN_USER_REQUEST: "LOGIN_ADMIN_USER_REQUEST",
  LOGIN_ADMIN_USER_SUCCESS: "LOGIN_ADMIN_USER_SUCCESS",
  LOGIN_ADMIN_USER_FAILED: "LOGIN_ADMIN_USER_FAILED",

  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILED: "GET_CUSTOMERS_FAILED",

  GET_USER_NOTIFICATIONS_REQUEST: "GET_USER_NOTIFICATIONS_REQUEST",
  GET_USER_NOTIFICATIONS_SUCCESS: "GET_USER_NOTIFICATIONS_SUCCESS",
  GET_USER_NOTIFICATIONS_FAILED: "GET_USER_NOTIFICATIONS_FAILED",

  FETCH_USER_NOTIFICATIONS_REQUEST: "FETCH_USER_NOTIFICATIONS_REQUEST",
  FETCH_USER_NOTIFICATIONS_SUCCESS: "FETCH_USER_NOTIFICATIONS_SUCCESS",
  FETCH_USER_NOTIFICATIONS_FAILED: "FETCH_USER_NOTIFICATIONS_FAILED",

  GET_ADMIN_NOTIFICATIONS_REQUEST: "GET_ADMIN_NOTIFICATIONS_REQUEST",
  GET_ADMIN_NOTIFICATIONS_SUCCESS: "GET_ADMIN_NOTIFICATIONS_SUCCESS",
  GET_ADMIN_NOTIFICATIONS_FAILED: "GET_ADMIN_NOTIFICATIONS_FAILED",

  CHANGE_ADMIN_NOTIFICATIONS_REQUEST: "CHANGE_ADMIN_NOTIFICATIONS_REQUEST",
  CHANGE_ADMIN_NOTIFICATIONS_SUCCESS: "CHANGE_ADMIN_NOTIFICATIONS_SUCCESS",
  CHANGE_ADMIN_NOTIFICATIONS_FAILED: "CHANGE_ADMIN_NOTIFICATIONS_FAILED",

  CLEAR_ADMIN_NOTIFICATIONS_REQUEST: "CLEAR_ADMIN_NOTIFICATIONS_REQUEST",
  CLEAR_ADMIN_NOTIFICATIONS_SUCCESS: "CLEAR_ADMIN_NOTIFICATIONS_SUCCESS",
  CLEAR_ADMIN_NOTIFICATIONS_FAILED: "CLEAR_ADMIN_NOTIFICATIONS_FAILED",

  LOGOUT_ADMIN_USER_REQUEST: "LOGOUT_ADMIN_USER_REQUEST",
  LOGOUT_ADMIN_USER_SUCCESS: "LOGOUT_ADMIN_USER_SUCCESS",
  LOGOUT_ADMIN_USER_FAILED: "LOGOUT_ADMIN_USER_FAILED",

  LOGOUT_SELLER_USER_REQUEST: "LOGOUT_SELLER_USER_REQUEST",
  LOGOUT_SELLER_USER_SUCCESS: "LOGOUT_SELLER_USER_SUCCESS",
  LOGOUT_SELLER_USER_FAILED: "LOGOUT_SELLER_USER_FAILED",

  SENT_PASS_RESET_LINK_REQUEST: "SENT_PASS_RESET_LINK_REQUEST",
  SENT_PASS_RESET_LINK_SUCCESS: "SENT_PASS_RESET_LINK_SUCCESS",
  SENT_PASS_RESET_LINK_FAILED: "SENT_PASS_RESET_LINK_FAILED",

  ADMIN_PASS_RESET_REQUEST: "ADMIN_PASS_RESET_REQUEST",
  ADMIN_PASS_RESET_SUCCESS: "ADMIN_PASS_RESET_SUCCESS",
  ADMIN_PASS_RESET_FAILED: "ADMIN_PASS_RESET_FAILED",

  SELLER_LOG_HISTORY_REQUEST: "SELLER_LOG_HISTORY_REQUEST",
  SELLER_LOG_HISTORY_SUCCESS: "SELLER_LOG_HISTORY_SUCCESS",
  SELLER_LOG_HISTORY_FAILED: "SELLER_LOG_HISTORY_FAILED",
};
