import { toast } from "react-toastify";
import { constants } from "./constants";

const INIT_STATE = {
  voiceDes: [],
  recall: false,
  videoData: [],
  videoUrl: null,
  loading: false,
  editStatus: "",
  pageCount: null,
  voiceData: null,
  totalCount: null,
  planDetails: null,
  videoTableData: [],
  paymentStatus: false,
  scriptLoading: false,
  deleteSuccess: false,
  paymentDetails:false,
  isLimitReached: false,
  paymentLoading: false,
  isLinkGenerated: false,
  videoDataErrorMessage: null,
  videoDataSuccessMessage: null,
};

const Video = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.GET_VIDEO_REQUEST:
      return { ...state, isLinkGenerated: false, loading: true ,paymentLoading: false }; // REQUEST
    case constants.GET_VOICE_REQUEST:
      return {
        ...state,
        recall: true,
        editStatus: "",
        voiceData: null,
        paymentStatus:false,
        scriptLoading: true,
        isLimitReached: false,
        paymentLoading: false,
        isLinkGenerated: false,
      };
    case constants.EDIT_VOICE_REQUEST:
    case constants.FETCH_VIDEO_REQUEST:
      return {
        ...state,
        recall: false,
        loading: true,
        editStatus: "",
        isLinkGenerated: false,
        voiceData: null,
      };
    case constants.FETCH_ALL_VIDEO_REQUEST:
      return {
        ...state,
        recall: false,
        loading: true,
        editStatus: "",
        isLinkGenerated: false,
        isLimitReached: false,
        voiceData: null,
      };
    case constants.DELETE_VIDEO_REQUEST:
    case constants.DOWNLOAD_VIDEO_REQUEST:
      return {
        ...state,
        recall: false,
        loading: true,
        editStatus: "",
        voiceData: null,
      };
    case constants.VIDEO_PAYMENT_REQUEST:
      return {
        ...state,
        paymentLoading: true,
        paymentStatus: false
      };
      case constants.LIMIT_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        paymentDetails:false,
      };
      case constants.GET_VOICE_FAILED:
        // toast.error(action.payload.message);
      return {
        ...state,
        loading: false,
        paymentLoading:false,
        editStatus: "",
        scriptLoading: false,
        planDetails: action?.payload?.data?.plan,
        videoDataErrorMessage: action.payload.message,
        isLimitReached: action.payload?.limit_reached ? action.payload?.limit_reached : false,
      };
    case constants.GET_VIDEO_FAILED: //FAILED
    case constants.EDIT_VOICE_FAILED:
    case constants.FETCH_VIDEO_FAILED:
    case constants.DELETE_VIDEO_FAILED:
    case constants.DOWNLOAD_VIDEO_FAILED:
      return {
        ...state,
        loading: false,
        editStatus: "",
        scriptLoading: false,
        videoDataErrorMessage: action.payload,
        paymentLoading:false,
      };
    case constants.FETCH_ALL_VIDEO_FAILED:
      return {
        ...state,
        loading: false,
        editStatus: "",
        scriptLoading: false,
        isLimitReached: false,
        videoDataErrorMessage: action.payload,
        paymentLoading:false,
      };
    case constants.VIDEO_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        paymentLoading:false,
        editStatus: "",
        scriptLoading: false,
        videoDataErrorMessage: action.payload,
        paymentStatus: false
      };

    case constants.GET_VIDEO_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
        videoUrl: null,
        deleteSuccess: false,
        isLinkGenerated: true,
        videoData: action.payload.data,
        paymentLoading: false
      };
    case constants.VIDEO_PAYMENT_SUCCESS:
      // toast.success(action.payload.message);
      return {
        ...state,
        paymentLoading: false,
        paymentStatus: action.payload?.status
      };

    case constants.DELETE_VIDEO_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
        videoUrl: null,
        // videoData: action.payload.data,
      };

    case constants.DOWNLOAD_VIDEO_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
        videoUrl: action.payload.data[0].video_file,
      };

    case constants.GET_VOICE_SUCCESS:
        toast.error(action.payload.message);
      return {
        ...state,
        deleteSuccess: false,
        scriptLoading: false,
        recall: action.payload.recall,
        voiceData: action.payload.data,
        videoUrl: null,
        paymentLoading: false
      };

    case constants.EDIT_VOICE_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
        editStatus: action.payload.status,
        videoUrl: null,

        // voiceData: action.payload.data,
      };
    case constants.FETCH_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
        videoUrl: null,

        // voiceData: action.payload.data,
      };
    case constants.FETCH_ALL_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
        isLimitReached: false,
        videoTableData: action.payload.results,
        totalCount: action.payload.count,
        videoUrl: null,

        // voiceData: action.payload.data,
      };
    case constants.PAYMENT_STATUS_UPDATE:
      return{
        ...state,
        paymentStatus: false
      }
    default:
      return { ...state };
  }
};

export default Video;
