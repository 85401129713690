import { constants } from "./constants";

export const resetAnalyticsMessage = () => ({
    type: constants.RESET_ANALYTICS_MESSAGE
});

export const getRevenueReport = (params) => ({
    type: constants.GET_REVENUE_REPORT_REQUEST,
    params
});

export const getRevenueReportSuccess = (report) => ({
    type: constants.GET_REVENUE_REPORT_SUCCESS,
    payload: report
});

export const getRevenueReportFailed = error => ({
    type: constants.GET_REVENUE_REPORT_FAILED,
    payload: error
});
