import React from "react";

export const components = {
  faq: React.lazy(() => import("../components/Public/FAQ")), //User
  login: React.lazy(() => import("../components/Auth/Login")),
  reviews: React.lazy(() => import("../containers/Reviews/index")),
  fbacalculator: React.lazy(() => import("../containers/FbaCalculator/index")),
  category: React.lazy(() => import("../containers/Category/index")),
  pricing: React.lazy(() => import("../components/Public/Pricing")),
  blog: React.lazy(() => import("../components/Public/Blog")),
  blogdetails: React.lazy(() => import("../components/Public/BlogDetails")),
  home: React.lazy(() => import("../components/Dashboard/Welcome")),
  products: React.lazy(() => import("../containers/Products/index")),
  settings: React.lazy(() => import("../containers/Settings/index")),
  NotFound: React.lazy(() => import("../components/common/NotFound")),
  features: React.lazy(() => import("../components/Public/Features")),
  feedback: React.lazy(() => import("../components/Public/FeedBack")),
  dashboard: React.lazy(() => import("../containers/Dashboard/index")),
  analytics: React.lazy(() => import("../containers/Analytics/index")),
  welcomePage: React.lazy(() => import("../containers/Welcome/index")),
  contactus: React.lazy(() => import("../components/Public/ContactUs")),
  onBoarding: React.lazy(() => import("../containers/OnBoarding/index")),
  monitoring: React.lazy(() => import("../containers/Monitoring/index")),
  videoCreation: React.lazy(() => import("../containers/videoCreation/index")),
  dealsManagement: React.lazy(() =>
  import("../containers/DealsManagement/index")
  ),
  privacy: React.lazy(() => import("../components/common/PrivacyPolicy")),
  termsandconditions: React.lazy(() =>
  import("../components/common/TermsAndConditions")
  ),
  CompetitorProducts: React.lazy(() => import("../containers/OtherProducts/index")),
  CategorySpy: React.lazy(() => import("../containers/CategorySpy/index")),
  CompetitorSpy: React.lazy(() => import("../containers/CompetitorSpy/index")),
  Keyword: React.lazy(() => import("../containers/Keyword/index")),
  unsubscribeNewsletter: React.lazy(() => import("../components/Public/UnsubscribeNewsletter")),
  
  adminFaq: React.lazy(() => import("../containers/AdminModule/FAQ/index")), //Admin
  adminLogin: React.lazy(() => import("../components/AdminModule/AdminLogin")),
  adminResetPassword: React.lazy(() =>
    import("../components/AdminModule/ForgotPassword")
  ),
  adminAlerts: React.lazy(() =>
    import("../containers/AdminModule/Alerts/index")
  ),
  customer: React.lazy(() =>
    import("../containers/AdminModule/Customers/index")
  ),
  adminInquiry: React.lazy(() =>
    import("../containers/AdminModule/AdminEnquiry/index")
  ),
  adminSettings: React.lazy(() =>
    import("../containers/AdminModule/AdminSettings/index")
  ),
  adminDashboard: React.lazy(() =>
    import("../containers/AdminModule/AdminDashboard/index")
  ),
  adminSubscription: React.lazy(() =>
    import("../containers/AdminModule/AdminSubscription/index")
  ),
  adminFeedback: React.lazy(() =>
    import("../containers/AdminModule/AdminFeedback/index")
  ),
  adminDealsManagement: React.lazy(() =>
    import("../containers/AdminModule/AdminDealsManagement/index")
  ),
  adminSellerDealsReport: React.lazy(() =>
    import("../containers/AdminModule/AdminSellerDealsReport/index")
  ),
  adminUserReport: React.lazy(() =>
    import("../containers/AdminModule/AdminUserReport/index")
  ),
  adminDealSuggestions: React.lazy(() =>
    import("../containers/AdminModule/AdminDealSuggestions/index")
  ),
  adminDealsDashboard: React.lazy(() =>
    import("../containers/AdminModule/AdminDealDashboard/index")
  ),
  sellerDashboard: React.lazy(() =>
    import("../containers/sellerDashboard/index")
  ),
  adminBlogManagement: React.lazy(() =>
    import("../containers/AdminModule/BlogManagement/index")
  ),
  adminReferAndEarn: React.lazy(() =>
    import("../containers/AdminModule/ReferandEarn/index")
  ),
};
