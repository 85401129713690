export const constants = {
  RESET_ADMIN_FAQ_MESSAGE: "RESET_ADMIN_FAQ_MESSAGE",

  GET_ADMIN_FAQ_REQUEST: "GET_ADMIN_FAQ_REQUEST",
  GET_ADMIN_FAQ_SUCCESS: "GET_ADMIN_FAQ_SUCCESS",
  GET_ADMIN_FAQ_FAILED: "GET_ADMIN_FAQ_FAILED",

  ADD_ADMIN_FAQ_REQUEST: "ADD_ADMIN_FAQ_REQUEST",
  ADD_ADMIN_FAQ_SUCCESS: "ADD_ADMIN_FAQ_SUCCESS",
  ADD_ADMIN_FAQ_FAILED: "ADD_ADMIN_FAQ_FAILED",

  EDIT_ADMIN_FAQ_REQUEST: "EDIT_ADMIN_FAQ_REQUEST",
  EDIT_ADMIN_FAQ_SUCCESS: "EDIT_ADMIN_FAQ_SUCCESS",
  EDIT_ADMIN_FAQ_FAILED: "EDIT_ADMIN_FAQ_FAILED",

  DELETE_ADMIN_FAQ_REQUEST: "DELETE_ADMIN_FAQ_REQUEST",
  DELETE_ADMIN_FAQ_SUCCESS: "DELETE_ADMIN_FAQ_SUCCESS",
  DELETE_ADMIN_FAQ_FAILED: "DELETE_ADMIN_FAQ_FAILED",
};
