import React from "react";
import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../helpers/api";
import { getQueryParams } from "../../helpers/structureData";
import {
  getKeywordSuccess,
  getKeywordFailed,
  getTitleSuccess,
  getTitleFailed,
} from "./actions";

function* getKeyword({ params }) {
  let options = getUnProtectedOptions(
    endpoints.keywordRecommendation,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getKeywordSuccess(response.data));
    } else {
      yield put(getKeywordFailed(response.data.message));
    }
  } catch (error) {
    yield put(getKeywordFailed(error || "Error not found!"));
  }
}

function* getTitle({ params }) {
  let options = getUnProtectedOptions(endpoints.keywordTitle, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getTitleSuccess(response.data));
    } else {
      yield put(getTitleFailed(response.data));
    }
  } catch (error) {
    yield put(getTitleFailed(error || "Error not found!"));
  }
}
export function* watchGetKeyword() {
  yield takeLatest(constants.GET_KEYWORD_REQUEST, getKeyword);
}
export function* watchGetTitle() {
  yield takeLatest(constants.GET_TITLE_REQUEST, getTitle);
}

function* keywordSaga() {
  yield all([watchGetKeyword(), watchGetTitle()]);
}

export default keywordSaga;
