import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  referAndEarnData: [],
  referApprove: null,
  approvalResponse:null,
  total_count: null,
  errorMessage: null,
};

const AdminReferAndEarn = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.FETCH_REFER_AND_EARN_REQUEST:
    case constants.APPROVE_REFERRAL_REQUEST:

      return {
        ...state,
        loading: true,
        referApprove: null,
        approvalResponse: null,
        // referAndEarnData:null
      };
    case constants.FETCH_REFER_AND_EARN_SUCCESS:
      return {
        ...state,
        loading: false,
        referAndEarnData: action.payload.data,
        total_count: action.payload.count,
      };
      case constants.APPROVE_REFERRAL_SUCCESS:
        console.log(action.payload,"approve/reject");
      return {
        ...state,
        loading: false,
        referApprove: true,
        approvalResponse: action.payload.message,
      };
    case constants.FETCH_REFER_AND_EARN_FAILED:
    case constants.APPROVE_REFERRAL_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        referApprove: null,
        // referAndEarnData:null
      };

    default: {
      return { ...state };
    }
  }
};

export default AdminReferAndEarn;
