import { constants } from "./constants";

export const getCheckSellerStatus = (params) => ({
  type: constants.GET_CHECK_SELLER_STATUS_REQUEST,
  params,
});
export const getCheckSellerStatusSuccess = (products) => ({
  type: constants.GET_CHECK_SELLER_STATUS_SUCCESS,
  payload: products,
});
export const getCheckSellerStatusFailed = (error) => ({
  type: constants.GET_CHECK_SELLER_STATUS_FAILED,
  payload: error,
});
