import React from "react";
import Loadable from "react-loadable";
import { Route } from "react-router-dom";
import LoaderSpinner from "../components/common/Loader";

const AuthLayout = Loadable({
    loader: () => import("../layout/AdminAuthLayout"),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading: LoaderSpinner
});

const AdminPublicRoute = ({ component: Component, ...rest }) => {
    return (
        <AuthLayout {...rest}>
            <Route
                {...rest}
                render={props =>
                    <Component {...props} />
                }
            />
        </AuthLayout>
    );
};

export default AdminPublicRoute;