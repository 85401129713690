import { constants } from "./constants";

export const getCompetitor = (params) => ({
  type: constants.GET_COMPETITOR_REQUEST,
  params,
});

export const getCompetitorSuccess = (products) => ({
  type: constants.GET_COMPETITOR_SUCCESS,
  payload: products,
});

export const getCompetitorFailed = (error) => ({
  type: constants.GET_COMPETITOR_FAILED,
  payload: error,
});

//comopetitor alerts

export const getCompetitorAlerts = (params) => ({
  type: constants.GET_COMPETITOR_ALERTS_REQUEST,
  params,
});

export const getCompetitorAlertsSuccess = (products) => ({
  type: constants.GET_COMPETITOR_ALERTS_SUCCESS,
  payload: products,
});

export const getCompetitorAlertsFailed = (error) => ({
  type: constants.GET_COMPETITOR_ALERTS_FAILED,
  payload: error,
});

//UPDATE comopetitor alerts

export const updateCompetitorAlerts = (params) => ({
  type: constants.UPDATE_COMPETITOR_ALERTS_REQUEST,
  params,
});

export const updateCompetitorAlertsSuccess = (products) => ({
  type: constants.UPDATE_COMPETITOR_ALERTS_SUCCESS,
  payload: products,
});

export const updateCompetitorAlertsFailed = (error) => ({
  type: constants.UPDATE_COMPETITOR_ALERTS_FAILED,
  payload: error,
});

// for add new
export const addCompetitor = (params) => ({
  type: constants.ADD_COMPETITOR_REQUEST,
  params,
});

export const addCompetitorSuccess = (data) => ({
  type: constants.ADD_COMPETITOR_SUCCESS,
  payload: data,
});

export const addCompetitorFailed = (error) => ({
  type: constants.ADD_COMPETITOR_FAILED,
  payload: error,
});
//delete

export const deleteCompetitor = (params) => ({
  type: constants.DELETE_COMPETITOR_REQUEST,
  params,
});

export const deleteCompetitorSuccess = (data) => ({
  type: constants.DELETE_COMPETITOR_SUCCESS,
  payload: data,
});

export const deleteCompetitorFailed = (error) => ({
  type: constants.DELETE_COMPETITOR_FAILED,
  payload: error,
});

// sort filter
export const fetchFilterSearch = (params) => ({
  type: constants.FETCH_FILTER_SEARCH_REQUEST,
  params
});

export const fetchFilterSearchSuccess = (report) => ({
  type: constants.FETCH_FILTER_SEARCH_SUCCESS,
  payload: report
});

export const fetchFilterSearchFailed = error => ({
  type: constants.FETCH_FILTER_SEARCH_FAILED,
  payload: error
});
