import { constants } from "./constants";

const INIT_STATE = {
    loading: false,
    faqList: [],
    errorMessage: null,
    successMessage: null,
};

const AdminFaq = (state = INIT_STATE, action) => {
    switch (action.type) {
        case constants.RESET_ADMIN_FAQ_MESSAGE:           //RESET MSGS
            return {
                ...state,
                successMessage: null,
                errorMessage: null
            };

        // REQUEST    
        case constants.GET_ADMIN_FAQ_REQUEST:           // REQUEST   
        case constants.ADD_ADMIN_FAQ_REQUEST:
        case constants.EDIT_ADMIN_FAQ_REQUEST:
        case constants.DELETE_ADMIN_FAQ_REQUEST:
            return { ...state, loading: true };

        case constants.GET_ADMIN_FAQ_FAILED:            //FAILED
        case constants.ADD_ADMIN_FAQ_FAILED:
        case constants.EDIT_ADMIN_FAQ_FAILED:
        case constants.DELETE_ADMIN_FAQ_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false
            };

        case constants.GET_ADMIN_FAQ_SUCCESS:           //SUCCESS
            return {
                ...state,
                loading: false,
                faqList: action.payload.data,
                pageCount: action.payload.count,
                errorMessage: null
            };
        case constants.DELETE_ADMIN_FAQ_SUCCESS:           //SUCCESS
            return {
                ...state,
                loading: false,
                successMessage: action.payload,
                errorMessage: null
            };
        case constants.EDIT_ADMIN_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload,
                errorMessage: null
            };
        case constants.ADD_ADMIN_FAQ_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload,
                errorMessage: null
            };

        default:
            return { ...state };
    }
};

export default AdminFaq;