export const constants = {
      GET_LISTING_CATEGORY_REQUEST: "GET_LISTING_CATEGORY_REQUEST",
      GET_LISTING_CATEGORY_SUCCESS: "GET_LISTING_CATEGORY_SUCCESS",
      GET_LISTING_CATEGORY_FAILED: "GET_LISTING_CATEGORY_FAILED",

      FETCH_CATEGORY_TRENDS_REQUEST: "FETCH_CATEGORY_TRENDS_REQUEST",
      FETCH_CATEGORY_TRENDS_SUCCESS: "FETCH_CATEGORY_TRENDS_SUCCESS",
      FETCH_CATEGORY_TRENDS_FAILED: "FETCH_CATEGORY_TRENDS_FAILED",

      LOAD_MORE_CONTENT_REQUEST: "LOAD_MORE_CONTENT_REQUEST",
      LOAD_MORE_CONTENT_SUCCESS: "LOAD_MORE_CONTENT_SUCCESS",
      LOAD_MORE_CONTENT_FAILED: "LOAD_MORE_CONTENT_FAILED",
}