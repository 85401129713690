export const constants = {
    GET_DEALS_BY_ADMIN_REQUEST: "GET_DEALS_BY_ADMIN_REQUEST",
    GET_DEALS_BY_ADMIN_SUCCESS: "GET_DEALS_BY_ADMIN_SUCCESS",
    GET_DEALS_BY_ADMIN_FAILED: "GET_DEALS_BY_ADMIN_FAILED",

    SEARCH_DEALS_BY_ADMIN_REQUEST: "SEARCH_DEALS_BY_ADMIN_REQUEST",
    SEARCH_DEALS_BY_ADMIN_SUCCESS: "SEARCH_DEALS_BY_ADMIN_SUCCESS",
    SEARCH_DEALS_BY_ADMIN_FAILED: "SEARCH_DEALS_BY_ADMIN_FAILED",
  
    EDIT_DEALS_BY_ADMIN_REQUEST: "EDIT_DEALS_BY_ADMIN_REQUEST",
    EDIT_DEALS_BY_ADMIN_SUCCESS: "EDIT_DEALS_BY_ADMIN_SUCCESS",
    EDIT_DEALS_BY_ADMIN_FAILED: "EDIT_DEALS_BY_ADMIN_FAILED",
  
    CREATE_DEALS_BY_ADMIN_REQUEST: "CREATE_DEALS_BY_ADMIN_REQUEST",
    CREATE_DEALS_BY_ADMIN_SUCCESS: "CREATE_DEALS_BY_ADMIN_SUCCESS",
    CREATE_DEALS_BY_ADMIN_FAILED: "CREATE_DEALS_BY_ADMIN_FAILED",

    SORT_DEALS_BY_ADMIN_REQUEST: "SORT_DEALS_BY_ADMIN_REQUEST",
    SORT_DEALS_BY_ADMIN_SUCCESS: "SORT_DEALS_BY_ADMIN_SUCCESS",
    SORT_DEALS_BY_ADMIN_FAILED: "SORT_DEALS_BY_ADMIN_FAILED",

    SELLER_PRODUCT_LIST_REQUEST: "SELLER_PRODUCT_LIST_REQUEST",
    SELLER_PRODUCT_LIST_SUCCESS: "SELLER_PRODUCT_LIST_SUCCESS",
    SELLER_PRODUCT_LIST_FAILED: "SELLER_PRODUCT_LIST_FAILED",
  };
  