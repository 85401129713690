import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  loadingSearch: false,
  dealsList: {},
  isDeleted: false,
  sellersList: {},
  productList: {},
  errorMessage: null,
  successMessage: null,
  table_total_pages: "",
  deleteDealSuccessMessage: null,
};

const AdminDealsManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_DEALS_BY_ADMIN_REQUEST:

    case constants.EDIT_DEALS_BY_ADMIN_REQUEST:
    case constants.CREATE_DEALS_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        successMessage: null,
        errorMessage: null,
        isDeleted: false,
      };
    case constants.SORT_DEALS_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: false,
        successMessage: null,
        errorMessage: null,
        isDeleted: false,
      };
    case constants.SEARCH_DEALS_BY_ADMIN_REQUEST:
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
        isDeleted: false,
      };
    case constants.SELLER_PRODUCT_LIST_REQUEST: // REQUEST
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
        isDeleted: false,
      };

    //FAILED
    case constants.GET_DEALS_BY_ADMIN_FAILED:
    case constants.SEARCH_DEALS_BY_ADMIN_FAILED:
    case constants.EDIT_DEALS_BY_ADMIN_FAILED:
    case constants.CREATE_DEALS_BY_ADMIN_FAILED:
    case constants.SORT_DEALS_BY_ADMIN_FAILED:
    case constants.SELLER_PRODUCT_LIST_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: null,
        loading: false,
      };

    case constants.GET_DEALS_BY_ADMIN_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        dealsList: action.payload.data,
        successMessage: action.payload.message,
        table_total_pages: action.payload.total_pages,
        isDeleted: action.payload.is_deleted
          ? true
          : false,
      };

    case constants.SEARCH_DEALS_BY_ADMIN_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        dealsList: action.payload.data,
        successMessage: action.payload.message,
        table_total_pages: action.payload.total_pages,
        isDeleted: action.payload.is_deleted
          ? action.payload.is_deleted
          : false,
      };

    case constants.CREATE_DEALS_BY_ADMIN_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        dealsList: action.payload.data,
        successMessage: action.payload.message,
        table_total_pages: action.payload.total_pages,
      };
    case constants.EDIT_DEALS_BY_ADMIN_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        dealsList: action.payload.data,
        successMessage: action.payload.message,
        table_total_pages: action.payload.total_pages,
      };
    case constants.SORT_DEALS_BY_ADMIN_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        dealsList: action.payload.data,
        successMessage: action.payload.message,
        table_total_pages: action.payload.total_pages,
      };
    case constants.SELLER_PRODUCT_LIST_SUCCESS: // REQUEST
      return {
        ...state,
        loading: false,
        errorMessage: null,
        productList: action.payload.product_data,
        sellersList: action.payload.seller_data,
      };
    default:
      return { ...state };
  }
};

export default AdminDealsManagement;
