import { constants } from "./constants";

export const resetMessages = () => ({
  type: constants.RESET_CUSTOMER_MESSAGE,
});

export const getCustomers = (data) => ({
  type: constants.GET_CUSTOMERS_REQUEST,
  data,
});

export const getCustomersSuccess = (data) => ({
  type: constants.GET_CUSTOMERS_SUCCESS,
  payload: data,
});

export const getCustomersFailed = (error) => ({
  type: constants.GET_CUSTOMERS_FAILED,
  payload: error,
});

export const generateTask = (data) => ({
  type: constants.GENERATE_TASK,
  data,
});

export const generateTaskSuccess = (data) => ({
  type: constants.GENERATE_TASK_SUCCESS,
  payload: data,
});

export const generateTaskFailed = (error) => ({
  type: constants.GENERATE_TASK_FAILED,
  payload: error,
});

export const sendSellerMail = (data) => ({
  type: constants.SEND_SELLER_MAIL,
  data,
});

export const sendSellerMailSuccess = (data) => ({
  type: constants.SEND_SELLER_MAIL_SUCCESS,
  payload: data,
});

export const sendSellerMailFailed = (error) => ({
  type: constants.SEND_SELLER_MAIL_FAILED,
  payload: error,
});
