import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  addCouponFailed,
  addCouponSuccess,
  editCouponFailed,
  editCouponSuccess,
  getCouponFailed,
  getCouponSuccess,
  getAdminSubscriptionsSuccess,
  getAdminSubscriptionsFailed,
} from "./action";

function* getCouponList({ data }) {
  let options = getProtectedOptions(endpoints.getCoupon, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getCouponSuccess(response.data));
    } else {
      yield put(getCouponFailed(response.data.message));
    }
  } catch (error) {
    yield put(getCouponFailed(error || "Error not found!"));
  }
}

function* addCouponList({ data }) {
  let options = getProtectedOptions(endpoints.addCoupon, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addCouponSuccess(response.data.message));
    } else {
      yield put(addCouponFailed(response.data.message));
    }
  } catch (error) {
    yield put(addCouponFailed(error || "Error not found!"));
  }
}

function* editCouponList({ data }) {
  let options = getProtectedOptions(endpoints.editCoupon, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editCouponSuccess(response.data.message));
    } else {
      yield put(editCouponFailed(response.data.message));
    }
  } catch (error) {
    yield put(editCouponFailed(error || "Error not found!"));
  }
}

function* getSubscriptionsList({ data }) {
  let options = getProtectedOptions(
    endpoints.getAdminSubscription,
    "POST",
    data
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getAdminSubscriptionsSuccess(response.data.data));
    } else {
      yield put(getAdminSubscriptionsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getAdminSubscriptionsFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetCoupon() {
  yield takeLatest(constants.GET_ADMIN_COUPON_REQUEST, getCouponList);
}
export function* watchAddCoupon() {
  yield takeLatest(constants.ADD_ADMIN_COUPON_REQUEST, addCouponList);
}
export function* watchEditCoupon() {
  yield takeLatest(constants.EDIT_ADMIN_COUPON_REQUEST, editCouponList);
}
export function* watchGetSubscriptions() {
  yield takeLatest(
    constants.GET_ADMIN_SUBSCRIPTIONS_REQUEST,
    getSubscriptionsList
  );
}

function* adminSubscriptionSaga() {
  yield all([
    watchGetCoupon(),
    watchAddCoupon(),
    watchEditCoupon(),
    watchGetSubscriptions(),
  ]);
}

export default adminSubscriptionSaga;
