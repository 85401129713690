import { constants } from "./constants";

const INIT_STATE = {
  blogList: [],
  total_count: 0,
  blogData: {},
  loading: false,
  pageCount: null,
  isDeleted: false,
  errorMessage: null,
  successMessage: null,
};

const AMZBlogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_ADMIN_BLOG_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        blogData: {},
        successMessage: null,
      };
      case constants.DELETE_ADMIN_BLOG_REQUEST:
      case constants.EDIT_ADMIN_BLOG_REQUEST:
      case constants.ADD_ADMIN_BLOG_REQUEST:
      case constants.SEARCH_SORT_ADMIN_BLOG_REQUEST:
        return {
          ...state,
          loading: true,
          successMessage: null,
        };
    case constants.GET_BLOG_LIST_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
        blogList: [],
        total_count: 0,
        pageCount: null,
        successMessage: null,
      };

    case constants.GET_BLOG_LIST_FAILED: //FAILED
    case constants.EDIT_ADMIN_BLOG_FAILED:
    case constants.DELETE_ADMIN_BLOG_FAILED:
    case constants.ADD_ADMIN_BLOG_FAILED:
    case constants.GET_ADMIN_BLOG_DETAILS_FAILED:
    case constants.SEARCH_SORT_ADMIN_BLOG_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };

    case constants.GET_BLOG_LIST_SUCCESS: // SUCCESS
    case constants.SEARCH_SORT_ADMIN_BLOG_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        blogList: action.payload.data,
        loading: false,
        total_count: action.payload.total_count,
        total_pages: action.payload.total_pages,
      };
    case constants.GET_ADMIN_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        blogData: action.payload?.data ? action.payload?.data[0] : {},
      };

    case constants.DELETE_ADMIN_BLOG_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        successMessage: action.payload.message,
      };
    case constants.EDIT_ADMIN_BLOG_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        successMessage: action.payload,
      };
    case constants.ADD_ADMIN_BLOG_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        successMessage: action.payload,
      };
    // case constants.SEARCH_SORT_ADMIN_BLOG_SUCCESS:
    //   return {
    //     ...state,
    //     deals: action.payload.data,
    //     table_total_pages: action.payload.total_pages,
    //     loading: false,
    //   };
    default:
      return { ...state };
  }
};

export default AMZBlogs;
