import { constants } from "./constants";

export const resetMonitoringMessages = () => ({
    type: constants.RESET_MONITORING_MESSAGE
});

export const getMonitoringReport = (params) => ({
    type: constants.GET_MONITORING_REPORT_REQUEST,
    params
});

export const getMonitoringReportSuccess = (report) => ({
    type: constants.GET_MONITORING_REPORT_SUCCESS,
    payload: report
});

export const getMonitoringReportFailed = error => ({
    type: constants.GET_MONITORING_REPORT_FAILED,
    payload: error
});

export const getCompetitorReport = (params) => ({
    type: constants.GET_COMPETITOR_REPORT_REQUEST,
    params
});

export const getCompetitorReportSuccess = (report) => ({
    type: constants.GET_COMPETITOR_REPORT_SUCCESS,
    payload: report
});

export const getCompetitorReportFailed = error => ({
    type: constants.GET_COMPETITOR_REPORT_FAILED,
    payload: error
});
export const deleteAlert = (params) => ({
    type: constants.DELETE_ALERT_REQUEST,
    params
});

export const deleteAlertSuccess = (report) => ({
    type: constants.DELETE_ALERT_SUCCESS,
    payload: report
});

export const deleteAlertFailed = error => ({
    type: constants.DELETE_ALERT_FAILED,
    payload: error
});
// on toggle delete
export const alertRemove = (params) => ({
    type: constants.ALERT_REMOVE_REQUEST,
    params
});

export const alertRemovetSuccess = (report) => ({
    type: constants.ALERT_REMOVE_SUCCESS,
    payload: report
});

export const alertRemoveFailed = error => ({
    type: constants.ALERT_REMOVE_FAILED,
    payload: error
});

