export const constants = {
  GET_BLOG_LIST_REQUEST: "GET_BLOG_LIST_REQUEST",
  GET_BLOG_LIST_SUCCESS: "GET_BLOG_LIST_SUCCESS",
  GET_BLOG_LIST_FAILED: "GET_BLOG_LIST_FAILED",

  GET_ADMIN_BLOG_DETAILS_REQUEST: "GET_ADMIN_BLOG_DETAILS_REQUEST",
  GET_ADMIN_BLOG_DETAILS_SUCCESS: "GET_ADMIN_BLOG_DETAILS_SUCCESS",
  GET_ADMIN_BLOG_DETAILS_FAILED: "GET_ADMIN_BLOG_DETAILS_FAILED",

  // for edit
  EDIT_ADMIN_BLOG_REQUEST: "EDIT_ADMIN_BLOG_REQUEST",
  EDIT_ADMIN_BLOG_SUCCESS: "EDIT_ADMIN_BLOG_SUCCESS",
  EDIT_ADMIN_BLOG_FAILED: "EDIT_ADMIN_BLOG_FAILED",

  // for ADD NEW
  ADD_ADMIN_BLOG_REQUEST: "ADD_ADMIN_BLOG_REQUEST",
  ADD_ADMIN_BLOG_SUCCESS: "ADD_ADMIN_BLOG_SUCCESS",
  ADD_ADMIN_BLOG_FAILED: "ADD_ADMIN_BLOG_FAILED",

  DELETE_ADMIN_BLOG_REQUEST: "DELETE_ADMIN_BLOG_REQUEST",
  DELETE_ADMIN_BLOG_SUCCESS: "DELETE_ADMIN_BLOG_SUCCESS",
  DELETE_ADMIN_BLOG_FAILED: "DELETE_ADMIN_BLOG_FAILED",

  // For search and sort
  SEARCH_SORT_ADMIN_BLOG_REQUEST: "SEARCH_SORT_ADMIN_BLOG_REQUEST",
  SEARCH_SORT_ADMIN_BLOG_SUCCESS: "SEARCH_SORT_ADMIN_BLOG_SUCCESS",
  SEARCH_SORT_ADMIN_BLOG_FAILED: "SEARCH_SORT_ADMIN_BLOG_FAILED",

};
