import { constants } from "./constants";

export const resetSettingsMessages = () => ({
  type: constants.RESET_SETTINGS_MESSAGE,
});

export const resetSettingsState = () => ({
  type: constants.RESET_SETTINGS_STATE,
});
// DON'T REMOVE THIS COMMIT, THIS NEEDED FOR SUBSCRIPTION
// remove params
export const getSubscriptionPlans = (params) => ({
  type: constants.GET_SUBSCRIPTION_PLANS_REQUEST,
  params,
});

export const getSubscriptionPlansForUser = () => ({
  type: constants.GET_USER_SUBSCRIPTION_PLANS_REQUEST,
});

export const getSubscriptionPlansSuccess = (plans) => ({
  type: constants.GET_SUBSCRIPTION_PLANS_SUCCESS,
  payload: plans,
});

export const getSubscriptionPlansFailed = (error) => ({
  type: constants.GET_SUBSCRIPTION_PLANS_FAILED,
  payload: error,
});

export const getNotificationTypes = (sellerData) => ({
  type: constants.GET_SELLER_NOTIFICATION_TYPES_REQUEST,
  sellerData,
});

export const getNotificationTypesSuccess = (types) => ({
  type: constants.GET_SELLER_NOTIFICATION_TYPES_SUCCESS,
  payload: types,
});

export const getNotificationTypesFailed = (error) => ({
  type: constants.GET_SELLER_NOTIFICATION_TYPES_FAILED,
  payload: error,
});

export const changeNotificationValidty = (params) => ({
  type: constants.CHANGE_NOTIFICATION_VALIDITY_REQUEST,
  params,
});

export const changeNotificationValidtySuccess = (data) => ({
  type: constants.CHANGE_NOTIFICATION_VALIDITY_SUCCESS,
  payload: data,
});

export const changeNotificationValidtyFailed = (error) => ({
  type: constants.CHANGE_NOTIFICATION_VALIDITY_FAILED,
  payload: error,
});

export const changePhone = (params) => ({
  type: constants.CHANGE_PHONE_REQUEST,
  params,
});

export const changePhoneSuccess = (data) => ({
  type: constants.CHANGE_PHONE_SUCCESS,
  payload: data,
});

export const changePhoneFailed = (error) => ({
  type: constants.CHANGE_PHONE_FAILED,
  payload: error,
});

export const getSellerAccount = (params) => ({
  type: constants.GET_SELLER_ACCOUNT_REQUEST,
  params,
});

export const getSellerAccountSuccess = (data) => ({
  type: constants.GET_SELLER_ACCOUNT_SUCCESS,
  payload: data,
});

export const getSellerAccountFailed = (error) => ({
  type: constants.GET_SELLER_ACCOUNT_FAILED,
  payload: error,
});

export const removeSeller = (params) => ({
  type: constants.REMOVE_SELLER_REQUEST,
  params,
});

export const removeSellerSuccess = (data) => ({
  type: constants.REMOVE_SELLER_SUCCESS,
  payload: data,
});

export const removeSellerFailed = (error) => ({
  type: constants.REMOVE_SELLER_FAILED,
  payload: error,
});

export const productsAdd = (params) => ({
  type: constants.PRODUCTS_ADD_REQUEST,
  params,
});

export const productsAddSuccess = (data) => ({
  type: constants.PRODUCTS_ADD_SUCCESS,
  payload: data,
});

export const productsAddFailed = (error) => ({
  type: constants.PRODUCTS_ADD_FAILED,
  payload: error,
});

export const addSubscriptionPlan = (params) => ({
  type: constants.ADD_SUBSCRIPTION_PLAN_REQUEST,
  params,
});

export const addSubscriptionPlanSuccess = (data) => ({
  type: constants.ADD_SUBSCRIPTION_PLAN_SUCCESS,
  payload: data,
});

export const getSubscriptionByUserPlanFailed = (error) => ({
  type: constants.ADD_SUBSCRIPTION_PLAN_FAILED,
  payload: error,
});

export const amazonPayBilling = (params) => ({
  type: constants.AMAZON_PAY_BILLING_REQUEST,
  params,
});

export const amazonPayBillingSuccess = (data) => ({
  type: constants.AMAZON_PAY_BILLING_SUCCESS,
  payload: data,
});

export const amazonPayBillingFailed = (error) => ({
  type: constants.AMAZON_PAY_BILLING_FAILED,
  payload: error,
});

export const getSubscriptionByUser = (params) => ({
  type: constants.GET_SUBSCRIPTION_BY_ID_PLAN_REQUEST,
  params,
});

export const getSubscriptionByUserSuccess = (data) => ({
  type: constants.GET_SUBSCRIPTION_BY_ID_PLAN_SUCCESS,
  payload: data,
});

export const getSubscriptionByUserFailed = (error) => ({
  type: constants.GET_SUBSCRIPTION_BY_ID_PLAN_FAILED,
  payload: error,
});

export const getPaymentHistory = (params) => ({
  type: constants.GET_PAYMENT_HISTORY_REQUEST,
  params,
});

export const getPaymentHistorySuccess = (data) => ({
  type: constants.GET_PAYMENT_HISTORY_SUCCESS,
  payload: data,
});

export const getPaymentHistoryFailed = (error) => ({
  type: constants.GET_PAYMENT_HISTORY_FAILED,
  payload: error,
});

export const getProfileData = (params) => ({
  type: constants.GET_PROFILE_DATA_REQUEST,
  params,
});

export const getProfileDataSuccess = (data) => ({
  type: constants.GET_PROFILE_DATA_SUCCESS,
  payload: data,
});

export const getProfileDataFailed = (error) => ({
  type: constants.GET_PROFILE_DATA_FAILED,
  payload: error,
});

export const cancelSubscription = (params) => ({
  type: constants.CANCEL_SUBSCRIPTION_REQUEST,
  params,
});

export const cancelSubscriptionSuccess = (data) => ({
  type: constants.CANCEL_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const cancelSubscriptionFailed = (error) => ({
  type: constants.CANCEL_SUBSCRIPTION_FAILED,
  payload: error,
});

export const getAlertSettings = (params) => ({
  type: constants.GET_ALERT_SETTINGS_REQUEST,
  params,
});

export const getAlertSettingsSuccess = (data) => ({
  type: constants.GET_ALERT_SETTINGS_SUCCESS,
  payload: data,
});

export const getAlertSettingsFailed = (error) => ({
  type: constants.GET_ALERT_SETTINGS_FAILED,
  payload: error,
});

export const updateAlertSettings = (params) => ({
  type: constants.UPDATE_ALERT_SETTINGS_REQUEST,
  params,
});

export const updateAlertSettingsSuccess = (data) => ({
  type: constants.UPDATE_ALERT_SETTINGS_SUCCESS,
  payload: data,
});

export const updateAlertSettingsFailed = (error) => ({
  type: constants.UPDATE_ALERT_SETTINGS_FAILED,
  payload: error,
});

export const checkCoupon = (params) => ({
  type: constants.CHECK_COUPON_REQUEST,
  params,
});

export const checkCouponSuccess = (data) => ({
  type: constants.CHECK_COUPON_SUCCESS,
  payload: data,
});

export const checkCouponFailed = (error) => ({
  type: constants.CHECK_COUPON_FAILED,
  payload: error,
});

export const feedbackView = () => ({
  type: constants.FEEDBACK_VIEW_REQUEST,
});

export const feedbackViewSuccess = (data) => ({
  type: constants.FEEDBACK_VIEW_SUCCESS,
  payload: data,
});

export const feedbackViewFailed = (error) => ({
  type: constants.FEEDBACK_VIEW_FAILED,
  payload: error,
});

export const fetchInitialDatas = (params) => ({
  type: constants.FETCH_INITIAL_DATA_REQUEST,
  params
});

export const fetchInitialDatasSuccess = (data) => ({
  type: constants.FETCH_INITIAL_DATA_SUCCESS,
  payload: data,
});

export const fetchInitialDatasFailed = (error) => ({
  type: constants.FETCH_INITIAL_DATA_FAILED,
  payload: error,
});

export const changeSubscriptionPlan = (params) => ({
  type: constants.CHANGE_SUBSCRIPTION_PLAN_REQUEST,
  params
});

export const changeSubscriptionPlanSuccess = (data) => ({
  type: constants.CHANGE_SUBSCRIPTION_PLAN_SUCCESS,
  payload: data,
});

export const changeSubscriptionPlanFailed = (error) => ({
  type: constants.CHANGE_SUBSCRIPTION_PLAN_FAILED,
  payload: error,
});
