import { constants } from "./constants";

const INIT_STATE = {
  sellerMail: [],
  loading: false,
  pageCount: null,
  customerList: [],
  errorMessage: null,
  successMessage: null,
  newBackgroundTask: [],
};

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_CUSTOMER_MESSAGE: //RESET MSGS
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };

    // REQUEST
    case constants.GET_CUSTOMERS_REQUEST: // REQUEST
    case constants.GENERATE_TASK:
    case constants.SEND_SELLER_MAIL:
      return { ...state, loading: true };

    case constants.GET_CUSTOMERS_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };

    case constants.GET_CUSTOMERS_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        errorMessage: null,
        customerList: action.payload,
        pageCount: action.payload.total_pages,
      };

    case constants.GENERATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
        newBackgroundTask: action.payload,
      };

    case constants.GENERATE_TASK_FAILED:
      return { ...state, loading: false, errorMessage: action.payload };

    case constants.SEND_SELLER_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
      };

    case constants.SEND_SELLER_MAIL_FAILED:
      return { ...state, loading: false, errorMessage: action.payload };

    default:
      return { ...state };
  }
};

export default Customer;
