import { constants } from "./constants";

const INIT_STATE = {
  faqList: [],
  loading: false,
  blogInfo: null,
  blogList: [],
  currentPage: 1,
  totalCount: 1,
  totalPages: 1,
  sendMessage: null,
  errorMessage: null,
  successMessage: null,
};

const Public = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_PUBLIC_MESSAGE: //RESET MSGS
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };

    case constants.GET_FAQ_REQUEST:
    case constants.GET_USER_BLOG_REQUEST:
    case constants.SUBMIT_FEEDBACK_REQUEST:
    case constants.FOOTER_SUBSCRIPTON_REQUEST:
    case constants.ADD_CONTACT_MESSAGE_REQUEST:
      return { ...state, loading: true };
    case constants.FETCH_BLOG_LIST_DETAIL_REQUEST:
      return { ...state, loading: true, blogInfo: null };

    case constants.ADD_CONTACT_MESSAGE_FAILED:
    case constants.GET_FAQ_FAILED:
    case constants.GET_USER_BLOG_FAILED:
    case constants.SUBMIT_FEEDBACK_FAILED:
    case constants.FOOTER_SUBSCRIPTON_FAILED:
    case constants.FETCH_BLOG_LIST_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case constants.FOOTER_SUBSCRIPTON_SUCCESS:
    case constants.ADD_CONTACT_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload.message,
      };

    case constants.GET_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        faqList: action.payload.data,
      };

    case constants.GET_USER_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        blogList: action.payload.data,
        currentPage: action.payload.page_num,
        totalCount: action.payload.total_count,
        totalPages: action.payload.total_pages,
      };

    case constants.FETCH_BLOG_LIST_DETAIL_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        blogInfo: action.payload.data[0],
        loading: false,
        total_count: action.payload.total_count,
        total_pages: action.payload.total_pages,
      };

    case constants.SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Public;
