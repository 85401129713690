import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  loadingSearch: false,
  deals: {},
  total_deals:0,
  productList: {},
  isDeleted: false,
  errorMessage: null,
  successMessage: null,
  table_total_pages: "",
  deleteDealSuccessMessage: null,
};

const DealsManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_SELLER_DEALS_REQUEST:
    case constants.EDIT_SELLER_DEAL_REQUEST:
    case constants.GET_PRODUCT_LIST_REQUEST:
    case constants.CREATE_SELLER_DEALS_REQUEST:
    case constants.CREATE_SELLER_BULK_DEALS_REQUEST:
      return {
        ...state,
        loading: true,
        successMessage: null,
        errorMessage: null,
        isDeleted: false,
      };
    case constants.SEARCH_SELLER_DEAL_REQUEST:
      return {
        ...state,
        loadingSearch: true,
        successMessage: null,
        errorMessage: null,
        isDeleted: false,
      };
    case constants.SORT_SELLER_DEAL_REQUEST:
      return {
        ...state,
        loadingSearch: true,
        successMessage: null,
        errorMessage: null,
        isDeleted: false,
      };
    //FAILED
    case constants.GET_SELLER_DEALS_FAILED:
    case constants.EDIT_SELLER_DEAL_FAILED:
    case constants.SORT_SELLER_DEAL_FAILED:
    case constants.GET_PRODUCT_LIST_FAILED:
    case constants.CREATE_SELLER_DEALS_FAILED:
    case constants.CREATE_SELLER_BULK_DEALS_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: null,
        loading: false,
      };
    case constants.SEARCH_SELLER_DEAL_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: null,
        loadingSearch: false,
      };

    case constants.GET_SELLER_DEALS_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        deals: action.payload.data,
        successMessage: action.payload.message,
        table_total_pages: action.payload.total_pages,
        isDeleted: action.payload.status ? action.payload.status : false,
      };
    case constants.GET_DEALS_PRODUCT_LIST_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        productList: action.payload.data,
      };
    case constants.CREATE_SELLER_DEALS_SUCCESS: //SUCCESS
      return {
        ...state,
        successMessage: action.payload.message,
        loading: false,
      };
    case constants.CREATE_SELLER_BULK_DEALS_SUCCESS: //SUCCESS
      return {
        ...state,
        successMessage: action.payload.message,
        loading: false,
      };
    case constants.EDIT_SELLER_DEAL_SUCCESS:
      return {
        ...state,
        successMessage: action.payload.message,
        loading: false,
      };
    case constants.SEARCH_SELLER_DEAL_SUCCESS:
      return {
        ...state,
        deals: action.payload.data,
        table_total_pages: action.payload.total_pages,
        loadingSearch: false,
      };
    case constants.SORT_SELLER_DEAL_SUCCESS:
      return {
        ...state,
        deals: action.payload.data,
        table_total_pages: action.payload.total_pages,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default DealsManagement;
