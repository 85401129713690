// @flow
import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import publicSaga from "./public/UserModule/saga";
import reviewSaga from "../containers/Reviews/saga";
import fbaSaga from "../containers/FbaCalculator/saga";
import categorySaga from "../containers/Category/saga";
import categorySpySaga from "../containers/CategorySpy/saga";
import CompetitorSpySaga from "../containers/CompetitorSpy/saga";
import keywordSaga from "../containers/Keyword/saga";
import videoSaga from "../containers/videoCreation/saga";
import settingsSaga from "../containers/Settings/saga";
import productsSaga from "../containers/Products/saga";
import OtherProductsSaga from "../containers/OtherProducts/saga";
import analyticsSaga from "../containers/Analytics/saga";
import dashboardSaga from "../containers/Dashboard/saga";
import monitoringSaga from "../containers/Monitoring/saga";
import dealsManagementSaga from "../containers/DealsManagement/saga";
import sellerDealDashboardsaga from "../containers/sellerDashboard/saga";
import onBoardingSaga from "../containers/OnBoarding/saga";
import sellerWelcomesaga from "../containers/Welcome/saga";


import customerSaga from "../containers/AdminModule/Customers/saga"; //ADMIN
import adminFaqSaga from "../containers/AdminModule/FAQ/saga";
import adminAlertsSaga from "../containers/AdminModule/Alerts/saga";
import adminDashboardSaga from '../containers/AdminModule/AdminDashboard/saga';
import adminSettingsSaga from "../containers/AdminModule/AdminSettings/saga";
import adminSubscriptionSaga from "../containers/AdminModule/AdminSubscription/saga";
import adminEnquirySaga from "../containers/AdminModule/AdminEnquiry/saga";
import feedbackSaga from "../containers/AdminModule/AdminFeedback/saga";
import adminDealManagementSaga from "../containers/AdminModule/AdminDealsManagement/saga";
import adminSellerDealsSaga from "../containers/AdminModule/AdminSellerDealsReport/saga";
import adminUserReportSaga from "../containers/AdminModule/AdminUserReport/saga";
import adminDealDashboardSaga from "../containers/AdminModule/AdminDealDashboard/saga";
import adminDealSuggestionsSaga from "../containers/AdminModule/AdminDealSuggestions/saga";
import adminAmzBlogsSaga from "../containers/AdminModule/BlogManagement/saga";
import referandearnSaga from "../containers/AdminModule/ReferandEarn/saga";

export default function* rootSaga(getState: any): any {
  yield all([
    authSaga(),
    reviewSaga(),
    fbaSaga(),
    categorySaga(),
    categorySpySaga(),
    keywordSaga(),
    CompetitorSpySaga(),
    videoSaga(),
    publicSaga(),
    productsSaga(),
    settingsSaga(),
    analyticsSaga(),
    dashboardSaga(),
    monitoringSaga(),
    customerSaga(),
    adminFaqSaga(),
    OtherProductsSaga(),
    adminSettingsSaga(),
    adminAlertsSaga(),
    adminSubscriptionSaga(),
    sellerDealDashboardsaga(),
    sellerWelcomesaga(),
    adminEnquirySaga(),
    feedbackSaga(),
    onBoardingSaga(),
    adminDashboardSaga(),
    dealsManagementSaga(),
    adminDealManagementSaga(),
    adminSellerDealsSaga(),
    adminUserReportSaga(),
    adminDealDashboardSaga(),
    adminDealSuggestionsSaga(),
    adminAmzBlogsSaga(),
    referandearnSaga()
  ]);
}
