export const constants = {

    RESET_PUBLIC_MESSAGE: "RESET_PUBLIC_MESSAGE",
    
    GET_FAQ_REQUEST: "GET_FAQ_REQUEST",
    GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
    GET_FAQ_FAILED: "GET_FAQ_FAILED",

    GET_USER_BLOG_REQUEST: "GET_USER_BLOG_REQUEST",
    GET_USER_BLOG_SUCCESS: "GET_USER_BLOG_SUCCESS",
    GET_USER_BLOG_FAILED: "GET_USER_BLOG_FAILED",

    SUBMIT_FEEDBACK_REQUEST: "SUBMIT_FEEDBACK_REQUEST",
    SUBMIT_FEEDBACK_SUCCESS: "SUBMIT_FEEDBACK_SUCCESS",
    SUBMIT_FEEDBACK_FAILED: "SUBMIT_FEEDBACK_FAILED",

    FOOTER_SUBSCRIPTON_REQUEST:"FOOTER_SUBSCRIPTON_REQUEST",
    FOOTER_SUBSCRIPTON_SUCCESS: "FOOTER_SUBSCRIPTON_SUCCESS",
    FOOTER_SUBSCRIPTON_FAILED: "FOOTER_SUBSCRIPTON_FAILED",

    ADD_CONTACT_MESSAGE_REQUEST: "ADD_CONTACT_MESSAGE_REQUEST",
    ADD_CONTACT_MESSAGE_SUCCESS: "ADD_CONTACT_MESSAGE_SUCCESS",
    ADD_CONTACT_MESSAGE_FAILED: "ADD_CONTACT_MESSAGE_FAILED",

    FETCH_BLOG_LIST_DETAIL_REQUEST: "FETCH_BLOG_LIST_DETAIL_REQUEST",
    FETCH_BLOG_LIST_DETAIL_SUCCESS: "FETCH_BLOG_LIST_DETAIL_SUCCESS",
    FETCH_BLOG_LIST_DETAIL_FAILED: "FETCH_BLOG_LIST_DETAIL_FAILED",

}