export const constants = {
  GET_KEYWORD_REQUEST: "GET_KEYWORD_REQUEST",
  GET_KEYWORD_SUCCESS: "GET_KEYWORD_SUCCESS",
  GET_KEYWORD_FAILED: "GET_KEYWORD_FAILED",

  GET_TITLE_REQUEST: "GET_TITLE_REQUEST",
  GET_TITLE_SUCCESS: "GET_TITLE_SUCCESS",
  GET_TITLE_FAILED: "GET_TITLE_FAILED",

  SET_KEYWORD_TITLE:"SET_KEYWORD_TITLE"
};
