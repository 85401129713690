import { constants } from "./constants";


export const getWelcomeDataList = (data) => ({
  type: constants.GET_SELLER_WELCOME_DATA_REQUEST,
  data,
});

export const getWelcomeDataSuccess = (data) => ({
  type: constants.GET_SELLER_WELCOME_DATA_SUCCESS,
  payload: data,
});

export const getWelcomeDataFailed = (error) => ({
  type: constants.GET_SELLER_WELCOME_DATA_FAILED,
  payload: error,
});

export const getChangeOnboardStatus = (data) => ({
  type: constants.GET_CHANGE_ONBOARD_STATUS_REQUEST,
  data,
});

export const getChangeOnboardStatusSuccess = (data) => ({
  type: constants.GET_CHANGE_ONBOARD_STATUS_SUCCESS,
  payload: data,
});

export const getChangeOnboardStatusFailed = (error) => ({
  type: constants.GET_CHANGE_ONBOARD_STATUS_FAILED,
  payload: error,
});

