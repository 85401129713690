import { constants } from "./constants";
import { toast } from "react-toastify";

const INIT_STATE = {
  keywordData: null,
  titleData: null,
  loading: false,
  pageCount: null,
  totalCount: null,
  keywordTitle: null,
  isLimitReached: false,
};
function KeywordReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case constants.SET_KEYWORD_TITLE:
      return {...state,keywordTitle:action.payload}
    case constants.GET_KEYWORD_REQUEST: // REQUEST
    return { ...state, keywordData: null, titleData: null, loading: true, isLimitReached:false };
    case constants.GET_TITLE_REQUEST:
      return { ...state, titleData: null, loading: true, isLimitReached:false };
    case constants.GET_KEYWORD_FAILED: //FAILED
    toast.error(action.payload);
      return {
        ...state,
        loading: false,
        keywordData: null,
        titleData: null,
      };
    case constants.GET_TITLE_FAILED:
      toast.error(action.payload);
      return {
        ...state,
        loading: false,
        // keywordData: null,
        titleData: null,
        planDetails: action?.payload?.data?.plan,
        isLimitReached: action.payload.limit_reached?action.payload.limit_reached : false,
      };

    case constants.GET_KEYWORD_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
        keywordData: action.payload.data,
      };
    case constants.GET_TITLE_SUCCESS:
      toast.success(action.payload.message);
      return {
        ...state,
        loading: false,
        titleData: action.payload.data,
      };
    default:
      return { ...state };
  }
}

export default KeywordReducer;
