import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  getDealSuggestionsFailed,
  getDealSuggestionsSuccess,
  fetchProductFailed,
  fetchProductSuccess,
  getDealDetailsFailed,
  getDealDetailsSuccess,
  rejectSuggestedDealFailed,
  rejectSuggestedDealSuccess,
  createSuggestedDealFailed,
  createSuggestedDealSuccess,
  sortSuggestedDealSuccess,
  sortSuggestedDealFailed,
  editSuggestDealSuccess,
  editSuggestDealFailed,
} from "./action";

function* getDealSuggestions({ data }) {
  let options = getProtectedOptions(endpoints.getDealSuggestions, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getDealSuggestionsSuccess(response.data));
    } else {
      yield put(getDealSuggestionsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getDealSuggestionsFailed(error || "Error not found!"));
  }
}

function* fetchProduct({ data }) {
  let options = getProtectedOptions(endpoints.fetchProduct, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchProductSuccess(response.data));
    } else {
      yield put(fetchProductFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchProductFailed(error || "Error not found!"));
  }
}


function* getDealDetails({ data }) {
  let options = getProtectedOptions(endpoints.getDealDetails, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getDealDetailsSuccess(response.data));
    } else {
      yield put(getDealDetailsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getDealDetailsFailed(error || "Error not found!"));
  }
}

function* rejectSuggestedDeal({ data }) {
  let options = getProtectedOptions(endpoints.rejectSuggestedDeal, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(rejectSuggestedDealSuccess(response.data));
    } else {
      yield put(rejectSuggestedDealFailed(response.data.message));
    }
  } catch (error) {
    yield put(rejectSuggestedDealFailed(error || "Error not found!"));
  }
}

function* createSuggestedDeal({ data }) {
  let options = getProtectedOptions(endpoints.createSuggestedDeal, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(createSuggestedDealSuccess(response.data));
    } else {
      yield put(createSuggestedDealFailed(response.data.message));
    }
  } catch (error) {
    yield put(createSuggestedDealFailed(error || "Error not found!"));
  }
}

// FOR SORT
function* sortSuggestion({ data }) {
  let options = getProtectedOptions(endpoints.sortSuggestion, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sortSuggestedDealSuccess(response.data));
    } else {
      yield put(sortSuggestedDealFailed(response.data.message));
    }
  } catch (error) {
    yield put(sortSuggestedDealFailed(error || "Error not found!"));
  }
}

// FOR Edit Suggest Deal
function* editSuggestDeal({ data }) {
  let options = getProtectedOptions(endpoints.editDealSuggestions, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editSuggestDealSuccess(response.data));
    } else {
      yield put(editSuggestDealFailed(response.data.message));
    }
  } catch (error) {
    yield put(editSuggestDealFailed(error || "Error not found!"));
  }
}


//Watcher

export function* watchsortDealSuggestions() {
  yield takeLatest(constants.SORT_SUGGESTED_DEAL_REQUEST, sortSuggestion);
}

export function* watchgetDealSuggestions() {
  yield takeLatest(constants.GET_DEAL_SUGGESTIONS_REQUEST, getDealSuggestions);
}
export function* watchfetchProduct() {
  yield takeLatest(constants.FETCH_PRODUCT_REQUEST, fetchProduct);
}

export function* watchgetDealDetails() {
  yield takeLatest(constants.GET_DEAL_DETAILS_REQUEST, getDealDetails);
}
export function* watchrejectSuggestedDeal() {
  yield takeLatest(constants.REJECT_DEAL_REQUEST, rejectSuggestedDeal);
}
export function* watchcreateSuggestedDeal() {
  yield takeLatest(constants.CREATE_SUGGESTED_DEAL_REQUEST, createSuggestedDeal);
}
export function* watcheditSuggestDeal() {
  yield takeLatest(constants.EDIT_SUGGESTED_DEAL_REQUEST, editSuggestDeal);
}
function* DealSuggestionsSaga() {
  yield all([
    watchcreateSuggestedDeal(),
    watchrejectSuggestedDeal(),
    watchgetDealDetails(),
    watchfetchProduct(),
    watchgetDealSuggestions(),
    watchsortDealSuggestions(),
    watcheditSuggestDeal()
  ]);
}

export default DealSuggestionsSaga;
