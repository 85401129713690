export const constants = {
  GET_VIDEO_REQUEST: "GET_VIDEO_REQUEST",
  GET_VIDEO_SUCCESS: "GET_VIDEO_SUCCESS",
  GET_VIDEO_FAILED: "GET_VIDEO_FAILED",

  GET_VOICE_REQUEST: "GET_VOICE_REQUEST",
  GET_VOICE_SUCCESS: "GET_VOICE_SUCCESS",
  GET_VOICE_FAILED: "GET_VOICE_FAILED",

  EDIT_VOICE_REQUEST: "EDIT_VOICE_REQUEST",
  EDIT_VOICE_SUCCESS: "EDIT_VOICE_SUCCESS",
  EDIT_VOICE_FAILED: "EDIT_VOICE_FAILED",

  FETCH_VIDEO_REQUEST: "FETCH_VIDEO_REQUEST",
  FETCH_VIDEO_SUCCESS: "FETCH_VIDEO_SUCCESS",
  FETCH_VIDEO_FAILED: "FETCH_VIDEO_FAILED",

  FETCH_ALL_VIDEO_REQUEST: "FETCH_ALL_VIDEO_REQUEST",
  FETCH_ALL_VIDEO_SUCCESS: "FETCH_ALL_VIDEO_SUCCESS",
  FETCH_ALL_VIDEO_FAILED: "FETCH_ALL_VIDEO_FAILED",

  DELETE_VIDEO_REQUEST: "DELETE_VIDEO_REQUEST",
  DELETE_VIDEO_SUCCESS: "DELETE_VIDEO_SUCCESS",
  DELETE_VIDEO_FAILED: "DELETE_VIDEO_FAILED",

  DOWNLOAD_VIDEO_REQUEST: "DOWNLOAD_VIDEO_REQUEST",
  DOWNLOAD_VIDEO_SUCCESS: "DOWNLOAD_VIDEO_SUCCESS",
  DOWNLOAD_VIDEO_FAILED: "DOWNLOAD_VIDEO_FAILED",

  VIDEO_PAYMENT_REQUEST: "VIDEO_PAYMENT_REQUEST",
  VIDEO_PAYMENT_SUCCESS: "VIDEO_PAYMENT_SUCCESS",
  VIDEO_PAYMENT_FAILED: "VIDEO_PAYMENT_FAILED",

  PAYMENT_STATUS_UPDATE:"PAYMENT_STATUS_UPDATE"
};
