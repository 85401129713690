import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../helpers/api";
import {
  sellerDealsSuccess,
  sellerDealsFailed,
  editSellerDealSuccess,
  editSellerDealFailed,
  getProductListSuccess,
  getProductListFailed,
  createSellerDealsFailed,
  createSellerDealsSuccess,
  searchSellerDealSuccess,
  searchSellerDealFailed,
  sortSellerDealSuccess,
  sortSellerDealFailed,
  createSellerBulkDealsFailed,
  createSellerBulkDealsSuccess,
} from "./actions";

function* sellerDeals({ params }) {
  let options = getProtectedOptions(endpoints.sellerDeals, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sellerDealsSuccess(response.data));
    } else {
      yield put(sellerDealsFailed(response.data.message));
    }
  } catch (error) {
    yield put(sellerDealsFailed(error || "Error not found!"));
  }
}
function* createSellerDeals({ params }) {
  let options = getProtectedOptions(
    endpoints.createSellerDeals,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(createSellerDealsSuccess(response.data));
    } else {
      yield put(createSellerDealsFailed(response.data.message));
    }
  } catch (error) {
    yield put(createSellerDealsFailed(error || "Error not found!"));
  }
}
function* getProductList({ params }) {
  let options = getProtectedOptions(
    endpoints.sellerDealsDropdown,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getProductListSuccess(response.data));
    } else {
      yield put(getProductListFailed(response.data.message));
    }
  } catch (error) {
    yield put(getProductListFailed(error || "Error not found!"));
  }
}
function* createSellerBulkDeals({ params }) {
  let options = getProtectedOptions(
    endpoints.createSellerBulkDeals,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(createSellerBulkDealsSuccess(response.data));
    } else {
      yield put(createSellerBulkDealsFailed(response.data.message));
    }
  } catch (error) {
    yield put(createSellerBulkDealsFailed(error || "Error not found!"));
  }
}
function* editSellerDeal({ params }) {
  let options = getProtectedOptions(endpoints.editSellerDeal, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editSellerDealSuccess(response.data));
    } else {
      yield put(editSellerDealFailed(response.data.message));
    }
  } catch (error) {
    yield put(editSellerDealFailed(error || "Error not found!"));
  }
}
function* searchSellerDeal({ params }) {
  let options = getProtectedOptions(endpoints.searchSellerDeal, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(searchSellerDealSuccess(response.data));
    } else {
      yield put(searchSellerDealFailed(response.data.message));
    }
  } catch (error) {
    yield put(searchSellerDealFailed(error || "Error not found!"));
  }
}
function* sortSellerDeal({ params }) {
  let options = getProtectedOptions(endpoints.sortSellerDeal, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sortSellerDealSuccess(response.data));
    } else {
      yield put(sortSellerDealFailed(response.data.message));
    }
  } catch (error) {
    yield put(sortSellerDealFailed(error || "Error not found!"));
  }
}
//Watcher
export function* watchsellerDeals() {
  yield takeLatest(constants.GET_SELLER_DEALS_REQUEST, sellerDeals);
}
export function* watchgetProductList() {
  yield takeLatest(constants.GET_DEALS_PRODUCT_LIST_REQUEST, getProductList);
}
export function* watchcreateSellerDeals() {
  yield takeLatest(constants.CREATE_SELLER_DEALS_REQUEST, createSellerDeals);
}
export function* watchcreateSellerBulkDeals() {
  yield takeLatest(
    constants.CREATE_SELLER_BULK_DEALS_REQUEST,
    createSellerBulkDeals
  );
}
export function* watcheditSellerDeal() {
  yield takeLatest(constants.EDIT_SELLER_DEAL_REQUEST, editSellerDeal);
}
export function* watchsearchSellerDeal() {
  yield takeLatest(constants.SEARCH_SELLER_DEAL_REQUEST, searchSellerDeal);
}
export function* watchsortSellerDeal() {
  yield takeLatest(constants.SORT_SELLER_DEAL_REQUEST, sortSellerDeal);
}
function* dealsManagementSaga() {
  yield all([
    watchsellerDeals(),
    watchgetProductList(),
    watchsortSellerDeal(),
    watcheditSellerDeal(),
    watchsearchSellerDeal(),
    watchcreateSellerDeals(),
    watchcreateSellerBulkDeals(),
  ]);
}

export default dealsManagementSaga;
