import Routes from "./routes/Routes";
import { ToastContainer } from "react-toastify" ;
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <>
        <ToastContainer
          style={{ zIndex: 99999 }}
          toastClassName={({ type }) =>
            `alert d-flex ${
              (type === "success" && "alert-success") || (type === "warning" && "alert-warning-toaster") || "alert alert-danger"
            }`
          }
        />
        <Routes />
      </>
    </div>
  );
}

export default App;
