import { constants } from "./constants";

const INIT_STATE = {
  currency: [],
  loading: false,
  revenueData: [],
  totalSellers: null,
  errorMessage: null,
  subscriptionData: [],
  successMessage: null,
  errorMessageRevenue: null,
  successMessageRevenue: null,
};

const AdminDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
      case constants.GET_ADMIN_REVENUE_GRAPH_REQUEST: // REQUEST
      case constants.GET_ADMIN_SUBSCRIPTION_GRAPH_REQUEST:
      return { ...state, loading: true };

    case constants.GET_ADMIN_SUBSCRIPTION_GRAPH_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };

    case constants.GET_ADMIN_SUBSCRIPTION_GRAPH_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        errorMessage: null,
        subscriptionData: action.payload.data,
        totalSellers: action.payload.total_seller,
      };

    case constants.GET_ADMIN_REVENUE_GRAPH_FAILED: //FAILED
      return {
        ...state,
        errorMessageRevenue: action.payload,
        loading: false,
      };

    case constants.GET_ADMIN_REVENUE_GRAPH_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        errorMessageRevenue: null,
        revenueData: action.payload.data,
        currency: action.payload.Currency,
      };

    default:
      return { ...state };
  }
};

export default AdminDashboard;
