import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  pageCount: null,
  couponList: [],
  errorMessage: null,
  subscriptionPlan: [],
  successMessage: null,
};

const AdminSubscription = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_ADMIN_COUPON_MESSAGE: //RESET MSGS
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };

    case constants.GET_ADMIN_COUPON_REQUEST: // REQUEST
    case constants.ADD_ADMIN_COUPON_REQUEST:
    case constants.EDIT_ADMIN_COUPON_REQUEST:
    case constants.GET_ADMIN_SUBSCRIPTIONS_REQUEST:
      return { ...state, loading: true };

    case constants.GET_ADMIN_COUPON_FAILED: //FAILED
    case constants.ADD_ADMIN_COUPON_FAILED:
    case constants.EDIT_ADMIN_COUPON_FAILED:
    case constants.GET_ADMIN_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };

    case constants.GET_ADMIN_COUPON_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        errorMessage: null,
        couponList: action.payload.data,
        pageCount: action.payload.total_pages,
      };
    case constants.ADD_ADMIN_COUPON_SUCCESS:
    case constants.EDIT_ADMIN_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        errorMessage: null,
      };
    case constants.GET_ADMIN_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionPlan: action.payload,
        errorMessage: null,
      };

    default:
      return { ...state };
  }
};

export default AdminSubscription;
