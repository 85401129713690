import { constants } from "./constants";
export const getDealsByAdmin = (data) => ({
  type: constants.GET_DEALS_BY_ADMIN_REQUEST,
  data,
});

export const getDealsByAdminSuccess = (data) => ({
  type: constants.GET_DEALS_BY_ADMIN_SUCCESS,
  payload: data,
});

export const getDealsByAdminFailed = (error) => ({
  type: constants.GET_DEALS_BY_ADMIN_FAILED,
  payload: error,
});

export const searchDealsByAdmin = (data) => ({
  type: constants.SEARCH_DEALS_BY_ADMIN_REQUEST,
  data,
});

export const searchDealsByAdminSuccess = (data) => ({
  type: constants.SEARCH_DEALS_BY_ADMIN_SUCCESS,
  payload: data,
});

export const searchDealsByAdminFailed = (error) => ({
  type: constants.SEARCH_DEALS_BY_ADMIN_FAILED,
  payload: error,
});


export const editDealsByAdmin = (data) => ({
  type: constants.EDIT_DEALS_BY_ADMIN_REQUEST,
  data,
});

export const editDealsByAdminSuccess = (data) => ({
  type: constants.EDIT_DEALS_BY_ADMIN_SUCCESS,
  payload: data,
});

export const editDealsByAdminFailed = (error) => ({
  type: constants.EDIT_DEALS_BY_ADMIN_FAILED,
  payload: error,
});

export const createDealsByAdmin = (data) => ({
  type: constants.CREATE_DEALS_BY_ADMIN_REQUEST,
  data,
});

export const createDealsByAdminSuccess = (data) => ({
  type: constants.CREATE_DEALS_BY_ADMIN_SUCCESS,
  payload: data,
});

export const createDealsByAdminFailed = (error) => ({
  type: constants.CREATE_DEALS_BY_ADMIN_FAILED,
  payload: error,
});

export const sortDealsByAdmin = (data) => ({
  type: constants.SORT_DEALS_BY_ADMIN_REQUEST,
  data,
});

export const sortDealsByAdminSuccess = (data) => ({
  type: constants.SORT_DEALS_BY_ADMIN_SUCCESS,
  payload: data,
});

export const sortDealsByAdminFailed = (error) => ({
  type: constants.SORT_DEALS_BY_ADMIN_FAILED,
  payload: error,
});

export const sellerProductsList = (data) => ({
  type: constants. SELLER_PRODUCT_LIST_REQUEST,
  data,
});

export const sellerProductsListSuccess = (data) => ({
  type: constants.SELLER_PRODUCT_LIST_SUCCESS,
  payload: data,
});

export const sellerProductsListFailed = (error) => ({
  type: constants.SELLER_PRODUCT_LIST_FAILED,
  payload: error,
});
