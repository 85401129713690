import { constants } from "./constants";

export const getEnquiry = (data) => ({
  type: constants.GET_ADMIN_ENQUIRY_REQUEST,
  data,
});

export const getEnquirySuccess = (data) => ({
  type: constants.GET_ADMIN_ENQUIRY_SUCCESS,
  payload: data,
});

export const getEnquiryFailed = (error) => ({
  type: constants.GET_ADMIN_ENQUIRY_FAILED,
  payload: error,
});
