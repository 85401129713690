import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import { getEnquiryFailed, getEnquirySuccess } from "./action";

function* getEnquiryList({ data }) {
  let options = getProtectedOptions(endpoints.getAdminEnquiry, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getEnquirySuccess(response.data));
    } else {
      yield put(getEnquiryFailed(response.data.message));
    }
  } catch (error) {
    yield put(getEnquiryFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetEnquiry() {
  yield takeLatest(constants.GET_ADMIN_ENQUIRY_REQUEST, getEnquiryList);
}

function* customerSaga() {
  yield all([watchGetEnquiry()]);
}

export default customerSaga;
