import { constants } from "./constants";
import { toast } from "react-toastify";

const INIT_STATE = {
  loading: false,
  listLoading: false,
  asinSellerLoading: false,
  pageCount: null,
  competitorSpyList: [],
  graphData: [],
  spyData: [],
  sellerIds: [],
  competitorData: [],
  deleteCategoryData: null,
  categorySpyErrorMessage: null,
  fetchCompetitorData: null,
  asinToSellerData: null,
  total_count: null,
  competitorDataCount:null,
  total_pages: null,
  addSellerError: null,
  refreshCompetitorTable:false,
  isLimitReached: false,
  planDetails:null,
};

const CompetitorSpy = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.GET_SELLER_SPY_REQUEST: // REQUEST
      return {
        ...state,
        listLoading: false,
        loading: true,
        categorySpyErrorMessage: null,
        asinToSellerData:null
      };
    case constants.ASIN_TO_SELLER_REQUEST:
      return { ...state, asinSellerLoading: true, loading: false, sellerIds: null,asinToSellerData:null };
    case constants.ADD_SELLER_ID_REQUEST:
      return {
        ...state,
        listLoading: true,
        loading: false,
        spyData: null,
        // competitorSpyList: null,
        sellerIds: null,
        refreshCompetitorTable:false,
        isLimitReached: false,
        planDetails:null,
      };
    case constants.FETCH_COMPETITOR_DATA_REQUEST:
      return { ...state, listLoading:true, asinToSellerData:null };
    case constants.CLEAR_COMPETITOR_LIST:
    case constants.DELETE_SELLER_SPY_REQUEST:
      return{...state, listLoading:true,refreshCompetitorTable:false,competitorSpyList:null}
    case constants.FETCH_SELLER_ID_REQUEST:
      return { ...state };

    case constants.GET_SELLER_SPY_FAILED: // FAILED
      toast.error(action.payload);
      return {
        ...state,
        // analyticsErrorMessage: action.payload,
        loading: false,
        allCategory: null,
        spyData: null,
        competitorSpyList: null,
        sellerIds: null,
        categorySpyErrorMessage: action.payload,
      };
    case constants.FETCH_COMPETITOR_DATA_FAILED:
    case constants.DELETE_SELLER_SPY_FAILED:
    case constants.ASIN_TO_SELLER_FAILED:
      toast.error(action.payload);
      return {
        ...state,
        // analyticsErrorMessage: action.payload,
        loading: false,
        asinSellerLoading: false,
        allCategory: null,
        spyData: null,
        // competitorSpyList: null,
        sellerIds: null,
        // categorySpyErrorMessage: action.payload,
      };
    case constants.ADD_SELLER_ID_FAILED:
      toast.error(action.payload);
      return {
        ...state,
        // analyticsErrorMessage: action.payload,
        loading: false,
        listLoading: false,
        allCategory: null,
        spyData: null,
        // competitorSpyList: null,
        sellerIds: null,
        addSellerError: action.payload,
        planDetails: action?.payload?.data?.plan,
        isLimitReached: action.payload?.limit_reached ? action.payload?.limit_reached : false,
      };

    case constants.GET_SELLER_SPY_SUCCESS:
      return {
        ...state,
        loading: false,
        listLoading: false,
        total_count: action.payload.count,
        competitorSpyList: action.payload.data,
      };
    case constants.FETCH_COMPETITOR_DATA_SUCCESS:
      return {
        ...state,
        listLoading: false,
        competitorData: action.payload.data,
        competitorDataCount: action.payload.total_count
      };
    case constants.ADD_SELLER_ID_SUCCESS:
      toast?.success(action.payload.message);
      return {
        ...state,
        loading: false,
        listLoading: false,
        // spyData: action.payload.data[0].product,
        sellerIds: action.payload.data,
        refreshCompetitorTable:action.payload.status
      };

    case constants.DELETE_SELLER_SPY_SUCCESS:
      toast?.success(action.payload.message);
      return {
        ...state,
        listLoading: false,
        deleteCategoryData: action.payload.data,
        refreshCompetitorTable:action.payload.status
      };
    case constants.FETCH_SELLER_ID_SUCCESS:
      return {
        ...state,
        listLoading: false,
        fetchCompetitorData: action.payload.data,
      };
    case constants.ASIN_TO_SELLER_SUCCESS:
      toast?.success(action.payload.message);
      return {
        ...state,
        asinSellerLoading: false,
        asinToSellerData: action.payload.data,
        fetchCompetitorData: null,
      };
    
      case constants.FETCH_ASIN_COUNT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case constants.FETCH_ASIN_COUNT_SUCCESS:
        return {
          ...state,
          loading: false,
          graphData: action.payload.data,
        };
      case constants.FETCH_ASIN_COUNT_FAILED:
        return {
          ...state,
          loading: false,
          graphData: [],
        };
    default:
      return { ...state };
  }
};

export default CompetitorSpy;
