import { constants } from "./constants";

const INIT_STATE = {
    loading: false,
    pageCount: null,
    errorMessage: null,
    successMessage: null,
    reviewList: []
};

const Review = (state = INIT_STATE, action) => {
    switch (action.type) {

        case constants.GET_REVIEW_LIST_REQUEST: // REQUEST
            return { ...state, loading: true };

        case constants.GET_REVIEW_LIST_FAILED:     //FAILED
            return {
                ...state,
                errorMessage: action.payload,
                loading: false
            };

        case constants.GET_REVIEW_LIST_SUCCESS:          //SUCCESS
            return {
                ...state,
                loading: false,
                reviewList: action.payload.data,
                pageCount: action.payload.total_pages,
            };

        default:
            return { ...state };
    }
};

export default Review;
