export const constants = {
  RESET_ADMIN_SUBSCRIPTION_GRAPH_MESSAGE:
    "RESET_ADMIN_SUBSCRIPTION_GRAPH_MESSAGE",

  GET_ADMIN_SUBSCRIPTION_GRAPH_REQUEST: "GET_ADMIN_SUBSCRIPTION_GRAPH_REQUEST",
  GET_ADMIN_SUBSCRIPTION_GRAPH_SUCCESS: "GET_ADMIN_SUBSCRIPTION_GRAPH_SUCCESS",
  GET_ADMIN_SUBSCRIPTION_GRAPH_FAILED: "GET_ADMIN_SUBSCRIPTION_GRAPH_FAILED",

  GET_ADMIN_REVENUE_GRAPH_REQUEST: "GET_ADMIN_REVENUE_GRAPH_REQUEST",
  GET_ADMIN_REVENUE_GRAPH_SUCCESS: "GET_ADMIN_REVENUE_GRAPH_SUCCESS",
  GET_ADMIN_REVENUE_GRAPH_FAILED: "GET_ADMIN_REVENUE_GRAPH_FAILED",
};
