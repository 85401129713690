import { constants } from "./constants";

export const getSubscriptionGraph = (data) => ({
    type: constants.GET_ADMIN_SUBSCRIPTION_GRAPH_REQUEST,
    data
});
export const getSubscriptionGraphSuccess = (data) => ({
    type: constants.GET_ADMIN_SUBSCRIPTION_GRAPH_SUCCESS,
    payload: data
});

export const getSubscriptionGraphFailed = error => ({
    type: constants.GET_ADMIN_SUBSCRIPTION_GRAPH_FAILED,
    payload: error
});

export const getRevenueGraph = (data) => ({
    type: constants.GET_ADMIN_REVENUE_GRAPH_REQUEST,
    data
});
export const getRevenueGraphSuccess = (data) => ({
    type: constants.GET_ADMIN_REVENUE_GRAPH_SUCCESS,
    payload: data
});

export const getRevenueGraphFailed = error => ({
    type: constants.GET_ADMIN_REVENUE_GRAPH_FAILED,
    payload: error
});
