export const constants = {
  GET_SELLER_SPY_REQUEST: "GET_SELLER_SPY_REQUEST",
  GET_SELLER_SPY_SUCCESS: "GET_SELLER_SPY_SUCCESS",
  GET_SELLER_SPY_FAILED: "GET_SELLER_SPY_FAILED",

  DELETE_SELLER_SPY_REQUEST: "DELETE_SELLER_SPY_REQUEST",
  DELETE_SELLER_SPY_SUCCESS: "DELETE_SELLER_SPY_SUCCESS",
  DELETE_SELLER_SPY_FAILED: "DELETE_SELLER_SPY_FAILED",

  ADD_SELLER_ID_REQUEST: "ADD_SELLER_ID_REQUEST",
  ADD_SELLER_ID_SUCCESS: "ADD_SELLER_ID_SUCCESS",
  ADD_SELLER_ID_FAILED: "ADD_SELLER_ID_FAILED",

  FETCH_SELLER_ID_REQUEST: "FETCH_SELLER_ID_REQUEST",
  FETCH_SELLER_ID_SUCCESS: "FETCH_SELLER_ID_SUCCESS",
  FETCH_SELLER_ID_FAILED: "FETCH_SELLER_ID_FAILED",

  FETCH_COMPETITOR_DATA_REQUEST: "FETCH_COMPETITOR_DATA_REQUEST",
  FETCH_COMPETITOR_DATA_SUCCESS: "FETCH_COMPETITOR_DATA_SUCCESS",
  FETCH_COMPETITOR_DATA_FAILED: "FETCH_COMPETITOR_DATA_FAILED",

  ASIN_TO_SELLER_REQUEST: "ASIN_TO_SELLER_REQUEST",
  ASIN_TO_SELLER_SUCCESS: "ASIN_TO_SELLER_SUCCESS",
  ASIN_TO_SELLER_FAILED: "ASIN_TO_SELLER_FAILED",

  CLEAR_COMPETITOR_LIST:"CLEAR_COMPETITOR_LIST",

  FETCH_ASIN_COUNT_REQUEST: 'FETCH_ASIN_COUNT_REQUEST',
  FETCH_ASIN_COUNT_SUCCESS: 'FETCH_ASIN_COUNT_SUCCESS',
  FETCH_ASIN_COUNT_FAILED: 'FETCH_ASIN_COUNT_FAILED',
};
