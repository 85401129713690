/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import Loadable from "react-loadable";
import ReactGA from "react-ga";

import { getLoggedInUser } from "../helpers/authUtils";
import LoaderSpinner from "../components/common/Loader";
import ErrorBoundary from "../components/common/ErrorBoundary";
import { getCheckSellerStatus } from "../containers/OnBoarding/actions";
import FbaModal from "../components/Monitoring/modals/FbaModal";


const loggedUser = getLoggedInUser();

ReactGA.initialize("UA-218477726-1");

const VerticalLayout = Loadable({
  loader: () => import("../layout/Vertical"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading: LoaderSpinner,
});

const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    if (loggedUser?.user?.userType === 1) {
      ReactGA.set({ userId: loggedUser?.user?.userId });
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
    if (!loggedUser?.user?.userType) {
      ReactGA.set({ userId: null });
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
    //ReactGA.set({ page: location.pathname });
  }, [location.pathname]);
};
const PrivateRoute = ({
  getCheckSellerStatus,
  OnBoarding: { sellerStatus },
  component: Component,
  ...rest
}) => {
  let history = useHistory();
  const { fbaModal } = useSelector((state) => state.Products);
  
  useEffect(() => {
    const loggedUser = getLoggedInUser();
    const { roles } = rest;
    if (roles?.includes("PAYER")) {
      if (!loggedUser?.user?.isSubscribed) {
        // history.push('/home')
      }
    }
    if (loggedUser?.user?.userType === 2) {
      if (!window?.location?.pathname?.includes("admin")) {
        history.push("/admin/dashboard");
      }
    } else {
      if (window?.location?.pathname?.includes("/admin/")) {
        history.push("/404");
      }
    }
    loggedUser?.user?.userType === 1 &&
      getCheckSellerStatus({ amazon_id: loggedUser?.user?.amazonId });
  }, []);

  useEffect(() => {
    let status = sellerStatus;
    if (status) {
      const {
        dataFetchingCompleted,
        onBoardingCompleted,
        authorized,
        subscribed,
      } = status;
      if (loggedUser?.user?.userType === 1) {
        if (
          !authorized &&
          !subscribed &&
          !dataFetchingCompleted &&
          !onBoardingCompleted &&
          !window.location.search.includes("spapi_oauth_code" || "choosePlan")
        ) {
          history.push("/authorization");
        } else if (
          authorized &&
          !subscribed &&
          !dataFetchingCompleted &&
          !onBoardingCompleted
        ) {
          history.push("/subscriptions");
        } else if (authorized && subscribed && !onBoardingCompleted) {
          history.push("/welcomePage");
        } else if (authorized && !subscribed && onBoardingCompleted) {
          history.push("/subscriptions");
        }
      }
    }
  }, [sellerStatus]);
  usePageViews();

  return (
    <ErrorBoundary>
      <React.Fragment>
        {fbaModal && <FbaModal />}
        <VerticalLayout {...rest}>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </VerticalLayout>
      </React.Fragment>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
  OnBoarding: state.OnBoarding,
});

export default connect(mapStateToProps, { getCheckSellerStatus })(PrivateRoute);
