import CryptoJS from "crypto-js";

const setSession = (userResult) => {
  //USER SESSION
  const {
    data: { data, token, access_token, refresh, user_id },
  } = userResult;
  const userData = CryptoJS.AES.encrypt(
    JSON.stringify({
      user: {
        token,
        phone: data.phone,
        refreshToken: refresh,
        email: data.email || "",
        accessToken: access_token,
        sellerName: data.name || "User",
        amazonId: data.amazon_id || "",
        isSubscribed: data.is_subscribed,
        isAuthorized: data.is_authorized,
        userType: data.user_type || "",
        userId: user_id, //User
        isOnboardingCompleted: data.onboarding_completed_status,
      },
    }),
    "my-secret-key@123"
  ).toString();
  localStorage.setItem("user", userData);
};

const setUserStatus = (data) => {
  //USER SESSION
  const userStatus = CryptoJS.AES.encrypt(
    JSON.stringify({
        dataFetchingCompleted: data.data_fetching_completed_status,
        onBoardingCompleted: data.onboarding_completed_status,
        subscribed: data.is_subscribed,
        authorized: data.is_authorized,
    }),
    "my-secret-key@123"
  ).toString();
  localStorage.setItem("userStatus", userStatus);
};

const setAdminSession = (userResult) => {
  //ADMIN SESSION
  const { data } = userResult;
  const userData = CryptoJS.AES.encrypt(
    JSON.stringify({
      user: {
        id: data.id,
        userType: 2, //Admin
        token: data.token,
        phone: data.contact_no,
        email: data.email || "",
        refreshToken: data.refresh,
        accessToken: data.access_token,
        dealmanager:data.is_deal_manager,
        sellerName: `${data.first_name || "Admin"} ${data.last_name || ""}`,
      },
    }),
    "my-secret-key@123"
  ).toString();
  localStorage.setItem("user", userData);
};

const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    var bytes = CryptoJS.AES.decrypt(user, "my-secret-key@123");
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return typeof decryptedData == "object"
      ? decryptedData
      : JSON.parse(decryptedData);
  }
  return null;
};
const getUserStatus = () => {
  const user = localStorage.getItem("userStatus");
  if (user) {
    var bytes = CryptoJS.AES.decrypt(user, "my-secret-key@123");
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return typeof decryptedData == "object"
      ? decryptedData
      : JSON.parse(decryptedData);
  }
  return null;
};

const changeSession = (type, value) => {
  const { user } = getLoggedInUser();
  user[type] = value;
  const userData = CryptoJS.AES.encrypt(
    JSON.stringify({ user }),
    "my-secret-key@123"
  ).toString();
  localStorage.setItem("user", userData);
};

const clearSessionData = () => {
  let loggedInUser = getLoggedInUser();
  // window.location.href = "/login";
  localStorage.removeItem("user");
  localStorage.removeItem("userStatus");
  let redirectUrl = loggedInUser?.user?.userType === 2 ? "/admin" : "/";
  window.location.href = redirectUrl;
};

export {
  setSession,
  setUserStatus,
  changeSession,
  setAdminSession,
  getLoggedInUser,
  getUserStatus,
  clearSessionData,
};
