/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

// import history from "../../config/history";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    onClick = e => {
        e.preventDefault();
        // history.push("/home");
        window.location.reload();
    };
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, info);
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <main className="login-wrapper">
                    <div className="success-container text-center">
                        <div className="logo-container">
                            <img alt="" src="assets/images/success.png" />
                            <h3>Something went wrong. We are working on it.. </h3>
                            <a
                                href="javascript:void(0);"
                                onClick={this.onClick}
                                className="blue-prmary-btn"
                            >
                                Click Here to Refresh the page
              </a>
                        </div>
                    </div>
                </main>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
