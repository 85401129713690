import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import { allFlattenRoutes as routes } from "./index";

class Routes extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    {routes.map((route, index) => {
                        return !route.children ? (
                            <route.route
                                key={index}
                                path={route.path}
                                roles={route.roles}
                                exact={route.exact}
                                component={route.component}
                            ></route.route>
                        ) : null;
                    })}
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routes;
