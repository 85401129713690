import { constants } from "./constants";


export const getUserReport = (data) => ({
  type: constants.GET_USER_REPORT_REQUEST,
  data,
});

export const getUserReportSuccess = (data) => ({
  type: constants.GET_USER_REPORT_SUCCESS,
  payload: data,
});

export const getUserReportFailed = (error) => ({
  type: constants.GET_USER_REPORT_FAILED,
  payload: error,
});