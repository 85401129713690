import { constants } from "./constants";

export const getListingCategories = (params) => ({
  type: constants.GET_LISTING_CATEGORY_REQUEST,
  params,
});

export const getListingCategoriesSuccess = (report) => ({
  type: constants.GET_LISTING_CATEGORY_SUCCESS,
  payload: report,
});

export const getListingCategoriesFailed = (error) => ({
  type: constants.GET_LISTING_CATEGORY_FAILED,
  payload: error,
});

export const fetchCategoryTrends = (params) => ({
  type: constants.FETCH_CATEGORY_TRENDS_REQUEST,
  params,
});

export const fetchcategorytrendsSuccess = (report) => ({
  type: constants.FETCH_CATEGORY_TRENDS_SUCCESS,
  payload: report,
});

export const fetchcategorytrendsFailed = (error) => ({
  type: constants.FETCH_CATEGORY_TRENDS_FAILED,
  payload: error,
});

export const loadMoreContent = (params) => ({
  type: constants.LOAD_MORE_CONTENT_REQUEST,
  params,
});

export const loadMoreContentSuccess = (report) => ({
  type: constants.LOAD_MORE_CONTENT_SUCCESS,
  payload: report,
});

export const loadMoreContentFailed = (error) => ({
  type: constants.LOAD_MORE_CONTENT_FAILED,
  payload: error,
});
