import { constants } from "./constants";

export const getKeyword = (params) => ({
  type: constants.GET_KEYWORD_REQUEST,
  params,
});

export const getKeywordSuccess = (keyword) => ({
  type: constants.GET_KEYWORD_SUCCESS,
  payload: keyword,
});

export const getKeywordFailed = (error) => ({
  type: constants.GET_KEYWORD_FAILED,
  payload: error,
});
export const getTitle = (params) => ({
  type: constants.GET_TITLE_REQUEST,
  params,
});

export const getTitleSuccess = (title) => ({
  type: constants.GET_TITLE_SUCCESS,
  payload: title,
});

export const getTitleFailed = (error) => ({
  type: constants.GET_TITLE_FAILED,
  payload: error,
});

export const setKeywordTitle = (title) => ({
  type: constants.SET_KEYWORD_TITLE,
  payload: title,
});
