export const constants = {
  RESET_SETTINGS_MESSAGE: "RESET_SETTINGS_MESSAGE",

  GET_ADMIN_DETAILS_REQUEST: "GET_ADMIN_DETAILS_REQUEST",
  GET_ADMIN_DETAILS_SUCCESS: "GET_ADMIN_DETAILS_SUCCESS",
  GET_ADMIN_DETAILS_FAILED: "GET_ADMIN_DETAILS_FAILED",

  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILED: "UPLOAD_IMAGE_FAILED",

  UPLOAD_LOGO: "UPLOAD_LOGO",
  UPLOAD_LOGO_SUCCESS: "UPLOAD_LOGO_SUCCESS",
  UPLOAD_LOGO_FAILED: "UPLOAD_LOGO_FAILED",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",

  EDIT_PROFILE: "EDIT_PROFILE",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_FAILED: "EDIT_PROFILE_FAILED",
};
