export const constants = {
    GET_SUBSCRIPTION_PLANS_REQUEST: "GET_SUBSCRIPTION_PLANS_REQUEST",
    GET_SUBSCRIPTION_PLANS_SUCCESS: "GET_SUBSCRIPTION_PLANS_SUCCESS",
    GET_SUBSCRIPTION_PLANS_FAILED: "GET_SUBSCRIPTION_PLANS_FAILED",

    GET_SELLER_NOTIFICATION_TYPES_REQUEST: "GET_SELLER_NOTIFICATION_TYPES_REQUEST",
    GET_SELLER_NOTIFICATION_TYPES_SUCCESS: "GET_SELLER_NOTIFICATION_TYPES_SUCCESS",
    GET_SELLER_NOTIFICATION_TYPES_FAILED: "GET_SELLER_NOTIFICATION_TYPES_FAILED",

    CHANGE_NOTIFICATION_VALIDITY_REQUEST: "CHANGE_NOTIFICATION_VALIDITY_REQUEST",
    CHANGE_NOTIFICATION_VALIDITY_SUCCESS: "CHANGE_NOTIFICATION_VALIDITY_SUCCESS",
    CHANGE_NOTIFICATION_VALIDITY_FAILED: "CHANGE_NOTIFICATION_VALIDITY_FAILED",

    CHANGE_PHONE_REQUEST: "CHANGE_PHONE_REQUEST",
    CHANGE_PHONE_SUCCESS: "CHANGE_PHONE_SUCCESS",
    CHANGE_PHONE_FAILED: "CHANGE_PHONE_FAILED",

    GET_SELLER_ACCOUNT_REQUEST: "GET_SELLER_ACCOUNT_REQUEST",
    GET_SELLER_ACCOUNT_SUCCESS: "GET_SELLER_ACCOUNT_SUCCESS",
    GET_SELLER_ACCOUNT_FAILED: "GET_SELLER_ACCOUNT_FAILED",

    REMOVE_SELLER_REQUEST: "REMOVE_SELLER_REQUEST",
    REMOVE_SELLER_SUCCESS: "REMOVE_SELLER_SUCCESS",
    REMOVE_SELLER_FAILED: "REMOVE_SELLER_FAILED",

    PRODUCTS_ADD_REQUEST: "PRODUCTS_ADD_REQUEST",
    PRODUCTS_ADD_SUCCESS: "PRODUCTS_ADD_SUCCESS",
    PRODUCTS_ADD_FAILED: "PRODUCTS_ADD_FAILED",

    ADD_SUBSCRIPTION_PLAN_REQUEST: "ADD_SUBSCRIPTION_PLAN_REQUEST",
    ADD_SUBSCRIPTION_PLAN_SUCCESS: "ADD_SUBSCRIPTION_PLAN_SUCCESS",
    ADD_SUBSCRIPTION_PLAN_FAILED: "ADD_SUBSCRIPTION_PLAN_FAILED",

    AMAZON_PAY_BILLING_REQUEST: "AMAZON_PAY_BILLING_REQUEST",
    AMAZON_PAY_BILLING_SUCCESS: "AMAZON_PAY_BILLING_SUCCESS",
    AMAZON_PAY_BILLING_FAILED: "AMAZON_PAY_BILLING_FAILED",

    GET_SUBSCRIPTION_BY_ID_PLAN_REQUEST: "GET_SUBSCRIPTION_BY_ID_PLAN_REQUEST",
    GET_SUBSCRIPTION_BY_ID_PLAN_SUCCESS: "GET_SUBSCRIPTION_BY_ID_PLAN_SUCCESS",
    GET_SUBSCRIPTION_BY_ID_PLAN_FAILED: "GET_SUBSCRIPTION_BY_ID_PLAN_FAILED",

    GET_PAYMENT_HISTORY_REQUEST: "GET_PAYMENT_HISTORY_REQUEST",
    GET_PAYMENT_HISTORY_SUCCESS: "GET_PAYMENT_HISTORY_SUCCESS",
    GET_PAYMENT_HISTORY_FAILED: "GET_PAYMENT_HISTORY_FAILED",

    GET_PROFILE_DATA_REQUEST: "GET_PROFILE_DATA_REQUEST",
    GET_PROFILE_DATA_SUCCESS: "GET_PROFILE_DATA_SUCCESS",
    GET_PROFILE_DATA_FAILED: "GET_PROFILE_DATA_FAILED",

    CANCEL_SUBSCRIPTION_REQUEST: "CANCEL_SUBSCRIPTION_REQUEST",
    CANCEL_SUBSCRIPTION_SUCCESS: "CANCEL_SUBSCRIPTION_SUCCESS",
    CANCEL_SUBSCRIPTION_FAILED: "CANCEL_SUBSCRIPTION_FAILED",

    GET_ALERT_SETTINGS_REQUEST: "GET_ALERT_SETTINGS_REQUEST",
    GET_ALERT_SETTINGS_SUCCESS: "GET_ALERT_SETTINGS_SUCCESS",
    GET_ALERT_SETTINGS_FAILED: "GET_ALERT_SETTINGS_FAILED",

    UPDATE_ALERT_SETTINGS_REQUEST: "UPDATE_ALERT_SETTINGS_REQUEST",
    UPDATE_ALERT_SETTINGS_SUCCESS: "UPDATE_ALERT_SETTINGS_SUCCESS",
    UPDATE_ALERT_SETTINGS_FAILED: "UPDATE_ALERT_SETTINGS_FAILED",

    CHECK_COUPON_REQUEST: "CHECK_COUPON_REQUEST",
    CHECK_COUPON_SUCCESS: "CHECK_COUPON_SUCCESS",
    CHECK_COUPON_FAILED: "CHECK_COUPON_FAILED",

    GET_USER_SUBSCRIPTION_PLANS_REQUEST: "GET_USER_SUBSCRIPTION_PLANS_REQUEST",

    RESET_SETTINGS_STATE: "RESET_SETTINGS_STATE",
    RESET_SETTINGS_MESSAGE: "RESET_SETTINGS_MESSAGE",

    FEEDBACK_VIEW_REQUEST: " FEEDBACK_VIEW_REQUEST",
    FEEDBACK_VIEW_SUCCESS: " FEEDBACK_VIEW_SUCCESS",
    FEEDBACK_VIEW_FAILED: " FEEDBACK_VIEW_FAILED",

    FETCH_INITIAL_DATA_REQUEST: " FETCH_INITIAL_DATA_REQUEST",
    FETCH_INITIAL_DATA_SUCCESS: " FETCH_INITIAL_DATA_SUCCESS",
    FETCH_INITIAL_DATA_FAILED: " FETCH_INITIAL_DATA_FAILED",

    CHANGE_SUBSCRIPTION_PLAN_REQUEST: " CHANGE_SUBSCRIPTION_PLAN_REQUEST",
    CHANGE_SUBSCRIPTION_PLAN_SUCCESS: " CHANGE_SUBSCRIPTION_PLAN_SUCCESS",
    CHANGE_SUBSCRIPTION_PLAN_FAILED: " CHANGE_SUBSCRIPTION_PLAN_FAILED",
}