import { constants } from "./constants";

export const resetProductsMessages = () => ({
    type: constants.RESET_PRODUCTS_MESSAGE
});

export const getProductList = (params) => ({
    type: constants.GET_PRODUCT_LIST_REQUEST,
    params
});

export const getProductListSuccess = (products) => ({
    type: constants.GET_PRODUCT_LIST_SUCCESS,
    payload: products
});

export const getProductListFailed = error => ({
    type: constants.GET_PRODUCT_LIST_FAILED,
    payload: error
});

export const getProductInfo = (params) => ({
    type: constants.GET_PRODUCT_INFO_REQUEST,
    params
});

export const getProductInfoSuccess = (products) => ({
    type: constants.GET_PRODUCT_INFO_SUCCESS,
    payload: products
});

export const getProductInfoFailed = error => ({
    type: constants.GET_PRODUCT_INFO_FAILED,
    payload: error
});

export const getProductFbaCalc = (params) => ({
    type: constants.GET_PRODUCT_FBA_CALC_REQUEST,
    params
});

export const getProductFbaCalcSuccess = (products) => ({
    type: constants.GET_PRODUCT_FBA_CALC_SUCCESS,
    payload: products
});

export const getProductFbaCalcFailed = error => ({
    type: constants.GET_PRODUCT_FBA_CALC_FAILED,
    payload: error
});
export const updateProduct = (params) => ({
    type: constants.UPDATE_PRODUCT_REQUEST,
    params
});

export const updateProductSuccess = (products) => ({
    type: constants.UPDATE_PRODUCT_SUCCESS,
    payload: products
});

export const updateProductFailed = error => ({
    type: constants.UPDATE_PRODUCT_FAILED,
    payload: error
});

export const getProductAlert = (params) => ({
    type: constants.GET_PRODUCT_ALERTS_REQUEST,
    params
});

export const getProductAlertSuccess = (alerts) => ({
    type: constants.GET_PRODUCT_ALERTS_SUCCESS,
    payload: alerts
});

export const getProductAlertFailed = error => ({
    type: constants.GET_PRODUCT_ALERTS_FAILED,
    payload: error
});

export const updateProductAlert = (params) => ({
    type: constants.UPDATE_PRODUCT_ALERTS_REQUEST,
    params
});

export const updateProductAlertSuccess = (products) => ({
    type: constants.UPDATE_PRODUCT_ALERTS_SUCCESS,
    payload: products
});

export const updateProductAlertFailed = error => ({
    type: constants.UPDATE_PRODUCT_ALERTS_FAILED,
    payload: error
});

export const activateProduct = (params) => ({
    type: constants.ACTIVATE_PRODUCT_REQUEST,
    params
});

export const activateProductSuccess = (products) => ({
    type: constants.ACTIVATE_PRODUCT_SUCCESS,
    payload: products
});

export const activateProductFailed = error => ({
    type: constants.ACTIVATE_PRODUCT_FAILED,
    payload: error
});

export const showFbaCalculator = (productAsin,productImg,productPrice) => ({
    type: constants.SHOW_FBA_CALCULATOR,
    productAsin,productImg,productPrice,
});

export const hideFbaCalculator = () => ({
    type: constants.HIDE_FBA_CALCULATOR,
});