import { constants } from "./constants";

const INIT_STATE = {
  dealsList: [],
  total_count:0,
  loading: false,
  pageCount: null,
  isDeleted: false,
  errorMessage: null,
  successMessage: null,
};

const SellerDeals = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_SELLER_DEALS_REPORT_REQUEST: // REQUEST
    case constants.DELETE_SELLER_DEALS_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
        isDeleted: false,
      };
      case constants.SEARCH_SELLER_DEALS_REQUEST: // REQUEST
      return {
        ...state,
        isDeleted: false,
      };

    case constants.GET_SELLER_DEALS_REPORT_FAILED: //FAILED
    case constants.DELETE_SELLER_DEALS_FAILED: // REQUEST
    case constants.SEARCH_SELLER_DEALS_FAILED: // REQUEST
      return {
        ...state,
        errorMessage: action.payload,
        isDeleted: false,
        loading: false,
      };

    case constants.GET_SELLER_DEALS_REPORT_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        isDeleted: false,
        errorMessage: null,
        dealsList: action.payload.deal_data,
        total_count:action.payload.total_count,
      };

    case constants.DELETE_SELLER_DEALS_SUCCESS: // REQUEST
      return {
        ...state,
        loading: false,
        errorMessage: null,
        isDeleted: action.payload.status ? action.payload.status : false,
        successMessage: action.payload.message,
        dealsList: action.payload.deal_data,
      };

    case constants.SEARCH_SELLER_DEALS_SUCCESS: // REQUEST
      return {
        ...state,
        loading: false,
        errorMessage: null,
        dealsList: action.payload.deal_data,
        total_count:action.payload.total_count,
      };

    default:
      return { ...state };
  }
};

export default SellerDeals;
