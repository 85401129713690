export const constants = {
  GET_SELLER_DEALS_REQUEST: "GET_SELLER_DEALS_REQUEST",
  GET_SELLER_DEALS_SUCCESS: "GET_SELLER_DEALS_SUCCESS",
  GET_SELLER_DEALS_FAILED: "GET_SELLER_DEALS_FAILED",

  CREATE_SELLER_DEALS_REQUEST: "CREATE_SELLER_DEALS_REQUEST",
  CREATE_SELLER_DEALS_SUCCESS: "CREATE_SELLER_DEALS_SUCCESS",
  CREATE_SELLER_DEALS_FAILED: "CREATE_SELLER_DEALS_FAILED",

  CREATE_SELLER_BULK_DEALS_REQUEST: "CREATE_SELLER_BULK_DEALS_REQUEST",
  CREATE_SELLER_BULK_DEALS_SUCCESS: "CREATE_SELLER_BULK_DEALS_SUCCESS",
  CREATE_SELLER_BULK_DEALS_FAILED: "CREATE_SELLER_BULK_DEALS_FAILED",

  GET_DEALS_PRODUCT_LIST_REQUEST: "GET_DEALS_PRODUCT_LIST_REQUEST",
  GET_DEALS_PRODUCT_LIST_SUCCESS: "GET_DEALS_PRODUCT_LIST_SUCCESS",
  GET_DEALS_PRODUCT_LIST_FAILED: "GET_DEALS_PRODUCT_LIST_FAILED",

  EDIT_SELLER_DEAL_REQUEST: "EDIT_SELLER_DEAL_REQUEST",
  EDIT_SELLER_DEAL_SUCCESS: "EDIT_SELLER_DEAL_SUCCESS",
  EDIT_SELLER_DEAL_FAILED: "EDIT_SELLER_DEAL_FAILED",

  SEARCH_SELLER_DEAL_REQUEST: "SEARCH_SELLER_DEAL_REQUEST",
  SEARCH_SELLER_DEAL_SUCCESS: "SEARCH_SELLER_DEAL_SUCCESS",
  SEARCH_SELLER_DEAL_FAILED: "SEARCH_SELLER_DEAL_FAILED",

  SORT_SELLER_DEAL_REQUEST: "SORT_SELLER_DEAL_REQUEST",
  SORT_SELLER_DEAL_SUCCESS: "SORT_SELLER_DEAL_SUCCESS",
  SORT_SELLER_DEAL_FAILED: "SORT_SELLER_DEAL_FAILED",
};
