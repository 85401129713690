import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  total_count: 0,
  pageCount: null,
  dashboardData: [],
  errorMessage: null,
  successMessage: null,
  fetchingStatus: false,
};

const SellerWelcome = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_SELLER_WELCOME_DATA_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
      };
    case constants.GET_CHANGE_ONBOARD_STATUS_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
        fetchingStatus: false,
      };

    case constants.GET_SELLER_WELCOME_DATA_FAILED: //FAILED
    case constants.GET_CHANGE_ONBOARD_STATUS_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,
        fetchingStatus: false,

        loading: false,
      };

    case constants.GET_CHANGE_ONBOARD_STATUS_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        fetchingStatus: true,
      };
    case constants.GET_SELLER_WELCOME_DATA_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        errorMessage: null,
        dashboardData: action.payload,
      };

    default:
      return { ...state };
  }
};

export default SellerWelcome;
