import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  allPlans: null,
  pageCount: null,
  sellerData: null,
  planCancel: null,
  couponData: null,
  phoneNumber: null,
  profileData: null,
  errorMessage: null,
  paymentHistory: null,
  paymentSuccess: null,
  successMessage: null,
  paymentDetail: null,
  notificationTypes: null,
  alertSettingsData: null,
  subscriptionComplete:null,
  activeSubscriptionPlan: {},
  settingsErrorMessage: null,
  sellerAccountDeleted: null,
  settingsSuccessMessage: null,
  feedbackViewList: [],
  adminLogo: null,
};

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_SETTINGS_MESSAGE: //RESET MSGS
      return {
        ...state,
        settingsSuccessMessage: null,
        settingsErrorMessage: null,
      };
    case constants.RESET_SETTINGS_STATE: //RESET STATE
      return INIT_STATE;

    // REQUEST
    case constants.CHANGE_PHONE_REQUEST:
    case constants.PRODUCTS_ADD_REQUEST:
    case constants.REMOVE_SELLER_REQUEST:
    case constants.GET_SELLER_ACCOUNT_REQUEST:
    case constants.AMAZON_PAY_BILLING_REQUEST:
    case constants.GET_PAYMENT_HISTORY_REQUEST:
    case constants.GET_SUBSCRIPTION_PLANS_REQUEST:
    case constants.GET_SUBSCRIPTION_BY_ID_PLAN_REQUEST:
    case constants.CHANGE_NOTIFICATION_VALIDITY_REQUEST:
    case constants.GET_SELLER_NOTIFICATION_TYPES_REQUEST:
    case constants.GET_PROFILE_DATA_REQUEST:
    case constants.CANCEL_SUBSCRIPTION_REQUEST:
    case constants.GET_ALERT_SETTINGS_REQUEST:
    case constants.UPDATE_ALERT_SETTINGS_REQUEST:
    case constants.CHECK_COUPON_REQUEST:
    case constants.GET_USER_SUBSCRIPTION_PLANS_REQUEST:
    case constants.FEEDBACK_VIEW_REQUEST:
    case constants.CHANGE_SUBSCRIPTION_PLAN_REQUEST:
      return { ...state, loading: true,successMessage: null, errorMessage:null };

    //FAILED
    case constants.CHANGE_PHONE_FAILED:
    case constants.PRODUCTS_ADD_FAILED:
    case constants.REMOVE_SELLER_FAILED:
    case constants.FEEDBACK_VIEW_FAILED:
    // case constants.GET_SELLER_ACCOUNT_FAILED:
    case constants.GET_ALERT_SETTINGS_FAILED:
    case constants.CANCEL_SUBSCRIPTION_FAILED:
    case constants.GET_SUBSCRIPTION_PLANS_FAILED:
    case constants.UPDATE_ALERT_SETTINGS_FAILED:
    case constants.CHANGE_NOTIFICATION_VALIDITY_FAILED:
    case constants.GET_SELLER_NOTIFICATION_TYPES_FAILED:
      return {
        ...state,
        settingsErrorMessage: action.payload,
        loading: false,
      };
    case constants.AMAZON_PAY_BILLING_FAILED:
    case constants.ADD_SUBSCRIPTION_PLAN_FAILED:
    case constants.CHANGE_SUBSCRIPTION_PLAN_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case constants.GET_SELLER_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        settingsSellerErrorMessage: action.payload,
      };

    case constants.CHECK_COUPON_FAILED:
      return {
        ...state,
        loading: false,
        couponData: null,
        settingsErrorMessage: action.payload,
      };

    case constants.GET_SUBSCRIPTION_BY_ID_PLAN_FAILED:
    case constants.GET_PAYMENT_HISTORY_FAILED:
      // case constants.GET_PROFILE_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_PROFILE_DATA_FAILED:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: action.payload,
      };

    case constants.GET_SUBSCRIPTION_PLANS_SUCCESS: //SUCCESS
      return {
        ...state,
        allPlans: action.payload,
        loading: false,
        settingsErrorMessage: null,
      };
    case constants.GET_SUBSCRIPTION_BY_ID_PLAN_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        activeSubscriptionPlan: action.payload.data,
      };
    case constants.ADD_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        successMessage: action.payload.message,
      };
    case constants.CHANGE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        subscriptionComplete: true,
        successMessage: action.payload,
      };
    case constants.GET_SELLER_NOTIFICATION_TYPES_SUCCESS:
      return {
        ...state,
        notificationTypes: action.payload,
        loading: false,
        settingsErrorMessage: null,
      };
    case constants.CHANGE_NOTIFICATION_VALIDITY_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        notificationTypes: action.payload.data,
        settingsSuccessMessage: action.payload.message,
      };
    case constants.CHANGE_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        phoneNumber: action.payload.data,
        settingsSuccessMessage: action.payload.message,
      };
    case constants.GET_SELLER_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        sellerAccountDeleted: false,
        sellerData: action.payload.data,
      };
    case constants.REMOVE_SELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        sellerData: null,
        settingsErrorMessage: null,
        sellerAccountDeleted: true,
        settingsSuccessMessage: action.payload.message,
      };
    case constants.PRODUCTS_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
      };
    case constants.AMAZON_PAY_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentSuccess: true,
        settingsErrorMessage: null,
        subscriptionComplete: true,
        paymentDetail: action.payload,
        successMessage: action.payload.message,
      };
    case constants.GET_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        paymentHistory: action.payload.data,
        pageCount: action.payload.total_pages,
      };
    case constants.GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: action.payload?.data[0] || {},
        settingsErrorMessage: null,
      };
    case constants.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        planCancel: true,
        settingsErrorMessage: null,
        settingsSuccessMessage: action.payload.message,
      };
    case constants.GET_ALERT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        alertSettingsData: action.payload?.data?.user_alert,
      };
    case constants.UPDATE_ALERT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        settingsSuccessMessage: action.payload.message,
        alertSettingsData: action.payload?.data?.user_alert,
      };
    case constants.CHECK_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        couponData: action.payload?.data,
        settingsSuccessMessage: action.payload.message,
      };

    case constants.FEEDBACK_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsErrorMessage: null,
        feedbackViewList: action.payload.data,
        adminLogo: action.payload.logo,
      };

    default:
      return { ...state };
  }
};

export default Settings;
