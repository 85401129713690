import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../helpers/api";
import {
  getWelcomeDataFailed,
  getWelcomeDataSuccess,
  getChangeOnboardStatusSuccess,
  getChangeOnboardStatusFailed,
} from "./actions";

function* getWelcomeDataList({ data }) {
  let options = getProtectedOptions(endpoints.welcomePage, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getWelcomeDataSuccess(response.data));
    } else {
      yield put(getWelcomeDataFailed(response.data.message));
    }
  } catch (error) {
    yield put(getWelcomeDataFailed(error || "Error not found!"));
  }
}
// CHECK STATUS
function* getChangeOnboardStatus({ data }) {
  let options = getProtectedOptions(endpoints.changeOnboard, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getChangeOnboardStatusSuccess(response.data));
    } else {
      yield put(getChangeOnboardStatusFailed(response.data.message));
    }
  } catch (error) {
    yield put(getChangeOnboardStatusFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchgetSellerWelcomePage() {
  yield takeLatest(
    constants.GET_SELLER_WELCOME_DATA_REQUEST,
    getWelcomeDataList
  );
}
export function* watchgetChangeOnboardStatus() {
  yield takeLatest(
    constants.GET_CHANGE_ONBOARD_STATUS_REQUEST,
    getChangeOnboardStatus
  );
}

function* SellerDealWelcomesaga() {
  yield all([watchgetSellerWelcomePage(), watchgetChangeOnboardStatus()]);
}

export default SellerDealWelcomesaga;
