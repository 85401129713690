import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  alertList: null,
  errorMessage: null,
  successMessage: null,
};

const AdminAlerts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_ADMIN_ALERTS_MESSAGE: //RESET MSGS
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };

    case constants.GET_ADMIN_ALERTS_REQUEST: // REQUEST
    case constants.UPDATE_ADMIN_ALERTS_REQUEST:
      return { ...state, loading: true };

    case constants.GET_ADMIN_ALERTS_FAILED: //FAILED
    case constants.UPDATE_ADMIN_ALERTS_FAILED:
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };

    case constants.GET_ADMIN_ALERTS_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        alertList: action.payload,
        errorMessage: null,
      };

    case constants.UPDATE_ADMIN_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        errorMessage: null,
      };

    default:
      return { ...state };
  }
};

export default AdminAlerts;
