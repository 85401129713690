import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../helpers/api";
import {
  getVideoSuccess,
  getVideoFailed,
  getVoiceSuccess,
  getVoiceFailed,
  editVoiceSuccess,
  editVoiceFailed,
  fetchAllVideoFailed,
  fetchAllVideoSuccess,
  deleteVideoFailed,
  deleteVideoSuccess,
  downloadVideoSuccess,
  downloadVideoFailed,
  videoPaymentFailed,
  videoPaymentSuccess
} from "./actions";
import { getQueryParams } from "../../helpers/structureData";

function* getVideo({ params }) {
  let options = getUnProtectedOptions(endpoints.getVideos, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getVideoSuccess(response.data));
    } else {
      yield put(getVideoFailed(response.data.message));
    }
  } catch (error) {
    yield put(getVideoFailed(error || "Error not found!"));
  }
}

function* deleteVideo({ params }) {
  let options = getUnProtectedOptions(endpoints.deleteAiVideos, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteVideoSuccess(response.data));
    } else {
      yield put(deleteVideoFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteVideoFailed(error || "Error not found!"));
  }
}

function* downloadVideo({ params }) {
  let options = getUnProtectedOptions(endpoints.downloadAiVideos, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(downloadVideoSuccess(response.data));
    } else {
      yield put(downloadVideoFailed(response.data.message));
    }
  } catch (error) {
    yield put(downloadVideoFailed(error || "Error not found!"));
  }
}

function* getVoice({ params }) {
  let options = getUnProtectedOptions(endpoints.getVoices, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getVoiceSuccess(response.data));
    } else {
      yield put(getVoiceFailed(response.data));
    }
  } catch (error) {
    yield put(getVoiceFailed(error || "Error not found!"));
  }
}

function* fetchVideo({ params }) {
  console.log(params);
  let options = getUnProtectedOptions(
    `${endpoints.fetchVideo}?${getQueryParams(params)}`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getVoiceSuccess(response.data));
    } else {
      yield put(getVoiceFailed(response.data.message));
    }
  } catch (error) {
    yield put(getVoiceFailed(error || "Error not found!"));
  }
}

function* fetchAllVideos({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.fetchAllAiVideos}?${getQueryParams(params)}&${params.offset===0 && "offset=0"}`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200) {
      yield put(fetchAllVideoSuccess(response.data));
    } else {
      yield put(fetchAllVideoFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchAllVideoFailed(error || "Error not found!"));
  }
}

function* editVoice({ params }) {
  let options = getUnProtectedOptions(endpoints.editVoices, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editVoiceSuccess(response.data));
    } else {
      yield put(editVoiceFailed(response.data.message));
    }
  } catch (error) {
    yield put(editVoiceFailed(error || "Error not found!"));
  }
}


function* videoPayment({ params }) {
  let options = getUnProtectedOptions(endpoints.videoPayment, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(videoPaymentSuccess(response.data));
    } else {
      yield put(videoPaymentFailed(response.data.message));
    }
  } catch (error) {
    yield put(videoPaymentFailed(error || "Error not found!"));
  }
}


//Watcher
export function* watchGetVideo() {
  yield takeLatest(constants.GET_VIDEO_REQUEST, getVideo);
}
export function* watchDeleteVideo() {
  yield takeLatest(constants.DELETE_VIDEO_REQUEST, deleteVideo);
}
export function* watchDownloadVideo() {
  yield takeLatest(constants.DOWNLOAD_VIDEO_REQUEST, downloadVideo);
}

export function* watchGetVoice() {
  yield takeLatest(constants.GET_VOICE_REQUEST, getVoice);
}

export function* watchFetchVideo() {
  yield takeLatest(constants.FETCH_VIDEO_REQUEST, fetchVideo);
}

export function* watchEditVoice() {
  yield takeLatest(constants.EDIT_VOICE_REQUEST, editVoice);
}

export function* watchFetchAllVideo() {
  yield takeLatest(constants.FETCH_ALL_VIDEO_REQUEST, fetchAllVideos);
}

export function* watchVideoPayment() {
  yield takeLatest(constants.VIDEO_PAYMENT_REQUEST, videoPayment);
}
function* videoSaga() {
  yield all([
    watchGetVideo(),
    watchDeleteVideo(),
    watchDownloadVideo(),
    watchGetVoice(),
    watchEditVoice(),
    watchFetchVideo(),
    watchFetchAllVideo(),
    watchVideoPayment()
  ]);
}

export default videoSaga;
