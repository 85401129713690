import React from 'react';
import '../../../assets/css/fba-calc.css'

function Overlay({ fetchData, title, spinner, content, button }) {
    return (
        <div className="fba-overlay">
            <div className="fba-overlay-cont">
            {spinner && <div className="lds-ellipsis"><div/><div/><div/><div/></div>}
                <h3>{title}</h3>
                <p>{content}</p>
                {button && <button type='button' className="btn-fetch" onClick={() => fetchData()}>{button}</button>}
            </div>
        </div>

    );
}

export default Overlay;