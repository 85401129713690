import { constants } from "./constants";

const INIT_STATE = {
    loading: false,
    dashboardData: {},
    errorMessage: null,
    successMessage: null,
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {

        // REQUEST
        case constants.GET_DASHBOARD_LIST_REQUEST:
            return { ...state, loading: true };

        //FAILED
        case constants.GET_DASHBOARD_LIST_FAILED:
            return {
                ...state,
                loading: false
            };

        case constants.GET_DASHBOARD_LIST_SUCCESS:          //SUCCESS
            return {
                ...state,
                loading: false,
                dashboardData: action.payload,
            };

        default:
            return { ...state };
    }
};

export default Dashboard;