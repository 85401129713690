import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../helpers/api";

import { setUserStatus } from "../../helpers/authUtils";
import {
  getCheckSellerStatusSuccess,
  getCheckSellerStatusFailed,
} from "./actions";

function* getCheckSellerStatus({ params }) {
  let options = getProtectedOptions(
    endpoints.checkSellerStatus,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      setUserStatus(response.data);
      yield put(getCheckSellerStatusSuccess(response.data));
    } else {
      yield put(getCheckSellerStatusFailed(response.data.message));
    }
  } catch (error) {
    yield put(getCheckSellerStatusFailed(error || "Error not found!"));
  }
}

export function* watchGetCheckSellerStatus() {
  yield takeLatest(
    constants.GET_CHECK_SELLER_STATUS_REQUEST,
    getCheckSellerStatus
  );
}

function* onBoardingSaga() {
  yield all([watchGetCheckSellerStatus()]);
}

export default onBoardingSaga;
