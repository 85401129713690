import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  getSubscriptionGraphSuccess,
  getSubscriptionGraphFailed,
  getRevenueGraphFailed,
  getRevenueGraphSuccess,
} from "./action";

function* geSubscriptionGraphData({ data }) {
  let options = getProtectedOptions(
    endpoints.getSubscriptionGraph,
    "POST",
    data
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getSubscriptionGraphSuccess(response.data));
    } else {
      yield put(getSubscriptionGraphFailed(response.data.message));
    }
  } catch (error) {
    yield put(getSubscriptionGraphFailed(error || "Error not found!"));
  }
}

function* getRevenueGraphData({ data }) {
  let options = getProtectedOptions(endpoints.getRevenueGraphApi, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getRevenueGraphSuccess(response.data));
    } else {
      yield put(getRevenueGraphFailed(response.data.message));
    }
  } catch (error) {
    yield put(getRevenueGraphFailed(error || "Error not found!"));
  }
}

export function* watchGetSubscriptionGraphData() {
  yield takeLatest(
    constants.GET_ADMIN_SUBSCRIPTION_GRAPH_REQUEST,
    geSubscriptionGraphData
  );
}
export function* watchGetRevenueGraphData() {
  yield takeLatest(
    constants.GET_ADMIN_REVENUE_GRAPH_REQUEST,
    getRevenueGraphData
  );
}

function* adminDashboardSaga() {
  yield all([watchGetSubscriptionGraphData(), watchGetRevenueGraphData()]);
}

export default adminDashboardSaga;
