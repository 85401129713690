export const constants = {
    GET_COMPETITOR_REQUEST: "GET_COMPETITOR_REQUEST",
    GET_COMPETITOR_SUCCESS: "GET_COMPETITOR_SUCCESS",
    GET_COMPETITOR_FAILED: "GET_COMPETITOR_FAILED",

    DELETE_COMPETITOR_REQUEST: "DELETE_COMPETITOR_REQUEST",
    DELETE_COMPETITOR_SUCCESS: "DELETE_COMPETITOR_SUCCESS",
    DELETE_COMPETITOR_FAILED: "DELETE_COMPETITOR_FAILED",

    ADD_COMPETITOR_REQUEST: "ADD_COMPETITOR_REQUEST",
    ADD_COMPETITOR_SUCCESS: "ADD_COMPETITOR_SUCCESS",
    ADD_COMPETITOR_FAILED: "ADD_COMPETITOR_FAILED",

    GET_COMPETITOR_ALERTS_REQUEST: "GET_COMPETITOR_ALERTS_REQUEST",
    GET_COMPETITOR_ALERTS_SUCCESS: "GET_COMPETITOR_ALERTS_SUCCESS",
    GET_COMPETITOR_ALERTS_FAILED: "GET_COMPETITOR_ALERTS_FAILED",

    UPDATE_COMPETITOR_ALERTS_REQUEST:"UPDATE_COMPETITOR_ALERTS_REQUEST",
    UPDATE_COMPETITOR_ALERTS_SUCCESS:"UPDATE_COMPETITOR_ALERTS_SUCCESS",
    UPDATE_COMPETITOR_ALERTS_FAILED:"UPDATE_COMPETITOR_ALERTS_FAILED",

     // sort filter
     FETCH_FILTER_SEARCH_REQUEST: "FETCH_FILTER_SEARCH_REQUEST",
     FETCH_FILTER_SEARCH_SUCCESS: "FETCH_FILTER_SEARCH_SUCCESS",
     FETCH_FILTER_SEARCH_FAILED: "FETCH_FILTER_SEARCH_FAILED",

}