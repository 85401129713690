import { constants } from "./constants";

export const resetMessages = () => ({
  type: constants.RESET_ADMIN_ALERTS_MESSAGE,
});

export const getAlerts = (data) => ({
  type: constants.GET_ADMIN_ALERTS_REQUEST,
  data,
});

export const getAlertsSuccess = (data) => ({
  type: constants.GET_ADMIN_ALERTS_SUCCESS,
  payload: data,
});

export const getAlertsFailed = (error) => ({
  type: constants.GET_ADMIN_ALERTS_FAILED,
  payload: error,
});

export const updateAlerts = (data) => ({
  type: constants.UPDATE_ADMIN_ALERTS_REQUEST,
  data,
});

export const updateAlertsSuccess = (data) => ({
  type: constants.UPDATE_ADMIN_ALERTS_SUCCESS,
  payload: data,
});

export const updateAlertsFailed = (error) => ({
  type: constants.UPDATE_ADMIN_ALERTS_FAILED,
  payload: error,
});

// export const editAlerts = (data) => ({
//     type: constants.EDIT_ADMIN_FAQ_REQUEST,
//     data
// });

// export const editAlertsSuccess = (data) => ({
//     type: constants.EDIT_ADMIN_FAQ_SUCCESS,
//     payload: data
// });

// export const editAlertsFailed = error => ({
//     type: constants.EDIT_ADMIN_FAQ_FAILED,
//     payload: error
// });

// export const deleteAlerts = (data) => ({
//     type: constants.DELETE_ADMIN_FAQ_REQUEST,
//     data
// });

// export const deleteAlertsSuccess = (data) => ({
//     type: constants.DELETE_ADMIN_FAQ_SUCCESS,
//     payload: data
// });

// export const deleteAlertsFailed = error => ({
//     type: constants.DELETE_ADMIN_FAQ_FAILED,
//     payload: error
// });
