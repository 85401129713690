import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  feedbackList: [],
  pageCount: null,
};

const adminFeedback = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_FEEDBACK_MESSAGE:
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };

    case constants.LIST_FEEDBACK_REQUEST:
    case constants.FEEDBACK_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.LIST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        feedbackList: action.payload,
        pageCount: action.payload.total_pages,
      };

    case constants.LIST_FEEDBACK_FAILED:
      return {
        ...state,
        loading: false,
      };

    case constants.FEEDBACK_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
      };

    case constants.FEEDBACK_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return { ...state };
  }
};

export default adminFeedback;
