import { constants } from "./constants";

export const resetMessages = () => ({
  type: constants.RESET_SETTINGS_MESSAGE,
});

export const getAdminDetails = (data) => ({
  type: constants.GET_ADMIN_DETAILS_REQUEST,
  data,
});

export const getAdminDetailsSuccess = (data) => ({
  type: constants.GET_ADMIN_DETAILS_SUCCESS,
  payload: data,
});

export const getAdminDetailsFailed = (error) => ({
  type: constants.GET_ADMIN_DETAILS_FAILED,
  payload: error,
});

export const uploadImage = (data) => ({
  type: constants.UPLOAD_IMAGE,
  data,
});

export const uploadImageSuccess = (data) => ({
  type: constants.UPLOAD_IMAGE_SUCCESS,
  payload: data,
});

export const uploadImageFailed = (error) => ({
  type: constants.UPLOAD_IMAGE_FAILED,
  payload: error,
});

export const logoUpload = (data) => ({
  type: constants.UPLOAD_LOGO,
  data,
});

export const logoUploadSuccess = (data) => ({
  type: constants.UPLOAD_LOGO_SUCCESS,
  payload: data,
});

export const logoUploadFailed = (error) => ({
  type: constants.UPLOAD_LOGO_FAILED,
  payload: error,
});

export const resetPassword = (data) => ({
  type: constants.RESET_PASSWORD,
  data,
});

export const resetPasswordSuccess = (data) => ({
  type: constants.RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailed = (error) => ({
  type: constants.RESET_PASSWORD_FAILED,
  payload: error,
});

export const editProfile = (data) => ({
  type: constants.EDIT_PROFILE,
  data,
});

export const editProfileSuccess = (data) => ({
  type: constants.EDIT_PROFILE_SUCCESS,
  payload: data,
});

export const editProfileFailed = (error) => ({
  type: constants.EDIT_PROFILE_FAILED,
  payload: error,
});
