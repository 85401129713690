import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
    apiCall,
    getProtectedOptions,
} from "../../helpers/api";
import { getRevenueReportFailed, getRevenueReportSuccess } from "./actions";

function* getRevenueReport({ params }) {
    let options = getProtectedOptions(endpoints.revenueReport, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getRevenueReportSuccess(response.data));
        } else {
            yield put(getRevenueReportFailed(response.data.message));
        }
    } catch (error) {
        yield put(getRevenueReportFailed(error || "Error not found!"));
    }
}

//Watcher
export function* watchGetRevenueReport() {
    yield takeLatest(constants.GET_REVENUE_REPORT_REQUEST, getRevenueReport);
};

function* analyticsSaga() {
    yield all([
        watchGetRevenueReport(),
    ]);
}

export default analyticsSaga;