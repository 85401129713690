import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  getCustomersFailed,
  getCustomersSuccess,
  sendSellerMailSuccess,
  sendSellerMailFailed,
  generateTaskSuccess,
  generateTaskFailed,
} from "./action";

function* getCustomersList({ data }) {
  let options = getProtectedOptions(endpoints.getCustomers, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getCustomersSuccess(response.data.data));
    } else {
      yield put(getCustomersFailed(response.data.message));
    }
  } catch (error) {
    yield put(getCustomersFailed(error || "Error not found!"));
  }
}

function* sendMailToSeller({ data }) {
  let options = getProtectedOptions(endpoints.customerEmailSend, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(sendSellerMailSuccess(response.data.message));
    } else {
      yield put(sendSellerMailFailed(response.data.message));
    }
  } catch (error) {
    yield put(sendSellerMailFailed(error || "Error, mail not send!"));
  }
}

function* generateNewTask({ data }) {
  let options = getProtectedOptions(endpoints.backgroundTask, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(generateTaskSuccess(response.data.message));
    } else {
      yield put(generateTaskFailed(response.data.message));
    }
  } catch (error) {
    yield put(
      generateTaskFailed(error || "Could not generate new Background Task!")
    );
  }
}

//Watcher
export function* watchGetCustomers() {
  yield takeLatest(constants.GET_CUSTOMERS_REQUEST, getCustomersList);
}

export function* watchSendMailToSeller() {
  yield takeLatest(constants.SEND_SELLER_MAIL, sendMailToSeller);
}

export function* watchGenerateNewTask() {
  yield takeLatest(constants.GENERATE_TASK, generateNewTask);
}

function* customerSaga() {
  yield all([
    watchGetCustomers(),
    watchSendMailToSeller(),
    watchGenerateNewTask(),
  ]);
}

export default customerSaga;
