import { constants } from "./constants";

export const resetPublicMessages = () => ({
    type: constants.RESET_PUBLIC_MESSAGE
});

export const addContactMessage = (params) => ({
    type: constants.ADD_CONTACT_MESSAGE_REQUEST,
    params
});

export const addContactMessageSuccess = (data) => ({
    type: constants.ADD_CONTACT_MESSAGE_SUCCESS,
    payload: data
});

export const addContactMessageFailed = error => ({
    type: constants.ADD_CONTACT_MESSAGE_FAILED,
    payload: error
});

export const getFAQ = () => ({
    type: constants.GET_FAQ_REQUEST
});

export const getFAQSuccess = (data) => ({
    type: constants.GET_FAQ_SUCCESS,
    payload: data
});

export const getFAQFailed = error => ({
    type: constants.GET_FAQ_FAILED,
    payload: error
});

export const submitFeedback = (data) => ({
    type: constants.SUBMIT_FEEDBACK_REQUEST,
    params: data
});

export const submitFeedbackSuccess = (data) => ({
    type: constants.SUBMIT_FEEDBACK_SUCCESS,
    payload: data
});

export const submitFeedbackFailed = error => ({
    type: constants.SUBMIT_FEEDBACK_FAILED,
    payload: error
});

export const getBlog = (params) => ({
    type: constants.GET_USER_BLOG_REQUEST,
    params,
  });
  
  export const getBlogSuccess = (payload) => ({
    type: constants.GET_USER_BLOG_SUCCESS,
    payload,
  });
  
  export const getBlogFailed = (error) => ({
    type: constants.GET_USER_BLOG_FAILED,
    payload: error,
  });

  export const fetchBlogDetail = (data) => ({
    type: constants.FETCH_BLOG_LIST_DETAIL_REQUEST,
    data,
  });
  
  export const fetchBlogDetailSuccess = (data) => ({
    type: constants.FETCH_BLOG_LIST_DETAIL_SUCCESS,
    payload: data,
  });
  
  export const fetchBlogDetailFailed = (error) => ({
    type: constants.FETCH_BLOG_LIST_DETAIL_FAILED,
    payload: error,
  });

  export const blogSubscription = (data) => ({
    type: constants.FOOTER_SUBSCRIPTON_REQUEST,
    params:data
  });
  
  export const blogSubscriptionSuccess = (data) => ({
    type: constants.FOOTER_SUBSCRIPTON_SUCCESS,
    payload:data
  });
  
  export const blogSubscriptionFailed = (error) => ({
    type: constants.FOOTER_SUBSCRIPTON_FAILED,
    payload: error,
  });