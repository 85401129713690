import { constants } from "./constants";

const INIT_STATE = {
  suggestedDeals: [],
  dealDetails: [],
  total_count: null,
  total_pages: null,
  loading: false,
  isDataExist:"",
  is_Data_Changed: false,
  pageCount: null,
  errorMessage: null,
  successMessage: null,
  editStatus:false,
};

const DealSuggestions = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.FETCH_PRODUCT_REQUEST: // REQUEST
    case constants.GET_DEAL_DETAILS_REQUEST: // REQUEST
    case constants.REJECT_DEAL_REQUEST: // REQUEST
    case constants.GET_DEAL_SUGGESTIONS_REQUEST: // REQUEST
    case constants.CREATE_SUGGESTED_DEAL_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
        isDeleted: false,
        isDataExist: "",
        successMessage: null,
        errorMessage: null,
        is_Data_Changed: false,
      };
    case constants.SORT_SUGGESTED_DEAL_REQUEST: // REQUEST
    case constants.EDIT_SUGGESTED_DEAL_REQUEST: // REQUEST

      return {
        ...state,
        loading: false,
        isDeleted: false,
        isDataExist: "",
        successMessage: null,
        errorMessage: null,
        is_Data_Changed: false,
        editStatus: false,
      };
    case constants.FETCH_PRODUCT_FAILED: //FAILED
    case constants.GET_DEAL_DETAILS_FAILED: //FAILED
    case constants.REJECT_DEAL_FAILED: //FAILED
    case constants.CREATE_SUGGESTED_DEAL_FAILED: //FAILED
    case constants.SORT_SUGGESTED_DEAL_FAILED: //FAILED
      return {
        ...state,
        loading: false,
        isDeleted: false,
        isDataExist: false,
        successMessage: null,
        errorMessage: action.payload,
      };
    case constants.GET_DEAL_SUGGESTIONS_FAILED: //FAILED
    case constants.EDIT_SUGGESTED_DEAL_FAILED: //FAILED
      return {
        ...state,
        loading: false,
        isDeleted: false,
        isDataExist: false,
        successMessage: null,
        editStatus: false
      };
    case constants.SORT_SUGGESTED_DEAL_SUCCESS: //SUCCESS
    case constants.GET_DEAL_SUGGESTIONS_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        isDeleted: true,
        isDataExist: true,
        total_count: action.payload.total_count,
        total_pages: action.payload.total_pages,
        errorMessage: null,
        suggestedDeals: action.payload.data,
        // successMessage: action.payload.message,
      };
      case constants.EDIT_SUGGESTED_DEAL_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        isDeleted: true,
        isDataExist: true,
        total_count: action.payload.total_count,
        total_pages: action.payload.total_pages,
        errorMessage: null,
        editStatus: true,
        successMessage: action.payload.message,
      };
    case constants.GET_DEAL_DETAILS_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        isDeleted: false,
        errorMessage: null,
        dealDetails: action.payload.data,
        // successMessage: action.payload.message,
      };

    case constants.REJECT_DEAL_SUCCESS: //SUCCESS
    case constants.FETCH_PRODUCT_SUCCESS: //SUCCESS
    case constants.CREATE_SUGGESTED_DEAL_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        isDeleted: false,
        errorMessage: null,
        is_Data_Changed: action.payload.status,
        successMessage: action.payload.message,
      };
    default:
      return { ...state };
  }
};

export default DealSuggestions;
