import { constants } from "./constants";
import { toast } from "react-toastify";

const INIT_STATE = {
  loading: false,
  pageCount: null,
  totalCount: null,
  categorySpyList: [],
  subcategoryData: null,
  categoryTableData: null,
  categoryDataCount: null,
  mainCategoryData: null,
  addCategoryResponse: null,
  isSubcategoryExist: true,
  subCategoryUpdated: false,
  refreshCategoryTable: false,
  categorySpyErrorMessage: null,
  categorySpySuccessMessage: null,
  planDetails:null,
  isLimitReached: false,

};

const CategorySpy = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.GET_LISTING_CATEGORYSPY_REQUEST: // REQUEST
      return { ...state, loading: true };

    case constants.ADD_SUB_CATEGORY_ID_REQUEST: // REQUEST
      return { ...state, loading: true, subCategoryUpdated: false };
    case constants.ADD_CATEGORY_ID_REQUEST:
      return {
        ...state,
        listLoading: true,
        addCategoryResponse: null,
        refreshCategoryTable: false,
        planDetails:null,
        isLimitReached: false,
      };
    case constants.CLEAR_CATEGORY_LIST:
    case constants.DELETE_CATEGORY_SPY_REQUEST: // REQUEST
    return { ...state, loading: true, refreshCategoryTable: false,categorySpyList:null };
    case constants.FETCH_MAIN_CATEGORY_REQUEST:
    return { ...state, loading: true, refreshCategoryTable: false};
    case constants.FETCH_CATEGORY_DATA_REQUEST:
    case constants.FETCH_SUB_CATEGORY_REQUEST:
      return { ...state, loading: true, refreshCategoryTable: false };
    case constants.FETCH_CATEGORY_DATA_FAILED:
      return { ...state, loading: false, categoryTableData: null };
    case constants.GET_LISTING_CATEGORYSPY_FAILED: //FAILED
      toast.error(action.payload);
      return { ...state, loading: false };
    case constants.DELETE_CATEGORY_SPY_FAILED:
      return { ...state, loading: false };
    case constants.FETCH_MAIN_CATEGORY_FAILED:
      return { ...state, mainCategoryData: null, loading: false };
    case constants.FETCH_SUB_CATEGORY_FAILED:
      toast?.error(action.payload);
      return {
        ...state,
        loading: false,
        subcategoryData: null,
        isSubcategoryExist: false,
      };
    case constants.ADD_SUB_CATEGORY_ID_FAILED:
    case constants.ADD_CATEGORY_ID_FAILED:
      toast.error(action.payload);

      return {
        ...state,
        listLoading: false,
        categorySpyErrorMessage: action.payload,
        loading: false,
        // categorySpyList: null,
        planDetails: action?.payload?.data?.plan,
        isLimitReached: action.payload?.limit_reached ? action.payload?.limit_reached : false,
      };
    case constants.FETCH_CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryDataCount: action.payload.total_count,
        categoryTableData: action?.payload?.data,
        subcategoryData: null,
      };

    case constants.GET_LISTING_CATEGORYSPY_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: action.payload.count,
        categorySpyList: action.payload.data,
      };
    case constants.DELETE_CATEGORY_SPY_SUCCESS:
      toast?.success(action.payload.message);
      return {
        ...state,
        loading: false,
        refreshCategoryTable: action.payload.status,
      };
    case constants.FETCH_MAIN_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        mainCategoryData: action.payload.data,
      };
    case constants.FETCH_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubcategoryExist: true,
        subcategoryData: action.payload.data,
      };
    case constants.ADD_SUB_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategoryUpdated: true,
      };
    case constants.ADD_CATEGORY_ID_SUCCESS:
      toast?.success(action.payload.message);
      return {
        ...state,
        listLoading: false,
        refreshCategoryTable: action.payload?.status,
        addCategoryResponse: action.payload,
      };
    default:
      return { ...state };
  }
};

export default CategorySpy;
