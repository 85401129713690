/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import img404 from "../../assets/img/404.svg";
const NotFound = () => {
  const redirPath = () => {
    return "";
  };
  return (
    <>
      <div className="content-area">
        <div className="nodata">
          <img src={img404} alt="" className="nodata-image" />
          <h2>
            Page 404 <span>Not Found</span>
          </h2>
          <p>Looks like the page you are looking for does not exist !</p>
          <Link to={redirPath} className="text-primary text-center">
            Return to Home Page
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
