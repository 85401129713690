import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  getBlogListFailed,
  getBlogListSuccess,
  editBlogSuccess,
  deleteBlogSuccess,
  deleteBlogFailed,
  editBlogFailed,
  searchSortBlogFailed,
  searchSortBlogSuccess,
  getAdminBlogDetailsFailed,
  getAdminBlogDetailsSuccess
} from "./action";
import { addBlogSuccess, addBlogFailed } from "./action";

function* getBlogList({ data }) {
  let options = getProtectedOptions(endpoints.getBlogs, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getBlogListSuccess(response.data));
    } else {
      yield put(getBlogListFailed(response.data.message));
    }
  } catch (error) {
    yield put(getBlogListFailed(error || "Error not found!"));
  }
}

function* getAdminBlogDetails({ data }) {
  let options = getProtectedOptions(endpoints.getBlogData, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getAdminBlogDetailsSuccess(response.data));
    } else {
      yield put(getAdminBlogDetailsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getAdminBlogDetailsFailed(error || "Error not found!"));
  }
}
//Detete

function* deleteBlogList({ data }) {
  let options = getProtectedOptions(endpoints.deleteBlog, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteBlogSuccess(response.data));
    } else {
      yield put(deleteBlogFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteBlogFailed(error || "Error not found!"));
  }
}

// for edit
function* editBlogList({ data }) {
  let options = getProtectedOptions(endpoints.editBlog, "POST", data, "file");
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editBlogSuccess(response.data.message));
    } else {
      yield put(editBlogFailed(response.data.message));
    }
  } catch (error) {
    yield put(editBlogFailed(error || "Error not found!"));
  }
}

//addBlog

function* addBlogList({data} ) {
  let options = getProtectedOptions(endpoints.addBlog, "POST", data, "file");
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addBlogSuccess(response.data.message));
    } else {
      yield put(addBlogFailed(response.data.message));
    }
  } catch (error) {
    yield put(addBlogFailed(error || "Error not found!"));
  }
}
//for search and sort

function* searchSortBlog({ data }) {
  let options = getProtectedOptions(endpoints.searchSortBlog, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(searchSortBlogSuccess(response.data));
    } else {
      yield put(searchSortBlogFailed(response.data.message));
    }
  } catch (error) {
    yield put(searchSortBlogFailed(error || "Error not found!"));
  }
}
//Watcher
export function* watchgetBlogList() {
  yield takeLatest(constants.GET_BLOG_LIST_REQUEST, getBlogList);
}
export function* watchgetBlogData() {
  yield takeLatest(constants.GET_ADMIN_BLOG_DETAILS_REQUEST, getAdminBlogDetails);
}
export function* watchdeleteBlog() {
  yield takeLatest(constants.DELETE_ADMIN_BLOG_REQUEST, deleteBlogList);
}
export function* watchBlogEdit() {
  yield takeLatest(constants.EDIT_ADMIN_BLOG_REQUEST, editBlogList);
}

export function* watchBlogAdd() {
  yield takeLatest(constants.ADD_ADMIN_BLOG_REQUEST, addBlogList);
}
export function* watchBlogSearchSort() {
  yield takeLatest(constants.SEARCH_SORT_ADMIN_BLOG_REQUEST, searchSortBlog);
}

function* SellerDealsaga() {
  yield all([watchgetBlogList(),watchdeleteBlog(), watchBlogEdit(), watchBlogAdd(),watchBlogSearchSort(),watchgetBlogData()]);
}

export default SellerDealsaga;
