import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  addFaqFailed,
  addFaqSuccess,
  deleteFaqFailed,
  deleteFaqSuccess,
  editFaqFailed,
  editFaqSuccess,
  getFaqFailed,
  getFaqSuccess,
} from "./action";

function* getFaqList({ data }) {
  let options = getProtectedOptions(endpoints.getFaq, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getFaqSuccess(response.data));
    } else {
      yield put(getFaqFailed(response.data.message));
    }
  } catch (error) {
    yield put(getFaqFailed(error || "Error not found!"));
  }
}

function* addFaqList({ data }) {
  let options = getProtectedOptions(endpoints.addAdminFaq, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addFaqSuccess(response.data.message));
    } else {
      yield put(addFaqFailed(response.data.message));
    }
  } catch (error) {
    yield put(addFaqFailed(error || "Error not found!"));
  }
}

function* editFaqList({ data }) {
  let options = getProtectedOptions(endpoints.editAdminFaq, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(editFaqSuccess(response.data.message));
    } else {
      yield put(editFaqFailed(response.data.message));
    }
  } catch (error) {
    yield put(editFaqFailed(error || "Error not found!"));
  }
}

function* deleteFaqList({ data }) {
  let options = getProtectedOptions(endpoints.deleteAdminFaq, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteFaqSuccess(response.data.message));
    } else {
      yield put(deleteFaqFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteFaqFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetFaq() {
  yield takeLatest(constants.GET_ADMIN_FAQ_REQUEST, getFaqList);
}
export function* watchAddFaq() {
  yield takeLatest(constants.ADD_ADMIN_FAQ_REQUEST, addFaqList);
}
export function* watchEditFaq() {
  yield takeLatest(constants.EDIT_ADMIN_FAQ_REQUEST, editFaqList);
}
export function* watchDeleteFaq() {
  yield takeLatest(constants.DELETE_ADMIN_FAQ_REQUEST, deleteFaqList);
}

function* customerSaga() {
  yield all([watchGetFaq(), watchAddFaq(), watchEditFaq(), watchDeleteFaq()]);
}

export default customerSaga;
