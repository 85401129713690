import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  updateAlertsFailed,
  updateAlertsSuccess,
  getAlertsFailed,
  getAlertsSuccess,
} from "./action";

function* getAlertsList({ data }) {
  let options = getProtectedOptions(endpoints.getAdminAlerts, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getAlertsSuccess(response.data.data));
    } else {
      yield put(getAlertsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getAlertsFailed(error || "Error not found!"));
  }
}

function* updateAlertsList({ data }) {
  let options = getProtectedOptions(endpoints.updateAdminAlerts, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(updateAlertsSuccess(response.data.message));
    } else {
      yield put(updateAlertsFailed(response.data.message));
    }
  } catch (error) {
    yield put(updateAlertsFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetAlerts() {
  yield takeLatest(constants.GET_ADMIN_ALERTS_REQUEST, getAlertsList);
}
export function* watchUpdateAlerts() {
  yield takeLatest(constants.UPDATE_ADMIN_ALERTS_REQUEST, updateAlertsList);
}

function* adminAlertSaga() {
  yield all([watchGetAlerts(), watchUpdateAlerts()]);
}

export default adminAlertSaga;
