export const constants = {
  GET_DEAL_SUGGESTIONS_REQUEST: "GET_DEAL_SUGGESTIONS_REQUEST",
  GET_DEAL_SUGGESTIONS_SUCCESS: "GET_DEAL_SUGGESTIONS_SUCCESS",
  GET_DEAL_SUGGESTIONS_FAILED: "GET_DEAL_SUGGESTIONS_FAILED",
  
  FETCH_PRODUCT_REQUEST: "FETCH_PRODUCT_REQUEST",
  FETCH_PRODUCT_SUCCESS: "FETCH_PRODUCT_SUCCESS",
  FETCH_PRODUCT_FAILED: "FETCH_PRODUCT_FAILED",

  
  GET_DEAL_DETAILS_REQUEST: "GET_DEAL_DETAILS_REQUEST",
  GET_DEAL_DETAILS_SUCCESS: "GET_DEAL_DETAILS_SUCCESS",
  GET_DEAL_DETAILS_FAILED: "GET_DEAL_DETAILS_FAILED",

  REJECT_DEAL_REQUEST: "REJECT_DEAL_REQUEST",
  REJECT_DEAL_SUCCESS: "REJECT_DEAL_SUCCESS",
  REJECT_DEAL_FAILED: "REJECT_DEAL_FAILED",
  
  CREATE_SUGGESTED_DEAL_REQUEST: "CREATE_SUGGESTED_DEAL_REQUEST",
  CREATE_SUGGESTED_DEAL_SUCCESS: "CREATE_SUGGESTED_DEAL_SUCCESS",
  CREATE_SUGGESTED_DEAL_FAILED: "CREATE_SUGGESTED_DEAL_FAILED",

  // FOR SORT

  SORT_SUGGESTED_DEAL_REQUEST: "SORT_SUGGESTED_DEAL_REQUEST",
  SORT_SUGGESTED_DEAL_SUCCESS: "SORT_SUGGESTED_DEAL_SUCCESS",
  SORT_SUGGESTED_DEAL_FAILED: "SORT_SUGGESTED_DEAL_FAILED",

   // FOR SUGGEST DEAL EDIT

   EDIT_SUGGESTED_DEAL_REQUEST: "EDIT_SUGGESTED_DEAL_REQUEST",
   EDIT_SUGGESTED_DEAL_SUCCESS: "EDIT_SUGGESTED_DEAL_SUCCESS",
   EDIT_SUGGESTED_DEAL_FAILED: "EDIT_SUGGESTED_DEAL_FAILED",
};
