import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import { getReviewListFailed, getReviewListSuccess } from "./action";
import { apiCall, getProtectedOptions, getUnProtectedOptions } from "../../helpers/api";

function* getReviewList({ params }) {
    let options = getProtectedOptions(endpoints.getReview, "POST", params);
    // let options = getUnProtectedOptions(endpoints.addOrders, "P/OST", params);

    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getReviewListSuccess(response.data));
        } else {
            yield put(getReviewListFailed(response.data.message));
        }
    } catch (error) {
        yield put(getReviewListFailed(error || "Error not found!"));
    }
}

//Watcher
export function* watchgetReviewsList() {
    yield takeLatest(constants.GET_REVIEW_LIST_REQUEST, getReviewList);
};

function* reviewsSaga() {
    yield all([
        watchgetReviewsList(),
    ]);
}

export default reviewsSaga;