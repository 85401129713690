import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  pageCount: null,
  allCategory: [],
  tableContent:null,
  analyticsErrorMessage: null,
  analyticsSuccessMessage: null
};

const Category = (state = INIT_STATE, action) => {
  switch (action.type) {
      // case constants.RESET_ANALYTICS_MESSAGE:          //RESET MSGS
      //     return {
      //         ...state,
      //         analyticsErrorMessage: null,
      //         analyticsSuccessMessage: null
      //     };

      case constants.LOAD_MORE_CONTENT_REQUEST: // REQUEST
      return { ...state, loading: true };
      case constants.GET_LISTING_CATEGORY_REQUEST: // REQUEST
      case constants.FETCH_CATEGORY_TRENDS_REQUEST: // REQUEST
      return { ...state, listLoading: true};

      case constants.GET_LISTING_CATEGORY_FAILED:     //FAILED
      case constants.FETCH_CATEGORY_TRENDS_FAILED:     //FAILED
      case constants.LOAD_MORE_CONTENT_FAILED:     //FAILED
          return {
              ...state,
              analyticsErrorMessage: action.payload,
              loading: false,
              allCategory:null
          };

      case constants.GET_LISTING_CATEGORY_SUCCESS:  
          return {
              ...state,
              loading: false,
              // productsErrorMessage: null,
              allCategorys: action.payload.data,
              // pageCount: action.payload.total_pages,
          };

          case constants.FETCH_CATEGORY_TRENDS_SUCCESS:  
          return {
              ...state,
              listLoading: false,
              // productsErrorMessage: null,
              tableContent: action.payload.data,
              // pageCount: action.payload.total_pages,
          };
          case constants.LOAD_MORE_CONTENT_SUCCESS:  
          return {
              ...state,
              loading: false,
              // productsErrorMessage: null,
              moreTableContent: action.payload.data,
              // pageCount: action.payload.total_pages,
          };
      default:
          return { ...state };
  }
};


export default Category;