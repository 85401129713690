import { constants } from './constants'

const INIT_STATE = {
  dashboardData: [],
  total_count: 0,
  loading: false,
  pageCount: null,

  errorMessage: null,
  successMessage: null,
}

const SellerDealsDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_SELLER_DASHBOARD_DATA_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
      }

    case constants.GET_SELLER_DASHBOARD_DATA_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,

        loading: false,
      }

    case constants.GET_SELLER_DASHBOARD_DATA_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,

        errorMessage: null,
        dashboardData: action.payload.data,
      }

    default:
      return { ...state }
  }
}

export default SellerDealsDashboard
