import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  adminDetails: {},
  errorMessage: null,
  successMessage: null,
  imageDetails: [],
  logoDetails: [],
  resetPass: false,
  changeDetails: false,
};

const AdminSettings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case constants.RESET_SETTINGS_MESSAGE: //RESET MSGS
      return {
        ...state,
        successMessage: null,
        errorMessage: null,
      };

    case constants.GET_ADMIN_DETAILS_REQUEST: // REQUEST
    case constants.RESET_PASSWORD:
    case constants.EDIT_PROFILE:
      return { ...state, loading: true };

    case constants.GET_ADMIN_DETAILS_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };

    case constants.GET_ADMIN_DETAILS_SUCCESS: //SUCCESS
      return {
        ...state,
        loading: false,
        adminDetails: action.payload,
        errorMessage: null,
        successMessage: null,
        changeDetails: false,
      };

    case constants.UPLOAD_IMAGE:
      return {
        ...state,
        loading: true,
        imageDetails: action.payload,
      };

    case constants.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
      };

    case constants.UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case constants.UPLOAD_LOGO:
      return {
        ...state,
        loading: true,
        logoDetails: action.payload,
      };

    case constants.UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
      };

    case constants.UPLOAD_LOGO_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case constants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
        resetPass: true,
      };

    case constants.RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        resetPass: false,
      };

    case constants.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: action.payload,
        changeDetails: true,
      };

    case constants.EDIT_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return { ...state };
  }
};

export default AdminSettings;
