export const constants = {
  RESET_ADMIN_ENQUIRY_MESSAGE: "RESET_ADMIN_ENQUIRY_MESSAGE",

  GET_ADMIN_ENQUIRY_REQUEST: "GET_ADMIN_ENQUIRY_REQUEST",
  GET_ADMIN_ENQUIRY_SUCCESS: "GET_ADMIN_ENQUIRY_SUCCESS",
  GET_ADMIN_ENQUIRY_FAILED: "GET_ADMIN_ENQUIRY_FAILED",

  ADD_ADMIN_ENQUIRY_REQUEST: "ADD_ADMIN_ENQUIRY_REQUEST",
  ADD_ADMIN_ENQUIRY_SUCCESS: "ADD_ADMIN_ENQUIRY_SUCCESS",
  ADD_ADMIN_ENQUIRY_FAILED: "ADD_ADMIN_ENQUIRY_FAILED",

  EDIT_ADMIN_ENQUIRY_REQUEST: "EDIT_ADMIN_ENQUIRY_REQUEST",
  EDIT_ADMIN_ENQUIRY_SUCCESS: "EDIT_ADMIN_ENQUIRY_SUCCESS",
  EDIT_ADMIN_ENQUIRY_FAILED: "EDIT_ADMIN_ENQUIRY_FAILED",

  DELETE_ADMIN_ENQUIRY_REQUEST: "DELETE_ADMIN_ENQUIRY_REQUEST",
  DELETE_ADMIN_ENQUIRY_SUCCESS: "DELETE_ADMIN_ENQUIRY_SUCCESS",
  DELETE_ADMIN_ENQUIRY_FAILED: "DELETE_ADMIN_ENQUIRY_FAILED",
};
