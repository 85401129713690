import { constants } from "./constants";

export const getAdminBlogDetails = (data) => ({
  type: constants.GET_ADMIN_BLOG_DETAILS_REQUEST,
  data,
});

export const getAdminBlogDetailsSuccess = (data) => ({
  type: constants.GET_ADMIN_BLOG_DETAILS_SUCCESS,
  payload: data,
});

export const getAdminBlogDetailsFailed = (error) => ({
  type: constants.GET_ADMIN_BLOG_DETAILS_FAILED,
  payload: error,
});

export const getBlogList = (data) => ({
  type: constants.GET_BLOG_LIST_REQUEST,
  data,
});

export const getBlogListSuccess = (data) => ({
  type: constants.GET_BLOG_LIST_SUCCESS,
  payload: data,
});

export const getBlogListFailed = (error) => ({
  type: constants.GET_BLOG_LIST_FAILED,
  payload: error,
});

//delete

export const deleteBlog = (data) => ({
  type: constants.DELETE_ADMIN_BLOG_REQUEST,
  data,
});

export const deleteBlogSuccess = (data) => ({
  type: constants.DELETE_ADMIN_BLOG_SUCCESS,
  payload: data,
});

export const deleteBlogFailed = (error) => ({
  type: constants.DELETE_ADMIN_BLOG_FAILED,
  payload: error,
});
// for edit
export const editBlog = (data) => ({
  type: constants.EDIT_ADMIN_BLOG_REQUEST,
  data,
});

export const editBlogSuccess = (data) => ({
  type: constants.EDIT_ADMIN_BLOG_SUCCESS,
  payload: data,
});

export const editBlogFailed = (error) => ({
  type: constants.EDIT_ADMIN_BLOG_FAILED,
  payload: error,
});

// for add new
export const addBlog = (data) => ({
  type: constants.ADD_ADMIN_BLOG_REQUEST,
  data,
});

export const addBlogSuccess = (data) => ({
  type: constants.ADD_ADMIN_BLOG_SUCCESS,
  payload: data,
});

export const addBlogFailed = (error) => ({
  type: constants.ADD_ADMIN_BLOG_FAILED,
  payload: error,
});

//for Search and sort

export const searchSortBlog = (data) => ({
  type: constants.SEARCH_SORT_ADMIN_BLOG_REQUEST,
  data,
});

export const searchSortBlogSuccess = (data) => ({
  type: constants.SEARCH_SORT_ADMIN_BLOG_SUCCESS,
  payload: data,
});

export const searchSortBlogFailed = (error) => ({
  type: constants.SEARCH_SORT_ADMIN_BLOG_FAILED,
  payload: error,
});
