import { constants } from "./constants";

export const resetMessages = () => ({
  type: constants.RESET_FEEDBACK_MESSAGE,
});

export const listFeedback = (data) => ({
  type: constants.LIST_FEEDBACK_REQUEST,
  data,
});

export const listFeedbackSuccess = (data) => ({
  type: constants.LIST_FEEDBACK_SUCCESS,
  payload: data,
});

export const listFeedbackFailed = (error) => ({
  type: constants.LIST_FEEDBACK_FAILED,
  payload: error,
});

export const feedbackStatus = (data) => ({
  type: constants.FEEDBACK_STATUS_REQUEST,
  data,
});

export const feedbackStatusSuccess = (data) => ({
  type: constants.FEEDBACK_STATUS_SUCCESS,
  payload: data,
});

export const feedbackStatusFailed = (error) => ({
  type: constants.FEEDBACK_STATUS_FAILED,
  payload: error,
});
