export const constants = {
  RESET_ADMIN_COUPON_MESSAGE: "RESET_ADMIN_COUPON_MESSAGE",

  GET_ADMIN_COUPON_REQUEST: "GET_ADMIN_COUPON_REQUEST",
  GET_ADMIN_COUPON_SUCCESS: "GET_ADMIN_COUPON_SUCCESS",
  GET_ADMIN_COUPON_FAILED: "GET_ADMIN_COUPON_FAILED",

  ADD_ADMIN_COUPON_REQUEST: "ADD_ADMIN_COUPON_REQUEST",
  ADD_ADMIN_COUPON_SUCCESS: "ADD_ADMIN_COUPON_SUCCESS",
  ADD_ADMIN_COUPON_FAILED: "ADD_ADMIN_COUPON_FAILED",

  EDIT_ADMIN_COUPON_REQUEST: "EDIT_ADMIN_COUPON_REQUEST",
  EDIT_ADMIN_COUPON_SUCCESS: "EDIT_ADMIN_COUPON_SUCCESS",
  EDIT_ADMIN_COUPON_FAILED: "EDIT_ADMIN_COUPON_FAILED",

  DELETE_ADMIN_COUPON_REQUEST: "DELETE_ADMIN_COUPON_REQUEST",
  DELETE_ADMIN_COUPON_SUCCESS: "DELETE_ADMIN_COUPON_SUCCESS",
  DELETE_ADMIN_COUPON_FAILED: "DELETE_ADMIN_COUPON_FAILED",

  GET_ADMIN_SUBSCRIPTIONS_REQUEST: "GET_ADMIN_SUBSCRIPTIONS_REQUEST",
  GET_ADMIN_SUBSCRIPTIONS_SUCCESS: "GET_ADMIN_SUBSCRIPTIONS_SUCCESS",
  GET_ADMIN_SUBSCRIPTIONS_FAILED: "GET_ADMIN_SUBSCRIPTIONS_FAILED",
};
