import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../../helpers/api";
import {
  deleteSellerDealsFailed,
  deleteSellerDealsSuccess,
  getSellerDealsReportFailed,
  getSellerDealsReportSuccess,
  searchSortSellerDealsFailed,
  searchSortSellerDealsSuccess,
} from "./action";

function* getSellerDealsReportList({ data }) {
  let options = getProtectedOptions(endpoints.getSellerDealsReport, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getSellerDealsReportSuccess(response.data));
    } else {
      yield put(getSellerDealsReportFailed(response.data.message));
    }
  } catch (error) {
    yield put(getSellerDealsReportFailed(error || "Error not found!"));
  }
}
function* deleteSellerDeals({ data }) {
  let options = getProtectedOptions(endpoints.deleteSellerDeals, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteSellerDealsSuccess(response.data));
    } else {
      yield put(deleteSellerDealsFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteSellerDealsFailed(error || "Error not found!"));
  }
}
function* searchSortSellerDeals({ data }) {
  let options = getProtectedOptions(endpoints.searchSortSellerDeals, "POST", data);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(searchSortSellerDealsSuccess(response.data));
    } else {
      yield put(searchSortSellerDealsFailed(response.data.message));
    }
  } catch (error) {
    yield put(searchSortSellerDealsFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchgetSellerDealsReport() {
  yield takeLatest(constants.GET_SELLER_DEALS_REPORT_REQUEST, getSellerDealsReportList);
}
export function* watchdeleteSellerDeals() {
  yield takeLatest(constants.DELETE_SELLER_DEALS_REQUEST, deleteSellerDeals);
}
export function* watchSearchSortSellerDeals() {
  yield takeLatest(constants.SEARCH_SELLER_DEALS_REQUEST, searchSortSellerDeals);
}

function* SellerDealsaga() {
  yield all([
    watchSearchSortSellerDeals(),
    watchgetSellerDealsReport(),
    watchdeleteSellerDeals(),
  ]);
}

export default SellerDealsaga;
