import { constants } from "./constants";

const INIT_STATE = {
  loading: false,
  dashboardData: [],
  errorMessage: null,
  successMessage: null,
};

const DashboardData = (state = INIT_STATE, action) => {
  switch (action.type) {
    // REQUEST
    case constants.GET_DASHBOARD_DATA_REQUEST: // REQUEST
      return {
        ...state,
        loading: true,
        isDeleted: false,
      };

    case constants.GET_DASHBOARD_DATA_FAILED: //FAILED
      return {
        ...state,
        errorMessage: action.payload,
        isDeleted: false,
        loading: false,
      };

    case constants.GET_DASHBOARD_DATA_SUCCESS: //SUCCESS
      // console.log({ action: action.payload.data });
      return {
        ...state,
        loading: false,
        isDeleted: false,
        errorMessage: null,
        dashboardData: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default DashboardData;
