import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideFbaCalculator } from "../../../containers/Products/actions";
import { connect } from "react-redux";
import StarRatings from "react-star-ratings";

import Overlay from "./Overlay";

import "../../../assets/css/fba-calc.css";
import "../../../assets/css/custom.css";
import closeIcon from "../../../assets/img/close.svg";
import fbaLogo from "../../../assets/img/fba-logo.svg";
import dollarIcon from "../../../assets/img/dollar.svg";
import estSalesIcon from "../../../assets/img/est-sales.svg";
import percentIcon from "../../../assets/img/percent-icon.svg";
import dollarLightIcon from "../../../assets/img/dollar-light.svg";
import percentLightIcon from "../../../assets/img/percent-icon-light.svg";

import {
  getProductInfo,
  getProductFbaCalc,
} from "../../../containers/Products/actions";

const FbaModal = ({
  products: { loadingInfo },
  getProductInfo,
  getProductFbaCalc,
}) => {
  const dispatch = useDispatch();
  const [unitConversion, setUnitConversion] = useState(false);
  const [formValues, setFormValues] = useState({
    cpc: 0,
    tax: 0,
    shipping: 0,
    product_cost: 0,
    product_price: 0,
    sales_count: 100,
  });

  const {
    productAsin,
    productImg,
    productInfo,
    fbaError,
    productFbaCalc,
    productPrice,
  } = useSelector((state) => state.Products);

  const [calcValues, setCalcValues] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getProductInfo({
      asin: productAsin,
      url: "",
    });
  };

  const formatValue = (value) => {
    if (value) {
      return Number(value)?.toFixed(2)?.replace(/\.00$/, "");
    } else return 0;
  };

  useEffect(() => {
    if (productFbaCalc) {
      const {
        product_price = 0,
        shipping = 0,
        product_cost = 0,
        tax = 0,
        cpc = 0,
        sales_count,
      } = formValues;
      const { unit_storage_fee = 0, fulfillment_fee = 0 } = productFbaCalc;
      const unit_storage = unit_storage_fee ? unit_storage_fee : 0;
      const fulfillmentfee = fulfillment_fee ? fulfillment_fee : 0;
      const referralFees = (Number(product_price) * 0.15)?.toFixed(2);
      const totalFbaFees =
        parseFloat(referralFees) +
        parseFloat(fulfillmentfee.toFixed(2)) +
        parseFloat(unit_storage.toFixed(2));
      const totalExpense =
        Number(product_cost) +
        Number(tax) +
        Number(cpc) +
        Number(shipping) +
        totalFbaFees;
      const profitPerUnit = formatValue(Number(product_price) - totalExpense);
      const netMargin = formatValue(
        (profitPerUnit / Number(product_price)) * 100
      );
      const monthlyProfit = formatValue(profitPerUnit * Number(sales_count));
      const roi = formatValue(
        (monthlyProfit * 100) / (totalExpense * Number(sales_count))
      );

      setCalcValues({
        ...calcValues,
        fulfillmentfee,
        unit_storage,
        referralFees,
        totalFbaFees,
        profitPerUnit,
        netMargin,
        monthlyProfit,
        roi,
      });
    }
  }, [productFbaCalc, formValues]);

  useEffect(() => {
    if (productInfo && !fbaError) {
      getProductFbaCalc({
        asin: productAsin,
        price: productPrice,
        url: "",
      });
    }
  }, [productInfo]);

  // Convert lbs to kg
  const weightConversion = (value) => {
    if (unitConversion) {
      return (value * 0.45359237)?.toFixed(2)?.replace(/\.00$/, "");
    }
    return value?.toFixed(2)?.replace(/\.00$/, "");
  };
  // Convert Inch to cm
  const legthConversion = (value) => {
    if (unitConversion) {
      return (value * 2.54)?.toFixed(2)?.replace(/\.00$/, "");
    }
    return value?.toFixed(2)?.replace(/\.00$/, "");
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 189 || event.keyCode === 109) {
      event.preventDefault();
    }
  };

  function checkInput(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value === "" ? null : Number(value),
    });
  }

  useEffect(() => {
    if (productInfo) {
      setFormValues({
        ...formValues,
        product_price:
          formValues?.product_price === " "
            ? productInfo?.product_price
            : productInfo?.product_price,
        shipping: productInfo?.shipping || 0,
      });
    }
  }, [productInfo]);

  const textColor = (value) => {
    const number = isFinite(value) ? value : 0;
    if (number > 0) {
      return "positive";
    }
    if (number < 0) return "negative";
  };

  return (
    <>
      <div className="fba-modal-wrap ">
        <div className="fba-modal">
          {loadingInfo &&
            <Overlay
              spinner
              title="Fetching"
              content="We're working on getting your data ready, just a moment please..."
            />
          }
          {!loadingInfo && !productInfo && (
            <Overlay
              button="Try Again"
              fetchData={fetchData}
              title="Something went wrong !!"
              content={
                fbaError ||
                "Sorry, unable to retrieve product details at this time. Please try again later..."
              }
            />
          )}

          <div className="fba-header">
            <div className="fba-logo">
              <img src={fbaLogo} alt="seller simple" />
            </div>
            <buttton className="btn-close">
              <img
                src={closeIcon}
                onClick={() => dispatch(hideFbaCalculator())}
                className="csr-ptr"
                alt="close"
              />
            </buttton>
          </div>

          <div
            className={`fba-content-wrp ${
              (loadingInfo || (!loadingInfo && !productInfo)) && "isLoading"
            }`}
          >
            <div className="fba-content">
              <div className="fba-info-wrp">
                <div className="fba-img">
                  <img
                    // src={productInfo?.image}
                    src={productImg ? productImg : productInfo?.image}
                    alt="prod img"
                    className="fba-prod-img"
                  />
                </div>
                <div className="fba-prod-info-itms">
                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Product Weight</span>
                      <span className="itm-units">
                        {unitConversion ? "Kg" : "Pounds"}{" "}
                      </span>
                    </h3>
                    <p className="info-itm-txt">
                      {weightConversion(productInfo?.weight)}{" "}
                    </p>
                  </div>
                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Product Size</span>
                      <span className="itm-units">
                        {unitConversion ? "cm" : "Inch"}{" "}
                      </span>
                    </h3>
                    {productInfo ? (
                      <p className="info-itm-txt">
                        {" "}
                        {legthConversion(productInfo?.height)}x{" "}
                        {legthConversion(productInfo?.length)} x{" "}
                        {legthConversion(productInfo?.width)}{" "}
                      </p>
                    ) : (
                      <p />
                    )}
                  </div>

                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Package Weight</span>
                      <span className="itm-units">
                        {unitConversion ? "Kg" : "Pound"}{" "}
                      </span>
                    </h3>
                    <p className="info-itm-txt">
                      {weightConversion(productInfo?.package_weight)}{" "}
                    </p>
                  </div>

                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Date First Available</span>{" "}
                    </h3>
                    <p className="info-itm-txt">
                      {productInfo?.date_available
                        ? productInfo?.date_available
                        : "N/A"}
                    </p>
                  </div>

                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Package Size</span>
                      <span className="itm-units">
                        {unitConversion ? "cm" : "Inch"}{" "}
                      </span>
                    </h3>
                    {productInfo ? (
                      <p className="info-itm-txt">
                        {" "}
                        {legthConversion(productInfo?.package_height)}x{" "}
                        {legthConversion(productInfo?.package_length)} x{" "}
                        {legthConversion(productInfo?.package_width)}{" "}
                      </p>
                    ) : (
                      <p />
                    )}{" "}
                  </div>

                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Category (Rank) </span>
                    </h3>
                    <p className="info-itm-txt">
                      {productInfo?.category1
                        ? productInfo?.rank1
                          ? productInfo?.category1 +
                            " (" +
                            productInfo?.rank1 +
                            ")"
                          : productInfo?.category1
                        : "N/A"}
                    </p>
                  </div>

                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Type</span>
                    </h3>
                    <p className="info-itm-txt">
                      {productInfo?.type ? productInfo?.type : "N/A"}
                    </p>
                  </div>

                  <div className="info-itm">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Subcategory (Rank) </span>
                    </h3>
                    <p className="info-itm-txt">
                      {productInfo?.category2
                        ? productInfo?.rank2
                          ? productInfo?.category2 +
                            " (" +
                            productInfo?.rank2 +
                            ")"
                          : productInfo?.category2
                        : "N/A"}
                    </p>
                  </div>

                  <div className="info-itm w-100">
                    <h3 className="info-itm-title">
                      <span className="itm-title">Measurement System</span>
                    </h3>
                    <div className="measureFields">
                      <div className="checkField">
                        <input
                          type="radio"
                          id="inch_lb"
                          name="measureSys"
                          value="inchLB"
                          onClick={() => setUnitConversion(false)}
                          checked={!unitConversion}
                        />
                        <div className="chkLabel-wrap">
                          <span className="chkLabel" />
                          <label htmlFor="inch_lb">INCH/LB</label>
                        </div>
                      </div>

                      <div className="checkField">
                        <input
                          type="radio"
                          id="cm_kg"
                          name="measureSys"
                          value="cmKG"
                          onClick={() => setUnitConversion(true)}
                          checked={unitConversion}
                        />
                        <div className="chkLabel-wrap">
                          <span className="chkLabel" />
                          <label htmlFor="cm_kg">CM/KG</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fba-fields-wrp">
                <div className="fba-fields">
                  <div className="input_wrp">
                    <label htmlFor="">Product Price</label>
                    <div className="field_wrp">
                      <span className="field_icon">
                        <img src={dollarIcon} alt="" />
                      </span>
                      <input
                        type="number"
                        name="product_price"
                        className="field-form"
                        autoSelect={false}
                        value={formValues?.product_price}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => checkInput(e)}
                      />
                    </div>
                  </div>

                  <div className="input_wrp">
                    <label htmlFor="">Taxes including VAT</label>
                    <div className="field_wrp">
                      <span className="field_icon">
                        <img src={percentIcon} alt="taxes" />
                      </span>
                      <input
                        type="number"
                        name="tax"
                        className="field-form"
                        autoSelect={false}
                        value={formValues?.tax}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => checkInput(e)}
                      />
                    </div>
                  </div>

                  <div className="input_wrp">
                    <label htmlFor="">Product Cost</label>
                    <div className="field_wrp">
                      <span className="field_icon">
                        <img src={dollarIcon} alt="product cost" />
                      </span>
                      <input
                        type="number"
                        name="product_cost"
                        className="field-form"
                        autoSelect={false}
                        value={formValues?.product_cost}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => checkInput(e)}
                      />
                    </div>
                  </div>

                  <div className="input_wrp">
                    <label htmlFor="">Shipping Cost</label>
                    <div className="field_wrp">
                      <span className="field_icon">
                        <img src={dollarIcon} alt="shipping cost" />
                      </span>
                      <input
                        type="number"
                        name="shipping"
                        className="field-form"
                        autoSelect={false}
                        value={formValues?.shipping}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => checkInput(e)}
                      />
                    </div>
                  </div>

                  <div className="input_wrp">
                    <label htmlFor="">CPC Cost</label>
                    <div className="field_wrp">
                      <span className="field_icon">
                        <img src={dollarIcon} alt="" />
                      </span>
                      <input
                        type="number"
                        name="cpc"
                        className="field-form"
                        autoSelect={false}
                        value={formValues?.cpc}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => checkInput(e)}
                      />
                    </div>
                  </div>

                  <div className="input_wrp">
                    <label htmlFor="">Est Monthly Sales</label>
                    <div className="field_wrp fba-sales-border">
                      <span className="field_icon">
                        <img src={estSalesIcon} alt="" />
                      </span>
                      <input
                        type="number"
                        name="sales_count"
                        min="0"
                        className="field-form"
                        autoSelect={false}
                        value={formValues?.sales_count}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => checkInput(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="fba-fl">
                  <div className="fl-info">
                    <div className="fl-title">Monthly Storage</div>
                    <div className="fl-cont">
                      <span>$</span>
                      <span>
                        {calcValues ? (
                          calcValues?.unit_storage
                        ) : (
                          <div
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="fl-info">
                    <div className="fl-title">Fulfillment Fee</div>
                    <div className="fl-cont">
                      <span>$</span>
                      <span>
                        {calcValues ? (
                          calcValues?.fulfillmentfee
                        ) : (
                          <div
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="fl-info">
                    <div className="fl-title">Referral Fee</div>
                    <div className="fl-cont">
                      <span>$</span>
                      <span>
                        {calcValues ? (
                          calcValues?.referralFees
                        ) : (
                          <div
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="fba-footer">
              <div className="fba-ftr-item">
                <div className="fba-ftr-title">Total FBA Fee</div>
                <div className="fba-ftr-txt">
                  <img src={dollarLightIcon} alt="" className="ftr-ico" />
                  <span className="txt">
                    {calcValues ? (
                      formatValue(calcValues?.totalFbaFees)
                    ) : (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="fba-ftr-item">
                <div className="fba-ftr-title">Profit per Unit</div>
                <div className="fba-ftr-txt">
                  <img src={dollarLightIcon} alt="" className="ftr-ico" />
                  <span
                    className={`txt ${textColor(calcValues?.profitPerUnit)}`}
                  >
                    {calcValues ? (
                      formatValue(calcValues?.profitPerUnit)
                    ) : (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="fba-ftr-item">
                <div className="fba-ftr-title">Net Margin</div>
                <div className="fba-ftr-txt">
                  <img src={percentLightIcon} alt="" className="ftr-ico" />
                  <span
                    className={`txt ${textColor(
                      isFinite(calcValues?.netMargin)
                        ? calcValues?.netMargin
                        : Math.sign(calcValues?.netMargin) === -1
                        ? 0
                        : Math.sign(calcValues?.netMargin) === 1 && 100
                    )}`}
                  >
                    {calcValues ? (
                      isFinite(calcValues?.netMargin) ? (
                        calcValues?.netMargin
                      ) : Math.sign(calcValues?.netMargin) === -1 ? (
                        0
                      ) : (
                        Math.sign(calcValues?.netMargin) === 1 && 100
                      )
                    ) : (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="fba-ftr-item">
                <div className="fba-ftr-title">ROI</div>
                <div className="fba-ftr-txt">
                  <img src={percentLightIcon} alt="" className="ftr-ico" />
                  <span className={`txt ${textColor(calcValues?.roi)}`}>
                    {calcValues ? (
                      formatValue(calcValues?.roi)
                    ) : (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>

              <div className="fba-ftr-item">
                <div className="fba-ftr-title">Est Monthly Profit</div>
                <div className="fba-ftr-txt">
                  <img src={dollarLightIcon} alt="" className="ftr-ico" />
                  <span
                    className={`txt ${textColor(calcValues?.monthlyProfit)}`}
                  >
                    {calcValues ? (
                      formatValue(calcValues?.monthlyProfit)
                    ) : (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.Products,
  settings: state.Settings,
});

export default connect(mapStateToProps, { getProductInfo, getProductFbaCalc })(
  FbaModal
);
