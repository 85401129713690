import { constants } from "./constants";


export const getDashboardList = (params) => ({
    type: constants.GET_DASHBOARD_LIST_REQUEST,
    params
});

export const getDashboardListSuccess = (products) => ({
    type: constants.GET_DASHBOARD_LIST_SUCCESS,
    payload: products
});

export const getDashboardListFailed = error => ({
    type: constants.GET_DASHBOARD_LIST_FAILED,
    payload: error
});

