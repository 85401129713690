import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
    apiCall,
    getProtectedOptions,
    getUnProtectedOptions,
} from "../../helpers/api";
import { getListingCategoriesFailed, getListingCategoriesSuccess,loadMoreContentFailed,loadMoreContentSuccess, fetchcategorytrendsFailed, fetchcategorytrendsSuccess } from "./actions";

function* getListingCategories({ params }) {
    let options = getUnProtectedOptions(endpoints.getListingCategories, "GET", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getListingCategoriesSuccess(response.data));
        } else {
            yield put(getListingCategoriesFailed(response.data.message));
        }
    } catch (error) {
        yield put(getListingCategoriesFailed(error || "Error not found!"));
    }
}

function* fetchCategoryTrends({ params }) {
  let options = getUnProtectedOptions(endpoints.fetchCategoryTrends, "POST", params);
  try {
      const response = yield call(apiCall, options);
      if (response.status === 200 && response.data.status) {
          yield put(fetchcategorytrendsSuccess(response.data));
      } else {
          yield put(fetchcategorytrendsFailed(response.data.message));
      }
  } catch (error) {
      yield put(fetchcategorytrendsFailed(error || "Error not found!"));
  }
}

function* loadMoreContent({ params }) {
    console.log(params,"paramsssssssssssssssss");
    let options = getUnProtectedOptions(endpoints.fetchCategoryTrends, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(loadMoreContentSuccess(response.data));
        } else {
            yield put(loadMoreContentFailed(response.data.message));
        }
    } catch (error) {
        yield put(loadMoreContentFailed(error || "Error not found!"));
    }
  }
  
//Watcher
export function* watchGetListingCategories() {
    yield takeLatest(constants.GET_LISTING_CATEGORY_REQUEST, getListingCategories);
};
export function* watchFetchcategorytrends() {
  yield takeLatest(constants.FETCH_CATEGORY_TRENDS_REQUEST, fetchCategoryTrends);
};
export function* watchLoadMoreContent() {
    yield takeLatest(constants.LOAD_MORE_CONTENT_REQUEST, loadMoreContent);
};

function* categorySaga() {
    yield all([
        watchGetListingCategories(),
        watchFetchcategorytrends(),
        watchLoadMoreContent()
    ]);
}

export default categorySaga;