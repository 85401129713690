export const constants = {
    GET_MONITORING_REPORT_REQUEST: "GET_MONITORING_REPORT_REQUEST",
    GET_MONITORING_REPORT_SUCCESS: "GET_MONITORING_REPORT_SUCCESS",
    GET_MONITORING_REPORT_FAILED: "GET_MONITORING_REPORT_FAILED",

    GET_COMPETITOR_REPORT_REQUEST: "GET_COMPETITOR_REPORT_REQUEST",
    GET_COMPETITOR_REPORT_SUCCESS: "GET_COMPETITOR_REPORT_SUCCESS",
    GET_COMPETITOR_REPORT_FAILED: "GET_COMPETITOR_REPORT_FAILED",

    DELETE_ALERT_REQUEST: "DELETE_ALERT_REQUEST",
    DELETE_ALERT_SUCCESS: "DELETE_ALERT_SUCCESS",
    DELETE_ALERT_FAILED: "DELETE_ALERT_FAILED",
    // on toggle remove
    ALERT_REMOVE_REQUEST: "ALERT_REMOVE_REQUEST",
    ALERT_REMOVE_SUCCESS: "ALERT_REMOVE_SUCCESS",
    ALERT_REMOVE_FAILED: "ALERT_REMOVE_FAILED",

    

    RESET_MONITORING_MESSAGE: "RESET_MONITORING_MESSAGE",
}