import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
  apiCall,
  getProtectedOptions,
  getUnProtectedOptions,
} from "../../helpers/api";
import { getQueryParams } from "../../helpers/structureData";

import {
  getCategorySpySuccess,
  getCategorySpyFailed,
  deleteCategorySpySuccess,
  deleteCategorySpyFailed,
  fetchMainCategorySuccess,
  fetchMainCategoryFailed,
  fetchSubCategorySuccess,
  fetchSubCategoryFailed,
  addSubCategorySuccess,
  addSubCategoryFailed,
  addCategoryIdSuccess,
  addCategoryIdFailed,
  fetchCategoryTableDataSuccess,
  fetchCategoryTableDataFailed
} from "./actions";

function* categorySpy({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.categorySpy}?${getQueryParams(params)}`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getCategorySpySuccess(response.data));
    } else {
      yield put(getCategorySpyFailed(response.data.message));
    }
  } catch (error) {
    yield put(getCategorySpyFailed(error || "Error not found!"));
  }
}

function* addSubCategory({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.addSubCategory}?${getQueryParams(params)}`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addSubCategorySuccess(response.data));
    } else {
      yield put(addSubCategoryFailed(response.data.message));
    }
  } catch (error) {
    yield put(addSubCategoryFailed(error || "Error not found!"));
  }
}
function* addCategoryId({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.addCategoryId}?${getQueryParams(params)}`,
    "GET"
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addCategoryIdSuccess(response.data));
    } else {
      yield put(addCategoryIdFailed(response.data));
    }
  } catch (error) {
    yield put(addCategoryIdFailed(error || "Error not found!"));
  }
}

function* deleteCategorySpy({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.deleteCategorySpy}?${getQueryParams(params)}`,
    "GET",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteCategorySpySuccess(response.data));
    } else {
      yield put(deleteCategorySpyFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteCategorySpyFailed(error || "Error not found!"));
  }
}

function* fetchMainCategory({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.fetchMainCategory}?${getQueryParams(params)}`,
    "GET",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchMainCategorySuccess(response.data));
    } else {
      yield put(fetchMainCategoryFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchMainCategoryFailed(error || "Error not found!"));
  }
}
function* fetchSubCategory({ params }) {
  let options = getUnProtectedOptions(
    `${endpoints.fetchSubCategory}?${getQueryParams(params)}`,
    "GET",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchSubCategorySuccess(response.data));
    } else {
      yield put(fetchSubCategoryFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchSubCategoryFailed(error || "Error not found!"));
  }
}

function* fetchCategoryTableData({ params }) {
  console.log(params);
  let options = getUnProtectedOptions(
    `${endpoints.listingSpyCategories}?${getQueryParams(params)}`,
    "GET",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchCategoryTableDataSuccess(response.data));
    } else {
      yield put(fetchCategoryTableDataFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchCategoryTableDataFailed(error || "Error not found!"));
  }
}

//Watcher
export function* watchGetcategorySpy() {
  yield takeLatest(constants.GET_LISTING_CATEGORYSPY_REQUEST, categorySpy);
}
export function* watchDeleteCategorySpy() {
  yield takeLatest(constants.DELETE_CATEGORY_SPY_REQUEST, deleteCategorySpy);
}
export function* watchFetchMainCategory() {
  yield takeLatest(constants.FETCH_MAIN_CATEGORY_REQUEST, fetchMainCategory);
}
export function* watchFetchSubCategory() {
  yield takeLatest(constants.FETCH_SUB_CATEGORY_REQUEST, fetchSubCategory);
}
export function* watchFetchCategoryTableData() {
  yield takeLatest(constants.FETCH_CATEGORY_DATA_REQUEST, fetchCategoryTableData);
}
export function* watchAddSubCategory() {
  yield takeLatest(constants.ADD_SUB_CATEGORY_ID_REQUEST, addSubCategory);
}
export function* watchAddCategoryId() {
  yield takeLatest(constants.ADD_CATEGORY_ID_REQUEST, addCategoryId);
}
function* categorySpySaga() {
  yield all([
    watchGetcategorySpy(),
    watchDeleteCategorySpy(),
    watchFetchMainCategory(),
    watchFetchSubCategory(),
    watchAddSubCategory(),
    watchFetchCategoryTableData(),
    watchAddCategoryId()
  ]);
}

export default categorySpySaga;
