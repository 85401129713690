import { constants } from "./constants";

const INIT_STATE = {
    loading: false,
    pageCount: null,
    deleteSuccess: false,
    monitoringReport: null,
    monitoringErrorMessage: null,
    monitoringSuccessMessage: null,

};

const Monitoring = (state = INIT_STATE, action) => {
    switch (action.type) {
        case constants.RESET_MONITORING_MESSAGE:          //RESET MSGS
            return {
                ...state,
                monitoringErrorMessage: null,
                monitoringSuccessMessage: null
            };

        case constants.GET_MONITORING_REPORT_REQUEST: // REQUEST
        case constants.GET_COMPETITOR_REPORT_REQUEST: // REQUEST
        case constants.DELETE_ALERT_REQUEST:
        case constants.ALERT_REMOVE_REQUEST:
        
            return { ...state, loading: true };

        case constants.GET_MONITORING_REPORT_FAILED:     //FAILED
        case constants.GET_COMPETITOR_REPORT_FAILED:     //FAILED
        case constants.DELETE_ALERT_FAILED:
        case constants.ALERT_REMOVE_FAILED:
        
            return {
                ...state,
                monitoringErrorMessage: action.payload,
                loading: false
            };

        case constants.GET_MONITORING_REPORT_SUCCESS:          //SUCCESS
        case constants.GET_COMPETITOR_REPORT_SUCCESS:          //SUCCESS
        
            return {
                ...state,
                loading: false,
                deleteSuccess: false,
                monitoringErrorMessage: null,
                monitoringReport: action.payload.data,
                pageCount: action.payload.total_pages,
            };
        case constants.DELETE_ALERT_SUCCESS:
        case constants.ALERT_REMOVE_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteSuccess: true,
                monitoringErrorMessage: null,
                monitoringSuccessMessage: action.payload.message,
            };
        default:
            return { ...state };
    }
};

export default Monitoring;
