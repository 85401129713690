import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import { apiCall, getProtectedOptions } from "../../helpers/api";
import {
  getCompetitorSuccess,
  getCompetitorFailed,
  addCompetitorSuccess,
  addCompetitorFailed,
  deleteCompetitorSuccess,
  deleteCompetitorFailed,
  getCompetitorAlertsSuccess,
  getCompetitorAlertsFailed,
  updateCompetitorAlertsFailed,
  updateCompetitorAlertsSuccess,
  fetchFilterSearchSuccess,
  fetchFilterSearchFailed,
} from "./actions";

function* addCompetitor({ params }) {
  let options = getProtectedOptions(endpoints.addcompetitor, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(addCompetitorSuccess(response.data));
    } else {
      yield put(addCompetitorFailed(response.data.message));
    }
  } catch (error) {
    yield put(addCompetitorFailed(error || "Error not found!"));
  }
}

function* getCompetitor({ params }) {
  let options = getProtectedOptions(endpoints.getcompetitor, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getCompetitorSuccess(response.data));
    } else {
      yield put(getCompetitorFailed(response.data.message));
    }
  } catch (error) {
    yield put(getCompetitorFailed(error || "Error not found!"));
  }
}

function* deleteCompetitor({ params }) {
  let options = getProtectedOptions(endpoints.deletecompetitor, "POST", params);
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(deleteCompetitorSuccess(response.data));
    } else {
      yield put(deleteCompetitorFailed(response.data.message));
    }
  } catch (error) {
    yield put(deleteCompetitorFailed(error || "Error not found!"));
  }
}
function* getCompetitorAlerts({ params }) {
  let options = getProtectedOptions(
    endpoints.getCompetitorAlerts,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(getCompetitorAlertsSuccess(response.data));
    } else {
      yield put(getCompetitorAlertsFailed(response.data.message));
    }
  } catch (error) {
    yield put(getCompetitorAlertsFailed(error || "Error not found!"));
  }
}

// update

function* updateCompetitorAlerts({ params }) {
  let options = getProtectedOptions(
    endpoints.updateCompetitorAlerts,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(updateCompetitorAlertsSuccess(response.data));
    } else {
      yield put(updateCompetitorAlertsFailed(response.data.message));
    }
  } catch (error) {
    yield put(updateCompetitorAlertsFailed(error || "Error not found!"));
  }
}
// fetch sort
function* fetchFilterSearch({ params }) {
  let options = getProtectedOptions(
    endpoints.fetchFilterSearch,
    "POST",
    params
  );
  try {
    const response = yield call(apiCall, options);
    if (response.status === 200 && response.data.status) {
      yield put(fetchFilterSearchSuccess(response.data));
    } else {
      yield put(fetchFilterSearchFailed(response.data.message));
    }
  } catch (error) {
    yield put(fetchFilterSearchFailed(error || "Error not found!"));
  }
}

export function* watchgetCompetitor() {
  yield takeLatest(constants.GET_COMPETITOR_REQUEST, getCompetitor);
}
export function* watchgetCompetitorAlerts() {
  yield takeLatest(
    constants.GET_COMPETITOR_ALERTS_REQUEST,
    getCompetitorAlerts
  );
}
export function* watchupdateCompetitorAlerts() {
  yield takeLatest(
    constants.UPDATE_COMPETITOR_ALERTS_REQUEST,
    updateCompetitorAlerts
  );
}
export function* watchaddCompetitor() {
  yield takeLatest(constants.ADD_COMPETITOR_REQUEST, addCompetitor);
}
export function* watchdeleteCompetitor() {
  yield takeLatest(constants.DELETE_COMPETITOR_REQUEST, deleteCompetitor);
}

// sort filter
export function* watchfetchFilterSearch() {
  yield takeLatest(constants.FETCH_FILTER_SEARCH_REQUEST, fetchFilterSearch);
}
function* otherProductsSaga() {
  yield all([
    watchgetCompetitor(),
    watchaddCompetitor(),
    watchdeleteCompetitor(),
    watchgetCompetitorAlerts(),
    watchupdateCompetitorAlerts(),
    watchfetchFilterSearch(),
  ]);
}

export default otherProductsSaga;
