import { all, call, put, takeLatest } from "redux-saga/effects";

import { constants } from "./constants";
import { endpoints } from "../../helpers/endpoints";
import {
    apiCall,
    getProtectedOptions,
    getUnProtectedOptions
} from "../../helpers/api";
import {
    getDashboardListSuccess,
    getDashboardListFailed
} from "./actions";

function* getDashboardList({ params }) {
    let options = getProtectedOptions(endpoints.getDashboard, "POST", params);
    try {
        const response = yield call(apiCall, options);
        if (response.status === 200 && response.data.status) {
            yield put(getDashboardListSuccess(response.data));
        } else {
            yield put(getDashboardListFailed(response.data.message));
        }
    } catch (error) {
        yield put(getDashboardListFailed(error || "Error not found!"));
    }
}

//Watcher
export function* watchgetDashboardList() {
    yield takeLatest(constants.GET_DASHBOARD_LIST_REQUEST, getDashboardList);
};

function* dashboardSaga() {
    yield all([
        watchgetDashboardList(),
    ]);
}

export default dashboardSaga;